
<v-btn
  class="atoms__icon-button"
  icon
  :color="color"
  :x-small="size === 'xs'"
  :small="size === 'sm'"
  :large="size === 'lg'"
  :x-large="size === 'xl'"
  :elevation="elevation"
  :dark="dark || fill"
  :outlined="outlined"
  :disabled="disabled || loading"
  :loading="loading"
  :to="to"
  :class="{
    'icon-button__dark': dark || fill,
    'icon-button__primary-dark': (dark || fill) && color === 'primary',
    'icon-button__secondary-dark': (dark || fill) && color === 'secondary',
    'icon-button__accent-dark': (dark || fill) && color === 'accent',
    'icon-button__error-dark': (dark || fill) && color === 'error',
    'icon-button__info-dark': (dark || fill) && color === 'info',
    'icon-button__success-dark': (dark || fill) && color === 'success',
    'icon-button__warning-dark': (dark || fill) && color === 'warning',
    'icon-button__anchor-dark': (dark || fill) && color === 'anchor',
    [`shape-${shape}`]: true,
    ['dark-icon']: darkIcon,
    [`shadow-${shadow}`]: !!shadow,
    ['fill']: dark || fill,
  }"
  v-bind="$attrs"
  v-on="$listeners"
  @mouseover="visibleTooltip = true"
  @mouseleave="visibleTooltip = false"
>
  <v-icon v-if="iconName" :dark="dark || fill" :size="iconSize">
    {{ iconName }}
  </v-icon>
  <slot></slot>

  <!-- tooltip -->
  <Tooltip v-if="tooltip" :position="tooltipPosition" closest=".v-btn">
    <span>{{ tooltip }}</span>
  </Tooltip>
</v-btn>
