import api from "@/service/api";

export default {
  namespaced: true,
  state: () => ({
    userIdMap: {}, //key 사용자 id, value {displayName,retrievalTime}
    userEmailMap: {}, //key 사용자 email
    requestIdMap: {}, //중복요청을 막기 위해 요청 보내는 동안은 true로 등록
    requestEmailMap: {},
    roleList: null,
  }),
  getters: {
    hasUser: (state) => (username) => {
      return !!state.userIdMap[username];
    },
    hasEmailUser: (state) => (email) => {
      return !!state.userEmailMap[email];
    },
    getUserDisplaynameById: (state) => (id) => {
      return state.userIdMap[id]?.displayName || "";
    },
    getUserDisplaynameByEmail: (state) => (email) => {
      return state.userEmailMap[email]?.displayName || "";
    },
  },
  mutations: {
    setUserDisplayname(state, userinfo) {
      if (!userinfo) return;
      const data = {
        username: userinfo.username,
        id: userinfo.id,
        displayName: `${userinfo.nickname}`,
        email: userinfo.email,
        retrievalTime: new Date(),
      };
      if (userinfo.additional_info) {
        data.displayName += `/${userinfo.additional_info}`;
      }

      if (userinfo.email) state.userEmailMap[userinfo.email] = data;
      if (userinfo.id != null) state.userIdMap[userinfo.id] = data;
      state.userEmailMap = { ...state.userEmailMap };
      state.userIdMap = { ...state.userIdMap };
    },
  },
  actions: {
    loadUserById({ state, commit }, id) {
      if (state.requestIdMap[id]) return;
      state.requestIdMap[id] = true;
      api
        .getUserById(id)
        .then((data) => {
          commit("setUserDisplayname", data);
        })
        .catch(() => {
          delete state.requestIdMap[id];
        });
    },
    loadUserByEmail({ state, commit }, email) {
      if (state.requestEmailMap[email]) return;
      state.requestEmailMap[email] = true;
      api
        .getUserByEmail(email)
        .then((data) => {
          commit("setUserDisplayname", data);
        })
        .catch(() => {
          delete state.requestEmailMap[email];
        });
    },
  },
};
