<template>
  <v-form
    ref="form"
    v-model="valid"
    :class="[
      useAutoLayout &&
        'grid lg:grid-cols-[140px,_1fr,_140px,_1fr] md:grid-cols-1 gap-x-8 max-w-[960px]',
    ]"
    @submit.prevent="submit"
  >
    <slot
      :pending="pending"
      :diff="diff"
      :valid="valid"
      :validate="validate"
      :defaultValues="defaultValues"
      :values="values"
      :setValue="setValue"
      :setValues="setValues"
      :mergeValues="mergeValues"
      :comparisonValues="comparisonValues"
      :setComparisonValues="setComparisonValues"
      :submitTrigger="submitTrigger"
      :submit="submit"
    ></slot>

    <div
      v-if="$scopedSlots.footer"
      class="flex justify-end gap-x-2 col-span-12"
    >
      <slot
        name="footer"
        :pending="pending"
        :diff="diff"
        :valid="valid"
        :validate="validate"
        :defaultValues="defaultValues"
        :values="values"
        :setValue="setValue"
        :setValues="setValues"
        :mergeValues="mergeValues"
        :comparisonValues="comparisonValues"
        :setComparisonValues="setComparisonValues"
        :submitTrigger="submitTrigger"
        :submit="submit"
      >
      </slot>
    </div>
  </v-form>
</template>

<script>
import { request } from "@/service/request";
import { timeoutFn } from "@/service/topologyHelper";
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  props: {
    /** true일 경우 submit 성공 후 리셋 */
    reset: {
      type: Boolean,
      default: true,
    },
    /** true일 경우 자식 컴포넌트 자동 레이아웃 */
    useAutoLayout: {
      type: Boolean,
    },
    /** true일 경우 success콜백에 가공하지 않은 원본 데이터의 응답을 전달한다  */
    originalResponse: {
      type: Boolean,
    },
    /** request method("post"|"put"|"patch"|"delete") */
    method: {
      type: String,
      default: "post",
    },
    /** request uri */
    action: {
      type: [String, Function],
      default: undefined,
    },
    /** uri querystring */
    query: {
      type: Object,
      default: undefined,
    },
    /** 기본 값 객체. 기본적으로는 마운트시 한번만 values에 적용된다 */
    defaultValues: {
      type: Object,
      default: undefined,
    },
    /** @type {boolean} - true일 경우 `defaultValues`를 비교한다 */
    defaultValuesCompare: {
      type: Boolean,
    },
    /** 해당 값이 변경시 values에 반영된다. */
    watchValues: {
      type: Object,
      default: undefined,
    },
    /** submit 전 이벤트 리턴 타입이 false일 경우 submit 중단. 객체일 경우 새로운 values 변경 */
    before: {
      type: Function,
      default: undefined,
    },
    /** 리퀘스트 성공 후 콜백 */
    success: {
      type: Function,
      default: undefined,
    },
    /** 리퀘스트 성공 후 토스트 메시지 */
    successText: {
      type: [String, Function],
      default: undefined,
    },
    /** 리퀘스트 성공 실패 콜백 */
    error: {
      type: Function,
      default: undefined,
    },
    /** 리퀘스트 실패 후 토스트 메시지 */
    errorText: {
      type: [String, Boolean, Function],
      default: undefined,
    },
    /** 리퀘스트 전체 실행 후 콜백 */
    after: {
      type: Function,
      default: undefined,
    },
  },
  emitts: ["submit", "diff", "change"],
  data: () => ({
    /** true 유효성 검사 통과  */
    valid: false,
    /** 리퀘스트 동안 true. 리퀘스트 완료 후 false */
    pending: false,
    /** 비교값에서 변경이 일어났을 경우 true */
    diff: false,
    /** 값 */
    values: {},
    /** 비교를 위한 값(객체) */
    comparisonValues: null,
    /** 비교를 위한 직렬화딘 값(문자열) */
    comparisonStringValues: null,
  }),
  watch: {
    defaultValues: {
      immediate: true,
      handler(defaultValues) {
        if (!defaultValues || Object.entries(this.values).length > 0) {
          return;
        }

        this.values = { ...defaultValues };

        // # 옵션이 설정되어 있으면 기본값을 비교값으로 등록한다
        if (this.defaultValuesCompare === true) {
          this.setComparisonValues(defaultValues);
        }
      },
    },
    watchValues(values) {
      this.values = { ...values };
    },
    /** values 변경 감시 */
    values: {
      deep: true,
      handler(newValues) {
        if (this.comparisonStringValues) {
          debounce(() => {
            const valuesStr = JSON.stringify(newValues);
            // console.log("> watch values: \n", valuesStr);
            this.diff = !(valuesStr === this.comparisonStringValues);
          }, 300);
        }
        this.$emit("change", newValues);
      },
    },
    /** 기본값이 있을 경우 변경 이벤트 */
    diff: {
      immediate: true,
      handler(curDiff) {
        if (!this.comparisonStringValues) {
          return;
        }
        this.$emit("diff", curDiff, {
          values: this.values,
          comparisonValues: this.comparisonValues,
        });
      },
    },
  },
  methods: {
    /** 서브밋 이벤트 시 콜백 */
    async submit(event) {
      event.preventDefault();
      let newValues;
      let response;
      let values = { ...this.values };

      this.valid = this.$refs.form.validate();

      // # 유효성 검사 실패 시 차단
      if (this.valid === false) {
        setTimeout(() => {
          const firstError =
            this.$refs.form?.$el?.querySelector?.(".error--text");

          if (firstError)
            firstError.scrollIntoView({
              behavior: "smooth", // Smooth scrolling
              block: "center", // Scroll so that the element is in the center of the view
            });
        }, 500);

        return;
      }

      // # submit before
      if (typeof this.$props.before === "function") {
        newValues = this.$props.before(
          { ...values },
          {
            valid: this.valid,
            validate: this.validate,
          },
        );
      }
      if (newValues instanceof Promise === true) {
        newValues = await newValues.then((a) => a);
      }
      if (newValues === false) {
        return;
      }
      if (typeof newValues === "object") {
        values = { ...newValues };
      }

      // # submit 이벤트
      this.$emit(
        "submit",
        { ...values },
        {
          valid: this.valid,
          validate: this.validate,
          event,
        },
      );

      // # method나 action이 정의되어 있지 않다면 리퀘스트 하지 않음
      if (!this.$props.method || !this.$props.action) {
        if (this.reset === true) {
          this.values = {};
        }
        return;
      }
      this.pending = true;

      // # 리퀘스트
      try {
        const successTextType = typeof this.$props.successText;

        response =
          typeof this.action === "function"
            ? await this.action({ ...values })
            : await request({
                method: this.$props.method,
                uri: this.$props.action,
                query: this.$props.query ? { ...this.$props.query } : undefined,
                data: { ...values },
              });

        // # 200인데 에러인 경우
        if (response?.success === false) {
          throw response;
        }

        if (this.reset === true) {
          this.values = {};
        }

        if (this.$props.successText) {
          CommonUIControl.ShowSuccessToast(
            successTextType === "function"
              ? this.$props.successText(response)
              : this.$props.successText,
            5000,
          );
        }

        if (typeof this.$props.success === "function") {
          const newResponse =
            this.$props.originalResponse === true
              ? response
              : response?.data?.result || response?.result;

          this.$props.success(newResponse, {
            response: response,
            values: { ...values },
          });
        }
      } catch (e) {
        const errorTextType = typeof this.$props.errorText;

        response = e;

        if (typeof this.$props.error === "function") {
          this.$props.error(e);
        }

        if (this.$props.errorText !== false) {
          const errorMessage =
            this.$props.errorText ??
            e?.response?.data?.message ??
            e?.response?.data?.error_message ??
            e?.response?.message ??
            e?.response?.error_message ??
            e?.message ??
            e?.error_message ??
            e?.data.message ??
            "요청이 실패하였습니다";

          CommonUIControl.ShowErrorToast(
            errorTextType === "function"
              ? this.$props.errorText(e)
              : errorMessage,
            10000,
          );
        }

        throw e;
      } finally {
        if (typeof this.$props.after === "function") {
          this.$props.after({ response, values: this.values });
        }
        this.pending = false;
      }
      // console.log("> handle submit: ", this.values);
    },
    /** 유효성 검사 */
    validate() {
      this.$refs.form.validate();
    },
    /** 값 변경 */
    setValue(name, value) {
      const nameArr = name.split(".");
      const newValues = { ...this.values };

      if (nameArr.length === 1) {
        newValues[name] = value;
      } else if (nameArr.length === 2) {
        newValues[nameArr[0]][nameArr[1]] = value;
      } else if (nameArr.length === 3) {
        newValues[nameArr[0]][nameArr[1]][nameArr[2]] = value;
      } else {
        throw new Error("invalid name: " + name);
      }

      this.values = newValues;
    },
    /** 값들 변경 */
    setValues(newValues) {
      this.values = newValues;
    },
    /** 값들 변경 */
    mergeValues(values) {
      this.values = Object.assign(this.values, values);
    },
    /** 기본값을 셋팅 */
    setComparisonValues(newValues) {
      this.comparisonStringValues =
        typeof newValues === "object" ? JSON.stringify(newValues) : null;
      // console.log("> setComparisonValues: ", newValues);
      this.comparisonValues = JSON.parse(this.comparisonStringValues);
    },
    /** submit 이벤트 트리거 */
    submitTrigger() {
      const submitEvt = new Event("submit");
      this.$refs.form.$el.dispatchEvent(submitEvt);
    },
  },
  mounted() {},
};

const debounce = timeoutFn("debounce");
</script>

<style lang="scss" scoped></style>
