<template>
  <div
    class="indicator-wrapper flex flex-col px-3"
    :class="[extraClass, { 'mr-6': splitter }]"
  >
    <div class="indicator w-full" :class="titleClass">
      <div class="text-sm i-title w-auto flex flex-row gap-1">
        <SvgIcon v-if="icon" :iconName="icon" size="sm" color="#87888C" />
        {{ title }}
      </div>
      <div class="">
        <span class="font-bold ivalue">{{
          $helper.roundNumber(value, 1)
        }}</span>
        <span v-if="usageUnit" class="pl-2 text-sm i-unit">{{
          usageUnit
        }}</span>
        <span v-if="limitNumber >= 0" class="mx-1 text-lg i-unit slash">/</span>
        <span v-if="limitNumber >= 0" class="font-bold ivalue">{{
          limitNumber
        }}</span>
        <span class="pl-2 text-sm i-unit">{{ unit }}</span>
      </div>
    </div>
    <div v-if="showPercent && percent >= 0" class="resource-bar mt-2">
      <div
        class="resource-bar-inner"
        :style="{ width: percent + '%', backgroundColor: color }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    value: { type: [Number, String], default: 0 },
    usageUnit: { type: String },
    unit: { type: String },
    splitter: { type: Boolean, default: false },
    titleClass: { type: String },
    showPercent: { type: Boolean, default: false },
    limit: { type: [Number, String], default: 0 },
    icon: { type: String },
    extraClass: { type: String },
    percent: { type: [Number, String], default: 0 },
  },
  data() {
    return {};
  },
  computed: {
    color() {
      return this.percent <= 60
        ? "#2EB85C"
        : this.percent < 80
        ? "#F9B115"
        : "#bd2130";
    },
    limitNumber() {
      const limit = Number(this.limit);
      return !isNaN(limit) && limit !== 0 ? limit : -1;
    },
    // percent() {
    //   return this.calPercent(this.value, this.limitNumber) || 0;
    // },
  },
  // methods: {
  //   calPercent(use, limit) {
  //     // Simplified validation and calculation
  //     use = parseFloat(use);
  //     limit = parseFloat(limit);

  //     if (isNaN(use) || isNaN(limit) || limit === 0) return 0;

  //     const percent = (use / limit) * 100;
  //     return percent > 100
  //       ? 100
  //       : percent <= 9
  //       ? this.$helper.roundNumber(percent, 1)
  //       : Math.round(percent);
  //   },
  // },
};
</script>

<style type="scss" scoped>
.indicator-wrapper {
  position: relative;
  overflow: hidden;
  min-width: 150px;
  padding: 10px;
  border-radius: 4px;
  border: 1px;
  background-color: #fff;

  .pc-value {
    position: absolute;
    top: 29px;
    color: #1d2129;
    width: 45px;
    font-size: 12px;
    font-weight: bold;
    line-height: 15.13px;
  }
  .indicator {
    min-width: 120px;

    .i-unit {
      color: #87888c;
      font-size: 12px;
    }
    .i-title {
      color: #2a2d45;
      font-size: 12px;
    }
    .ivalue {
      color: #321fdb;
      font-size: 28px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }
  .splitter {
    content: "";
    @apply mr-4 float-right;
  }
}

.resource-bar {
  width: 100%;
  height: 5px;
  background-color: #dadce0;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.resource-bar-inner {
  height: 100%;
}

.slash {
  font-size: 16px !important;
}
</style>
