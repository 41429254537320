export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations: {
    initialize(state) {
      for (const [k, v] of Object.entries(initialState)) {
        state[k] = v;
      }
    },
    show(state, payload) {
      for (const [k, v] of Object.entries(initialState)) {
        state[k] = payload[k] ? payload[k] : v;
      }
    },
  },
  actions: {
    /** initialize */
    initialize: ({ commit }) => commit("initialize"),
    /** select */
    select: ({ commit }, { title, data, onSelect, onClose }) =>
      commit("show", {
        visible: true,
        type: "select",
        title,
        data,
        onSelect,
        onClose,
      }),
    multiSelect: ({ commit }, { title, data, onConfirm, onClose }) =>
      commit("show", {
        visible: true,
        type: "multiSelect",
        title,
        data,
        onConfirm,
        onClose,
      }),
    prompt: ({ commit }, { title, data, onConfirm, onClose }) =>
      commit("show", {
        visible: true,
        type: "prompt",
        title,
        data,
        onConfirm,
        onClose,
      }),
    form: ({ commit }, payload) =>
      commit("show", {
        visible: true,
        type: "form",
        ...payload,
      }),
  },
};

const initialState = Object.freeze({
  // # common
  visible: false, // 다이얼로그 활성화 유무
  type: "", // 다이얼로그 타입("select"|"multiSelect"|"prompt")
  title: "", // 다이얼로그 타이틀
  data: null, // 랜더링 될 다이얼로드 데이타(type:"form"일 경우는 $attrs로 폼 컴포넌트에 전달)
  onSelect: null, // type=select 시 아이템 선택 콜백 함수
  onConfirm: null, // confirm 시 콜백 함수
  onClose: null, // close 시 콜백 함수
  submitText: null, // submit 버튼의 이름
  // # type:"form"
  mode: null, // form의 모드["add" | "edit" | null]
  formComponent: null, // 동적 임포트할 폼 컴포넌트
  onBefore: null, // before 콜백 함수
  onAfter: null, // after 콜백 함수
  onSuccess: null, //success 콜백 함수
  onError: null, // error 콜백 함수
});
