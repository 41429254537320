<template>
  <Input
    ref="input"
    :value="newValue"
    :clearable="false"
    :hideDetails="hideDetails"
    :disabled="disabled"
    :errorMessage="innerErrorMessage"
    :appendClass="appendClass"
    v-bind="$attrs"
    v-on="$listeners"
    @paste="handlePaste"
    @keydown="handleKeydown"
    @keypress="handleKeyPress"
    @keyup="handleKeyUp"
  />
</template>

<script>
import Vue from "vue";
import { commset, toNumber, checkNumberFormat } from "@/helper/mathHelper";
import Input from "../Input/Input.vue";

export default {
  props: {
    /** 값 */
    value: {
      type: [Number, String],
    },
    /** 보여지는 포맷 */
    format: {
      type: String, // "none" | "comma"
      default: "comma",
    },
    /** 최소값 */
    min: {
      type: Number,
    },
    /** 최대값 */
    max: {
      type: Number,
    },
    /** true일 경우 헬퍼 메시지 숨김 */
    hideDetails: {
      type: Boolean,
    },
    errorMessageEnabled: {
      type: Boolean,
      default: false,
    },
    inputErrorMessage: {
      type: String,
    },
    appendClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: { Input },
  emits: ["input"],
  data: () => ({
    newValue: "0",
    pressedKeyMap: {},
    innerErrorMessage: "",
  }),
  filters: {},
  computed: {},
  /** ===== watch ===== */
  watch: {
    value: {
      immediate: true,
      handler(num) {
        const type = typeof num;

        // # 포맷 검사
        if (type !== "number" && checkNumberFormat(String(num)) === false) {
          this.$emit("input", 0);
          return;
        }

        const { num: newNum, commseted } = toNumberCommset(num, {
          min: this.min,
          max: this.max,
        });

        // # 최솟값, 최대값 으로 인해 값이 변경되었을 경우
        if (type === "number" && num !== newNum) {
          this.$emit("input", newNum);
          return;
        }

        this.newValue = this.format === "comma" ? commseted : newNum;
      },
    },
  },
  /** ===== methods ===== */
  methods: {
    /** 붙여넣기 이벤트 */
    handlePaste(e) {
      e.preventDefault();
      const data = e.clipboardData.getData("text/plain");

      // # 포맷 검사
      if (checkNumberFormat(data) === false) {
        return;
      }

      // # `-`입력 시
      if (data === "-") {
        this.$emit("input", 0);
        this.newValue = "-";
        return;
      }

      this.$emit("input", toNumber(data));
    },
    /** 키업 */
    handleKeyUp(e) {
      const { code } = e;

      if (this.pressedKeyMap[code]) {
        Vue.delete(this.pressedKeyMap, code);
      }
    },
    /** 키 다운 이벤트(keypress 입력 외 처리) */
    handleKeydown(e) {
      const { code } = e;
      const value = e.target.value;
      const len = value.length;
      const startPosition = e.target.selectionStart ?? 0;
      const endPosition = e.target.selectionEnd ?? 0;
      const selectionText =
        startPosition !== endPosition
          ? value.substr(startPosition, endPosition - startPosition)
          : "";
      let newValue = value;
      let nextPosition = startPosition;

      // # 키 정보 입력
      this.pressedKeyMap[code] = true;

      // # 방향키 아래로 입력 시 -1 적용(shift를 누른 상태일 경우 -10)
      if (code === "ArrowDown") {
        newValue =
          toNumber(newValue) +
          (this.pressedKeyMap["ShiftLeft"] === true ? -10 : -1);
      }

      // # 방향키 위로 입력 시 +1 적용.(shift를 누른 상태일 경우 +10)
      if (code === "ArrowUp") {
        newValue =
          toNumber(newValue) +
          (this.pressedKeyMap["ShiftLeft"] === true ? 10 : 1);
      }

      // # delete 버튼
      if (code === "Delete" && startPosition < len) {
        const selectionText =
          startPosition !== endPosition
            ? value.substr(startPosition, endPosition - startPosition)
            : "";
        const { nextValue, before, after } = getNextValue({
          key: "",
          value,
          startPosition,
          endPosition: selectionText ? endPosition : startPosition + 1,
        });

        // console.log('> ');/

        // # 값 변경
        if (!selectionText && value[startPosition] === ",") {
          newValue = before + after.substring(1, after.length);
        } else if (value === "-") {
          newValue = 0;
          this.newValue = "0";
        } else {
          newValue = nextValue;
        }

        // # 캐럿 위치 보정
        if (!selectionText && value[startPosition] === ",") {
          nextPosition += 1;
        }
        if (!selectionText && /\d\,\d/.test(before.substring(0, 3))) {
          nextPosition -= 1;
        }
      }

      // # Backspace
      if (code === "Backspace" && startPosition > 0) {
        const { nextValue, before, after } = getNextValue({
          key: "",
          value,
          startPosition: selectionText ? startPosition : startPosition - 1,
          endPosition,
        });

        if (!selectionText && value?.[startPosition - 1] === ",") {
          newValue = before.substring(0, startPosition - 2) + after;
        } else if (value === "-") {
          newValue = 0;
          this.newValue = "0";
        } else {
          newValue = nextValue;
        }

        if (!selectionText && nextPosition > 0 && nextPosition < len) {
          if (nextPosition > 2) {
            nextPosition -= newValue?.[1] === "," ? 2 : 1;
          } else {
            nextPosition -= 1;
          }
        }
      }

      // # Space
      if (code === "Space" && selectionText.length > 0) {
        const { before, after } = getNextValue({
          key: "",
          value,
          startPosition,
          endPosition,
        });

        if (value === "-" && startPosition === 0 && endPosition === 1) {
          newValue = 0;
          this.newValue = "0";
        } else {
          newValue = before + after;
        }
      }

      // # 값 변경 시 input 입력
      if (value !== newValue) {
        e.preventDefault();
        const num = toNumber(newValue);

        this.setValue(num);
        this.$nextTick().then(() => {
          e.target.setSelectionRange(nextPosition, nextPosition);
        });
      }
    },
    /** 키 프레스 이벤트 */
    handleKeyPress(e) {
      e.preventDefault();
      const { key } = e;
      const value = e.target.value;
      const startPosition = e.target.selectionStart ?? 0;
      const endPosition = e.target.selectionEnd ?? 0;
      const { nextValue: newValue, selectionText } = getNextValue({
        key: e.key,
        value: e.target.value,
        startPosition,
        endPosition,
      });

      // # 지정된 포맷외 입력 블럭
      if (!/[-0-9]/.test(key)) {
        return;
      }

      // # 전체 선택 혹은 `0`만 있을 경우 `-` or `0`입력 시
      if (
        (value === "0" ||
          (selectionText && selectionText.length === value.length)) &&
        (key === "-" || key === "0")
      ) {
        let nextNumber = adjustingRangeNumber(0, {
          min: this.min,
          max: this.max,
        });

        this.$emit("input", nextNumber);
        this.$nextTick().then(() => {
          this.newValue = nextNumber === 0 && key === "-" ? "-" : nextNumber;
        });
        return;
      }

      // # 입력 포맷 검사
      if (!checkNumberFormat(newValue)) {
        return;
      }

      if (this.errorMessageEnabled) {
        if (newValue > this.max) {
          this.innerErrorMessage = this.inputErrorMessage;
        } else {
          this.innerErrorMessage = "";
        }
      }
      // # `@input` 이벤트 발생
      const { num, commseted } = toNumberCommset(newValue, {
        min: this.min,
        max: this.max,
      });
      const commanCnt = value.match(/\,/g)?.length ?? 0;
      const nextCommanCnt = commseted.match(/\,/g)?.length ?? 0;
      const changeCommaAction =
        nextCommanCnt > commanCnt
          ? "inc"
          : nextCommanCnt < commanCnt
          ? "dec"
          : undefined;

      // 캐럿 위치 보정
      let nextPosition = startPosition + 1;

      // # 콤마 추가 된후 캐럿 위치 보정
      if (changeCommaAction === "inc") {
        nextPosition += 1;
      }

      this.setValue(num);
      this.$nextTick().then(() => {
        e.target.setSelectionRange(nextPosition, nextPosition);
      });
      // });
    },
    /** 값을 저장 */
    setValue(num) {
      this.$emit("input", num);
    },
  },
};

/** commset, number 를 반환 */
const toNumberCommset = (data, { min, max } = {}) => {
  const newData = String(data ?? "")?.replace(/\,/g, "");
  let num = toNumber(newData);

  num = adjustingRangeNumber(num, { min, max });

  const commseted = commset(num);

  return {
    num,
    commseted,
  };
};

/** 최소/최대값 조정 */
const adjustingRangeNumber = (num, { min, max }) => {
  // # 최소값
  if (typeof min === "number" && min > num) {
    return min;
  }
  // # 최대값
  if (typeof max === "number" && max < num) {
    return max;
  }

  return num;
};

/** 입력된 값을 기준으로 다음에 생성될 값을 반환 */
const getNextValue = ({ value, key, startPosition = 0, endPosition = 0 }) => {
  const len = value.length;
  const selectionText =
    startPosition !== endPosition
      ? value.substr(startPosition, endPosition - startPosition)
      : "";
  // 커서 기준 앞의 문자열
  const before = value.substr(0, startPosition);
  // 커서 기준 뒤의 문자열
  const after = value.substr(selectionText ? endPosition : startPosition, len);
  // 전체 문자열
  const nextValue = before + key + after;

  return {
    nextValue,
    selectionText,
    before,
    after,
  };
};
</script>

<style lang="scss" scoped></style>
