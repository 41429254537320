
<v-form
  ref="form"
  v-model="valid"
  :class="[
    useAutoLayout &&
      'grid lg:grid-cols-[140px,_1fr,_140px,_1fr] md:grid-cols-1 gap-x-8 max-w-[960px]',
  ]"
  @submit.prevent="submit"
>
  <slot
    :pending="pending"
    :diff="diff"
    :valid="valid"
    :validate="validate"
    :defaultValues="defaultValues"
    :values="values"
    :setValue="setValue"
    :setValues="setValues"
    :mergeValues="mergeValues"
    :comparisonValues="comparisonValues"
    :setComparisonValues="setComparisonValues"
    :submitTrigger="submitTrigger"
    :submit="submit"
  ></slot>

  <div
    v-if="$scopedSlots.footer"
    class="flex justify-end gap-x-2 col-span-12"
  >
    <slot
      name="footer"
      :pending="pending"
      :diff="diff"
      :valid="valid"
      :validate="validate"
      :defaultValues="defaultValues"
      :values="values"
      :setValue="setValue"
      :setValues="setValues"
      :mergeValues="mergeValues"
      :comparisonValues="comparisonValues"
      :setComparisonValues="setComparisonValues"
      :submitTrigger="submitTrigger"
      :submit="submit"
    >
    </slot>
  </div>
</v-form>
