
<div class="app-detail-rde-popup">
  <div class="header">
    <p>
      <v-icon @click="turnOffFunc"> mdi-arrow-right </v-icon>
      SSH Information - {{ selectedRde?.name }}
    </p>
  </div>
  <div class="data-view-wrapper m-4">
    <div class="flex justify-between">
      <div class="title">Private Key</div>
      <div class="flex flex-row gap-2">
        <button class="mb-1" @click="copyToClipboard(privateKeyStr)">
          <v-icon>mdi-content-copy</v-icon>
        </button>
        <button
          class="mb-1"
          @click="
            downloadAsFile(
              privateKeyStr,
              fileName || selectedRde.ideConfigCrdName + '-ssh-key',
            )
          "
        >
          <v-icon>mdi-download</v-icon>
        </button>
      </div>
    </div>
    <MonacoEditor
      language="yaml"
      :value="privateKeyStr || ''"
      :readOnly="true"
      :miniMap="false"
      :hideDetails="true"
      height="38vh"
      class="editor-wrap"
      lineNumbers="off"
    />
  </div>
  <div class="data-view-wrapper m-4">
    <div class="flex justify-between">
      <div class="title">SSH Config</div>
      <div class="flex flex-row gap-2">
        <button class="mb-1" @click="copyToClipboard(sshConfigStr)">
          <v-icon>mdi-content-copy</v-icon>
        </button>
        <button
          class="mb-1"
          @click="downloadAsFile(sshConfigStr, generateFilename('sshConfig'))"
        >
          <v-icon>mdi-download</v-icon>
        </button>
      </div>
    </div>
    <MonacoEditor
      language="yaml"
      :value="sshConfigStr || ''"
      :readOnly="true"
      :miniMap="false"
      :hideDetails="true"
      height="38vh"
      theme="vs-white"
      class="editor-wrap"
      lineNumbers="off"
    />
  </div>
</div>
