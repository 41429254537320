/**
 * 입력은 값을 number로 반환한다
 * @param {any} num 넘버로 변환을 원하는 값
 * @return {number}
 */
export const toNumber = (num) => {
  if (!num) {
    return 0;
  }

  const type = typeof num;
  let newNumber = num;

  if (type === "number") {
    return newNumber;
  }

  if (type === "string") {
    newNumber = /^-?\d+$/.test(newNumber)
      ? newNumber
      : newNumber?.replace(/[^-0-9]/g, "");
    return Number(newNumber);
  }

  if (isNaN(newNumber) === true) {
    return 0;
  }

  return newNumber;
};

/**
 * 숫자 입력 시 3자리 마다 콤마가 붙은 문자열 반환
 * @param {number | string} num 넘버 숫자 혹은 문자
 * @return {string}
 */
export const commset = (num) => {
  return new Intl.NumberFormat("ko").format(toNumber(num));
};

/**
 * 입력된 값이 문자열이면서 숫자 형식에 맞는 포맷인지 검사
 * @param {string} data 숫자형식 문자열
 * @returns {boolean}
 */
export const checkNumberFormat = (data) => {
  if (typeof data !== "string") {
    return false;
  }

  return [/^-$/, /^-?[0-9]+$/, /^-?(\d{1,4}\,\d{1,4})+$/].some((r) =>
    r.test(data),
  );
};

/**
 * 직사각형의 중점 구하기
 * @param {{x:number,y:number}} p1 - 사각형의 꼭지점
 * @param {{x:number,y:number}} p2 - p1의 대각선 꼭지점
 */
export const getRectangleCenterPoint = (p1, p2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
};

/**
 * byte를 사이즈에 따라 지정된 포맷으로 보여준다
 * @param {number} bytes
 * @param {number} decimals - 소수점 자릿수
 * @returns {number}
 */
export const byteToSize = (bytes, decimals = 2) => {
  const newByte = Number(bytes);

  if (!bytes || isNaN(newByte)) {
    return `0 Byte`;
  }

  const kbToBytes = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Byte",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const index = Math.floor(Math.log(newByte) / Math.log(kbToBytes));
  const num = parseFloat((newByte / Math.pow(kbToBytes, index)).toFixed(dm));

  return `${num} ${sizes[index]}`;
};
