
<v-select
  :id="id"
  ref="root"
  v-bind="$attrs"
  :value.sync="value"
  class="atoms__select"
  :class="[`size-${size}`, appendClass]"
  :items="items"
  :outlined="outlined"
  :dense="dense"
  :solo="solo"
  :type="type"
  :error-messages="errorMessage"
  :rules="rules"
  :disabled="disabled"
  :item-title="itemTitle"
  :item-value="itemValue"
  :return-object="returnObject"
  :label="label"
  @change="(...args) => $emit('change', ...args)"
  v-on="$listeners"
>
  <!-- "Select All" 옵션은 selectAll 가 true일 때만 렌더링 -->
  <template v-if="selectAll" v-slot:prepend-item>
    <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
      <v-list-item-action>
        <v-icon :color="value?.length > 0 ? 'indigo darken-4' : ''">
          {{ selectAllIcon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title> Select All </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mt-0"></v-divider>
  </template>

  <template v-if="selectAll" v-slot:selection="{ item, index }">
    <Chip v-if="index === 0">
      <span>{{ item.name }}</span>
    </Chip>
    <span
      v-if="index === 0 && value?.length > 1"
      class="grey--text text-caption"
    >
      (+{{ value?.length - 1 }} others)
    </span>
    <span v-if="index === 0 && value?.length === 0"> Select All </span>
  </template>

  <template v-else-if="$scopedSlots.item" #item="item">
    <slot name="item" v-bind="item"></slot>
  </template>
  <template v-else-if="$scopedSlots.selection" #selection="data">
    <slot name="selection" v-bind="data"></slot>
  </template>
</v-select>
