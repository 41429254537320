/**
 * 기능에 대한 차단 여부 정의
 */

export default {
  /**
   * 플러그인 인스톨
   * @param {any} app
   * @param {boolean} sw - false일 경우 기능 차단
   */
  install(app, sw) {
    app.prototype.$useTopology = sw;
    app.prototype.$useFeatures = {
      istio: true,
    };
  },
};
