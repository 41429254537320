// @ts-check
export default {
  async install(Vue) {
    // # 초기 버전 정보 저장
    Vue.prototype.$version = await fetchOriginVersion();

    intervalChecker((originVersion) => {
      // # 버전이 다를 경우 경고문
      if (originVersion === Vue.prototype.$version) {
        return;
      }
      alert(
        "AMDP 웹사이트가 업데이트 되었습니다. 확인을 누르면 기존 화면이 계속 표시됩니다.\n업데이트된 버전 반영을 위해 확인을 누른 뒤 브라우저를 새로고침 해주십시요.",
      );
    });
  },
};

let timeoutId;

/**
 * 일정 주기로 서버의 버전 파일을 가져온다
 * @param {function} f 인터벌시 실행 함수
 */
const intervalChecker = (f) => {
  timeoutId = setTimeout(async () => {
    try {
      const version = await fetchOriginVersion();
      if (typeof f === "function") {
        f(version);
      }

      return intervalChecker(f);
    } catch (error) {
      clearTimeout(timeoutId);
      throw new Error("failed version check");
    }
  }, 1000 * 60 * 5);
};

/**
 * 원격지의 버전정보를 가져와 문자열로 반환한다
 * @returns {Promise<string>} 버전 정보
 */
const fetchOriginVersion = async () => {
  const json = await fetch("/version.json?time=" + new Date().getTime(), {
    method: "GET",
    headers: {
      Accept: "appliication/json",
    },
  }).then((res) => res.json());
  return json.version;
};
