<template>
  <v-data-table
    :headers="headers"
    :items="data"
    hide-default-footer
    dense
    disable-pagination
    class="table__wrapper_resource_rde no-border"
  >
    <template v-slot:[`item.serviceType`]="{ item }">
      <label class="service-type">{{ item.serviceType }}</label>
    </template>
    <template v-slot:[`item.cpu`]="{ item }">
      <td>
        <div class="flex flex-col justify-center p-2">
          <div class="flex flex-row gap-1">
            <SvgIcon iconName="cpu" size="sm" color="#87888C" />
            <label class="label">CPU</label>
          </div>
          <div class="resource-box">
            <span
              class="usage-percentage"
              :style="$helper.getUsageStyle(item?.cpu?.usage, item?.cpu?.limit)"
            ></span>
            <label class="content text-start">
              {{ $helper.viewCpuData2(item?.cpu?.usage, item?.cpu?.limit) }}
            </label>
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.memory`]="{ item }">
      <td>
        <div class="flex flex-col justify-center p-2">
          <div class="flex flex-row gap-1">
            <SvgIcon iconName="memory" size="sm" color="#87888C" />
            <label class="label">Memory</label>
          </div>
          <div class="resource-box">
            <span
              class="usage-percentage"
              :style="
                $helper.getUsageStyle(item?.memory?.usage, item?.memory?.limit)
              "
            ></span>
            <label class="content text-start">
              {{
                $helper.viewMemoryData2(
                  item?.memory?.usage,
                  item?.memory?.limit,
                )
              }}
            </label>
          </div>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";
import {
  getWorkspaceRdeById,
  getRDEStateMetrics,
} from "@/service/apis/workspaceApis";
import { isEmpty, maxBy } from "lodash";
import { roundNumber } from "../dashboard/helper";

export default {
  props: { selectedResourceRDE: { type: Object, default: () => null } },
  data: () => ({
    headers: [
      {
        text: "",
        sortable: false,
        value: "serviceType",
        width: "30%",
        class: "header-table",
      },
      {
        text: "",
        sortable: false,
        value: "cpu",
        width: "35%",
        class: "header-table",
      },
      {
        text: "",
        sortable: false,
        value: "memory",
        width: "35%",
        class: "header-table",
      },
      // {
      //   text: "Disk R/W",
      //   sortable: false,
      //   value: "disk",
      //   class: "header-table",
      //   width: "25%",
      // },
    ],
    data: [],
    matchingKeys: {
      vscode: "vscodeserver",
      webssh: "sshserver",
      notebook: "jupyter",
    },
  }),
  methods: {
    excludeUnitText(value) {
      const CHARACTERS_TO_REMOVE = ["m", "mi", "Gi", "Mi", "gi"];

      if (typeof value === "string") {
        CHARACTERS_TO_REMOVE.forEach((str) => {
          value = value.replace(new RegExp(str, "g"), "");
        });
        return value;
      }
      return value;
    },
    // currently grafana returns one object with key as timestamp and value as the metric value
    // we would like to get the latest value from the object
    getLastestValueFromObj(obj) {
      if (isEmpty(obj)) {
        return 0;
      }

      const maxKey = maxBy(Object.keys(obj), (v) => new Date(v).getTime());
      if (maxKey) return parseFloat(obj[maxKey]) || 0;
      return 0;
    },
    transformValue({ rdeMeasure: rdeMeasureObj, rdeDetail, serviceType }) {
      // select measure object based on service type (vscode, webssh, notebook)
      const rdeMeasure = rdeMeasureObj?.containers?.find(
        (c) => c?.name === this.matchingKeys[serviceType],
      );

      if (rdeMeasure) {
        // convert core to milicore
        const currentCPU = roundNumber(
          this.getLastestValueFromObj(rdeMeasure.cpu) * 1000,
          0,
        );

        // convert byte to Mi
        const currentMemory = roundNumber(
          this.getLastestValueFromObj(rdeMeasure.memory) / 1048576,
          0,
        );

        return {
          cpu: {
            usage: currentCPU,
            limit: this.excludeUnitText(
              rdeDetail?.[serviceType]?.resourceSize.cpu,
            ),
          },
          memory: {
            usage: currentMemory,
            limit: this.excludeUnitText(
              rdeDetail?.[serviceType]?.resourceSize.memory,
            ),
          },
        };
      }
      return {
        cpu: {
          usage: 0,
          limit: this.excludeUnitText(
            rdeDetail?.[serviceType]?.resourceSize.cpu,
          ),
        },
        memory: {
          usage: 0,
          limit: this.excludeUnitText(
            rdeDetail?.[serviceType]?.resourceSize.memory,
          ),
        },
      };
    },
    fetchData() {
      if (!isEmpty(this.selectedResourceRDE)) {
        CommonUIControl.ShowUIProgress();

        Promise.all([
          getWorkspaceRdeById({ rdeId: this.selectedResourceRDE.rdeId }),
          getRDEStateMetrics({
            rdeId: this.selectedResourceRDE.rdeId,
            namespace: this.selectedResourceRDE.namespace,
            rde: this.selectedResourceRDE.rde,
            wsName: this.selectedResourceRDE.wsName,
          }),
        ])
          .then((res) => {
            const rdeDetail = res?.[0]?.data;
            const rdeMeasure = res?.[1]?.data?.result;

            if (!rdeDetail || !rdeMeasure) {
              throw new Error("Resource detail or measure is empty");
            }

            const data = [];
            if (rdeDetail?.vscode?.resourceSize) {
              data.push({
                serviceType: "VS Code",
                ...this.transformValue({
                  rdeMeasure,
                  rdeDetail,
                  serviceType: "vscode",
                }),
              });
            }

            if (rdeDetail?.webssh?.resourceSize) {
              data.push({
                serviceType: "SSH",
                ...this.transformValue({
                  rdeMeasure,
                  rdeDetail,
                  serviceType: "webssh",
                }),
              });
            }

            if (rdeDetail?.notebook?.resourceSize) {
              data.push({
                serviceType: "Jupiter",
                ...this.transformValue({
                  rdeMeasure,
                  rdeDetail,
                  serviceType: "notebook",
                }),
              });
            }
            this.data = data;
          })
          .catch((e) => {
            CommonUIControl.ShowErrorToast(
              e?.message || "Fetch resource detail failed",
              5000,
            );
          })
          .finally(() => {
            CommonUIControl.HideUIProgress();
          });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
.table__wrapper_resource_rde {
  & .header-table {
    height: 0 !important;
  }
  & .text-start {
    color: black;
    font-size: 12px !important;
    font-weight: 400;
    text-align: start;
  }

  & th,
  tr,
  td {
    border: none !important;
  }

  .resource-box {
    width: 95%;
    height: 20px;
    background-color: #e4e5eb;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
  }
  .usage-percentage {
    content: " ";
    height: 100%;
    background-color: aquamarine;
    position: absolute;
    top: 0;
    left: 0;
  }
  .content {
    margin-left: 3px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
  }

  .service-type {
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
