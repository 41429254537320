<template>
  <RouterView v-if="allowed" />
</template>

<script>
/**
 * 프로젝트 멤버가 아닐 경우 접근 불가
 */
import { RequestMixin } from "@/mixins/RequestMixin";
import api from "@/service/api";
import { mapState } from "vuex";

export default {
  props: {
    /** 접근 가능 role */
    allowedRoles: {
      type: Array,
      default: undefined,
    },
  },
  mixins: [RequestMixin],
  data: () => ({
    allowed: false,
  }),
  computed: {
    ...mapState({
      role: (state) => state.accountInfo.auth,
    }),
  },
  methods: {
    /** 가드 */
    guard(projects) {
      // # role 검사
      if (
        this.allowedRoles?.length > 0 &&
        this.allowedRoles.some((a) => a === this.role) === true
      ) {
        this.allowed = true;
        return;
      }

      // # 소유 프로젝트 검사
      if (projects.length === 0) {
        this.allowed = false;
        return;
      }

      // # 프로젝트 멤버인지 확인
      const { id, projectId } = this.$route.params;
      let newProjectId = id ?? projectId;
      newProjectId = Number(newProjectId);

      if (projects.some((a) => a.id === newProjectId) === false) {
        this.allowed = false;
        return;
      }

      this.allowed = true;
    },
  },
  async mounted() {
    // console.log("> project guard : ", this.$route.path);

    const projects = await this.query(() => api.getProjects());
    this.guard(projects);
  },
};
</script>

<style lang="scss" scoped></style>
