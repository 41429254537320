<template>
  <!-- <div v-if="isShow"> -->
  <LineChartJS v-if="isShow" :data="finalData" :options="finalOptions" />
  <!-- </div> -->
</template>

<script lang="ts">
import { Line as LineChartJS } from "vue-chartjs";

import { isEmpty } from "lodash";
import { transformOptions, transformPlugins, transformData } from "./handler";

export default {
  name: "LineChart",
  props: ["data", "options", "plugins"],
  components: {
    LineChartJS,
  },
  data() {
    return {};
  },
  computed: {
    isShow: function () {
      return !isEmpty(this.data?.datasets);
    },
    finalOptions() {
      // In next time, we can customize something before push into line chart component
      return transformOptions(this.options);
    },
    finalData() {
      return transformData(this.data);
    },
    finalPlugins() {
      return transformPlugins(this.plugins || []);
    },
  },
  mounted() {},
  updated() {},
};
</script>
