<template>
  <v-text-field
    :id="id"
    ref="input"
    class="app-g-text-field atoms__input"
    autocomplete="off"
    :disabled="disabled"
    :readonly="readonly"
    :placeholder="placeholder"
    :outlined="outlined"
    :dense="dense"
    :clearable="newClearable"
    :type="type"
    :value="value"
    :rules="rules"
    :counter="counter"
    :error-messages="innerErrorMessage"
    :queryValidation="queryValidation"
    :hideDetails="hideDetails"
    :class="[
      `size-${size}`,
      text && 'text-style',
      hideDetails && 'hide-details',
      newBackgroupColor && bgClass.router(newBackgroupColor),
      appendClass,
    ]"
    v-bind="$attrs"
    :label="label"
    v-on="$listeners"
    @click.stop
    @input="handleInput"
  >
    <template v-if="$scopedSlots.prepend" slot="prepend">
      <slot name="prepend"></slot>
    </template>
    <template v-if="$scopedSlots.append" slot="append">
      <slot name="append"></slot>
    </template>
  </v-text-field>
</template>

<script>
import { debounce } from "@/service/topologyHelper";

export default {
  props: {
    /** true 일 경우 비활성화 */
    disabled: {
      type: Boolean,
    },
    /** true 일 경우 읽기전용 */
    readonly: {
      type: Boolean,
    },
    /** true 일 경우 외곽선 활성화(default: true) */
    outlined: {
      type: Boolean,
      default: true,
    },
    /** true일 경우 일반 텍스트처럼 보이게 한다. */
    text: {
      type: Boolean,
    },
    /** true일 경우 기본 크기가 글자 하나 크기가 된다. */
    fit: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    /** true일 경우 하단의 text 라인이 사라진다. */
    hideDetails: {
      type: Boolean,
      default: false,
    },
    /** true일 경우 값을 한번에 지울 수 있는 버튼 활성화 */
    clearable: {
      type: Boolean,
      default: true,
    },
    /** 아이디(상위에서 적용되지 않게 하기 위함) */
    id: {
      type: String,
    },
    /** 플레이스 홀더 */
    placeholder: {
      type: String,
      default: undefined,
    },
    /** 입력 타입("text"|"number") */
    type: {
      type: String,
      default: undefined,
    },
    value: {
      type: [String, Number, null, undefined],
      default: undefined,
    },
    /** 크기("xs"|"sm"|"md"|"lg") */
    size: {
      type: String,
      default: "md",
    },
    /** 배경색(undefiend|"transparent"|"white") */
    backgroundColor: {
      type: String,
      default: undefined,
    },
    /** 정의 시 validate 호출 시 유효성 검사 */
    rules: {
      type: Array,
      default: undefined,
    },
    /** 입력제한 수 표시 */
    counter: {
      type: Number,
      default: undefined,
    },
    /** 정의 시 에러 메시지 표시 */
    errorMessage: {
      type: [String, Array],
      default: undefined,
    },
    /** 값을 입력시마다 디바운스를 이용해 유효성 검사를 한다. 문자열 반환 시 에러 표시  */
    queryValidation: {
      type: Function,
      default: undefined,
    },
    label: {
      type: String,
    },
    appendClass: {
      type: String,
    },
  },
  emits: ["input", "enter"],
  data: () => ({
    innerErrorMessage: null,
    bgClass,
  }),
  /** ===== computed ===== */
  computed: {
    newBackgroupColor() {
      if (this.text === true) {
        return "transparent";
      }
      return this.backgroundColor;
    },
    newClearable() {
      if (this.$props.readonly) {
        return false;
      }
      return this.clearable;
    },
  },
  /** ===== watch ===== */
  watch: {
    /** 에러 메시지 */
    errorMessage: {
      immediate: true,
      handler(newErrorMessage) {
        this.innerErrorMessage = newErrorMessage;
      },
    },
  },
  methods: {
    handleInput(v) {
      debounce(async () => {
        // # query validation 실행
        if (typeof this.queryValidation === "function") {
          this.innerErrorMessage = "Checking...";

          const res = await this.queryValidation(v);

          if (typeof res === "string") {
            this.innerErrorMessage = res;
          } else {
            this.innerErrorMessage = this.errorMessage ?? null;
          }
        }
      }, 300);
      this.$emit("input", v);
    },
  },
  mounted() {},
};

const bgClass = {
  transparent: () => "input__bg-transparent",
  white: () => "input__bg-white",
  router(color) {
    if (!this[color]) {
      throw new Error(`invalid color (bg.router()): ` + color);
    }
    return this[color]();
  },
};
</script>

<style lang="scss">
.atoms__input {
  @apply items-center;
  &.v-input--is-disabled .v-input__slot > fieldset {
    background-color: #6c757d4d;
  }

  /** ===== base ===== */
  & .v-input__slot {
    min-height: initial !important;
  }
  & .v-text-field__slot {
    padding-top: 0px !important;
  }
  & input {
    padding: 0px;
    line-height: 1 !important;
  }
  & .v-input__append-inner {
    margin-top: 0px !important;
    align-self: center;
  }

  /** ===== disabled ===== */
  &.v-input--is-disabled .v-input__slot > fieldset {
    background-color: #e5e7eb !important;
  }

  /** ===== hide details ===== */
  &.hide-details .v-input__slot {
    margin-bottom: 0px !important;
  }

  /** ===== text ===== */
  &.text-style {
    & .v-input__slot {
      padding: 0 !important;
    }
    & .v-input__slot > fieldset {
      border-color: transparent !important;
    }
    & input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  /** ===== background-color ===== */
  &.input__bg-transparent.v-text-field--outlined .v-input__slot fieldset {
    @apply bg-transparent;
  }
  &.input__bg-white.v-text-field--outlined .v-input__slot fieldset {
    @apply bg-white;
  }

  /** ===== size ===== */
  &.size-xs {
    & .v-input__slot {
      height: 20px;
    }
    & input {
      font-size: 0.625rem !important;
    }
  }
  &.size-sm {
    & .v-input__slot {
      height: 28px;
    }
    & input {
      font-size: 0.75rem !important;
    }
  }
  &.size-md {
    & .v-input__slot {
      height: 36px;
    }
    & input {
      font-size: 0.875rem !important;
    }
  }
  &.size-lg {
    & .v-input__slot {
      height: 44px;
    }
    & input {
      font-size: 0.875rem !important;
    }
  }
  /** ===== prepend ===== */
  & > .v-input__prepend-outer {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px;
  }
}
</style>
