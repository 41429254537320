
<v-btn
  class="atoms__button"
  :color="color"
  :x-small="size === 'xs'"
  :small="size === 'sm'"
  :large="size === 'lg'"
  :x-large="size === 'xl'"
  :medium="size === 'md'"
  :elevation="elevation"
  :text="fill === true ? false : text"
  :icon="icon"
  :block="block"
  :disabled="disabled || loading"
  :loading="loading"
  :outlined="fill === true ? false : outlined"
  :class="[block, $attrs.to && 'is-link']"
  v-bind="$attrs"
  v-on="$listeners"
>
  <slot></slot>

  <!-- tooltip -->
  <Tooltip v-if="tooltip" :position="tooltipPosition" closest=".v-btn">
    <span>{{ tooltip }}</span>
  </Tooltip>
</v-btn>
