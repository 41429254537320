<template>
  <div class="w-full">
    <h3 class="table-title pb-2">RDE Usage</h3>
    <v-data-table
      :headers="headers"
      :items="items"
      :hide-default-header="true"
      item-key="id"
      class="elevation-1 rde-usage-table w-full"
      show-expand
      :expanded.sync="expanded"
      :single-expand="singleExpand"
    >
      <template v-slot:header="{ props }">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="header-cell"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr>
          <td class="expand-icon-cell">
            <v-icon @click="expand(!isExpanded)">
              {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </td>
          <td>{{ item.rdeName }}</td>
          <td>{{ item.workspaceName }}</td>
          <td>{{ item.instanceType }}</td>
          <td>{{ formatDate(item.createdDate) }}</td>
          <td>{{ item.used }}</td>
          <td>{{ item.billing }}</td>
          <td>{{ item.creator }}</td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="3"></td>
        <td :colspan="headers.length + 1" class="pb-1">
          <v-data-table
            :headers="headersExpand"
            :items="item.details"
            :hide-default-header="false"
            :hide-default-footer="true"
            item-key="id"
            class="elevation-1 rde-usage-table w-full"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.instanceType }}</td>
                <td>{{ item.cpu }}</td>
                <td>{{ item.memory }}</td>
                <td>{{ item.disk }}</td>
                <td>{{ item.diskType }}</td>
                <td>{{ item.used }}</td>
                <td>{{ item.billing }}</td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No data available
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      expanded: [],
      singleExpand: true,
      search: "",
      headers: [
        { text: "RDE Name", value: "rdeName", sortable: false },
        { text: "Workspace Name", value: "workspaceName", sortable: false },
        { text: "Instance Type", value: "instanceType", sortable: false },
        { text: "Created Date", value: "createdDate", sortable: false },
        { text: "Used", value: "used", sortable: false },
        { text: "Billing", value: "billing", sortable: false },
        { text: "Creator", value: "creator", sortable: false },
      ],
      items: [
        {
          id: 1,
          rdeName: "{rde-name}",
          workspaceName: "{workspace-name}",
          instanceType: "Node",
          cpu: "2c",
          memory: "200Gi",
          disk: "200Gi",
          diskType: "Block-storage",
          createdDate: "2024-09-24",
          used: "1d 10h",
          billing: "₩20,000",
          creator: "skcc-test",
          details: [
            {
              instanceType: "Node",
              cpu: "1c",
              memory: "100Gi",
              disk: "100Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩10,000",
              creator: "skcc-test",
            },
            {
              instanceType: "Node",
              cpu: "1c",
              memory: "100Gi",
              disk: "100Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩10,000",
              creator: "skcc-test",
            },
          ],
        },
        {
          id: 2,
          rdeName: "{rde-name}",
          workspaceName: "{workspace-name}",
          instanceType: "Node",
          cpu: "3c",
          memory: "300Gi",
          disk: "300Gi",
          diskType: "Block-storage",
          createdDate: "2024-09-24",
          used: "1d 10h",
          billing: "₩30,000",
          creator: "skcc-test",
          details: [
            {
              instanceType: "Node",
              cpu: "1c",
              memory: "100Gi",
              disk: "100Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩10,000",
              creator: "skcc-test",
            },
            {
              instanceType: "Node",
              cpu: "1c",
              memory: "100Gi",
              disk: "100Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩10,000",
              creator: "skcc-test",
            },
            {
              instanceType: "Node",
              cpu: "1c",
              memory: "100Gi",
              disk: "100Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩10,000",
              creator: "skcc-test",
            },
          ],
        },
        {
          id: 3,
          rdeName: "{rde-name}",
          workspaceName: "{workspace-name}",
          instanceType: "Node",
          cpu: "5c",
          memory: "500Gi",
          disk: "500Gi",
          diskType: "Block-storage",
          createdDate: "2024-09-24",
          used: "1d 10h",
          billing: "₩50,000",
          creator: "skcc-test",
          details: [
            {
              instanceType: "Node",
              cpu: "2c",
              memory: "200Gi",
              disk: "200Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩20,000",
              creator: "skcc-test",
            },
            {
              instanceType: "Node",
              cpu: "1c",
              memory: "100Gi",
              disk: "100Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩10,000",
              creator: "skcc-test",
            },
            {
              instanceType: "Node",
              cpu: "1c",
              memory: "100Gi",
              disk: "100Gi",
              diskType: "Block-storage",
              createdDate: "2024-09-24",
              used: "1d 10h",
              billing: "₩10,000",
              creator: "skcc-test",
            },
          ],
        },
      ],
      headersExpand: [
        { text: "Instance Type", value: "instanceType", sortable: false },
        { text: "CPU", value: "cpu", sortable: false },
        { text: "Memory", value: "memory", sortable: false },
        { text: "Disk", value: "disk", sortable: false },
        { text: "Disk Type", value: "diskType", sortable: false },
        { text: "Used", value: "used", sortable: false },
        { text: "Billing", value: "billing", sortable: false },
      ],
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style type="scss" scoped>
.elevation-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.header-cell {
  background-color: #e4e5eb;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
}
.table-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
}
.rde-usage-table {
  tr > td {
    font-size: 12px !important;
    font-weight: 400;
    letter-spacing: 0.004em;
    text-align: left;
  }
}
</style>
