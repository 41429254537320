
<PageTemplate
  :useCard="false"
  :breadcrumbs="[
    {
      text: 'Billing',
    },
  ]"
>
  <MeteringPricing />
</PageTemplate>
