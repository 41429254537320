<template>
  <date-range-picker
    v-model="dateRange"
    :opens="opens"
    :timePicker="timePicker"
    :timePicker24Hour="timePicker24Hour"
    :time-picker-increment="1"
    :showDropdowns="showDropdowns"
    class="custom-date-range-picker"
    :ranges="ranges"
    @toggle="toggleDateTimePicker"
    @update="updateValues"
  >
    <template #input="picker">
      {{ picker | date(viewFormat) }}
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import CommonUIControl from "@/helper/CommonUIControl";
import { cloneDeep } from "lodash";

const formatDate = (val, format) => {
  return val ? moment(val).format(format) : format;
};

export default {
  name: "SlotsDemo",
  props: {
    opens: {
      type: String,
      default: "center",
    },
    timePicker: {
      type: Boolean,
      default: true,
    },
    timePicker24Hour: {
      type: Boolean,
      default: true,
    },
    showDropdowns: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
    },
    genRanges: {
      type: Function,
    },
    maxDurationStartEnd: {
      type: Number, //  days
    },
    viewFormat: {
      type: String,
      default: "HH:mm DD/MM/YYYY",
    },
  },
  components: { DateRangePicker },
  data() {
    return { dateRange: { startDate: null, endDate: null }, ranges: null };
  },
  filters: {
    date(val, format) {
      if (!val?.startDate && !val?.endDate) return "Time duration";

      return `${formatDate(val?.startDate, format)} - ${formatDate(
        val?.endDate,
        format,
      )}`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.dateRange = this.value;
      },
    },
  },
  methods: {
    updateValues(value) {
      if (this.maxDurationStartEnd) {
        const startDate = moment(value.startDate);
        const endDate = moment(value.endDate);
        var durationInMinutes = moment
          .duration(endDate.diff(startDate))
          .asDays();

        if (durationInMinutes > this.maxDurationStartEnd) {
          CommonUIControl.ShowWarningToast(
            `Time Duration is too long and exceeds ${this.maxDurationStartEnd} days`,
          );
          this.dateRange = cloneDeep(this.value);

          return;
        }
      }

      this.$emit("input", value);
    },
    toggleDateTimePicker(isOpen) {
      if (isOpen) {
        if (this.genRanges) this.ranges = this.genRanges();
      }
    },
  },
  mounted() {
    if (this.genRanges) this.ranges = this.genRanges();
  },
};
</script>

<style lang="scss">
.custom-date-range-picker {
  width: 100%;
  > div {
    width: 100%;
    border: 1px solid rgb(229, 231, 235) !important;
    border-radius: 4px;
    font-size: 14px;
    color: var(--gray2);
    min-height: 40px;
    padding-top: 8px !important;
  }
}
</style>
