export default [
  {
    path: "projects/:projectId/profiles/:profileId/variables/add",
    component: () => import("../pages/variable/VariableAddPage"),
  },
  {
    path: "projects/:projectId/profiles/:profileId/variables/:variableId/edit",
    component: () => import("../pages/variable/VariableEditPage"),
  },
];
