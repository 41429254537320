<template>
  <RouterView v-if="allowed" />
</template>

<script>
import { checkTopologyAccess } from "@/service/apis/topology";
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  data: () => ({
    allowed: false,
  }),
  async mounted() {
    let res;
    try {
      res = await checkTopologyAccess();
      if (res.value && this.$useTopology) {
        this.allowed = true;
      }
    } catch (error) {
      this.allowed = false;
    }

    if (!this.allowed) {
      CommonUIControl.ShowErrorToast("접근할 수 없습니다");
      await this.$router.replace("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
