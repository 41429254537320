import Vue from "vue";
// import Vuetify from "vuetify/lib";
import Vuetify from "vuetify";
// import "@mdi/font/css/materialdesignicons.css";
// import "../styles/App.scss";
import { Ripple } from "vuetify/lib/directives";

// import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#321fdb",
        secondary: "#ced2d8",
        accent: "#2A2D45",
        error: "#e55353",
        info: "#39f",
        success: "#2eb85c",
        warning: "#f9b115",
        anchor: "#321fdb",
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
