/** 토폴로지의 타입별 그룹 정보를 정의 */
export const gropuRules = {
  ["springboot"]: () => ({
    group: "application",
    subGroups: [],
  }),
  ["react"]: () => ({
    group: "application",
    subGroups: [],
  }),
  ["vuejs"]: () => ({
    group: "application",
    subGroups: [],
  }),
  ["nexacro"]: () => ({
    group: "application",
    subGroups: [],
  }),
  ["docker"]: () => ({
    group: "application",
    subGroups: [],
  }),
  ["mariadb"]: () => ({
    group: "database",
    subGroups: [],
  }),
  ["oracle"]: () => ({
    group: "database",
    subGroups: [],
  }),
  ["postgresql"]: () => ({
    group: "database",
    subGroups: [],
  }),
  ["mongodb"]: () => ({
    group: "database",
    subGroups: [],
  }),
  ["redis"]: () => ({
    group: "inMemory",
    subGroups: ["cache", "session"],
  }),
  ["rabbitmq"]: () => ({
    group: "messageChannel",
    subGroups: [],
  }),
  ["kafka"]: () => ({
    group: "messageChannel",
    subGroups: [],
  }),
  ["ingress"]: () => ({
    group: "ingress",
    subGroups: [],
  }),
  ["pvc"]: () => ({
    group: "pvc",
    subGroups: [],
  }),
  router(type) {
    if (!this[type]) {
      throw new Error("gropuRules.router() invalid type: " + type);
    }
    return this[type](type);
  },
};
