
<div
  class="common__page-header"
  :class="[
    'h-11 px-8 grid grid-flow-col items-center bg-white',
    fixed === true
      ? 'sticky top-0 z-[5] shadow-md'
      : 'border-b border-b-gray-300',
  ]"
>
  <!-- left -->
  <div class="overflow-visible flex gap-4">
    <slot name="left"></slot>

    <!-- breadcrumbs -->
    <Breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />
    <slot name="left-next"></slot>
  </div>

  <!-- center -->
  <div class="flex justify-center items-center">
    <slot name="center"></slot>
  </div>

  <!-- right -->
  <div class="flex justify-end items-center">
    <a
      v-if="useBackButton"
      :class="$slots.right && 'mr-2'"
      @click.prevent="$router.back()"
    >
      <Button color="accent" text outlined>뒤로</Button>
    </a>
    <slot name="right"></slot>
  </div>
</div>
