const SHOW_INCOMPLETE = process.env.VUE_APP_MODE !== "release";

export default [
  {
    path: "AppstoreList",
    name: "AppstoreList",
    component: () =>
      import("../pages/main/modernframework/Project/AppstoreList.vue"),
    meta: {
      title: "App store",
      icon: "mdi-cube-outline",
      isMenuItem: SHOW_INCOMPLETE,
      model: false,
    },
    children: [
      {
        path: "ProjectAppstoreList",
        name: "Project" + "AppstoreList",
        components: () =>
          import("../pages/main/modernframework/Project/AppstoreList.vue"),
        meta: {
          title: "프로젝트 Boilerplate",
          isMenuItem: true,
        },
      },
      {
        path: "ServiceAppstoreList",
        name: "ServiceList",
        component: () =>
          import("../pages/main/modernframework/Project/ServiceList.vue"),
        meta: {
          title: "Service Boilerplate",
          isMenuItem: true,
        },
      },
    ],
  },
];
