<template>
  <v-select
    :id="id"
    ref="root"
    v-bind="$attrs"
    :value.sync="value"
    class="atoms__select"
    :class="[`size-${size}`, appendClass]"
    :items="items"
    :outlined="outlined"
    :dense="dense"
    :solo="solo"
    :type="type"
    :error-messages="errorMessage"
    :rules="rules"
    :disabled="disabled"
    :item-title="itemTitle"
    :item-value="itemValue"
    :return-object="returnObject"
    :label="label"
    @change="(...args) => $emit('change', ...args)"
    v-on="$listeners"
  >
    <!-- "Select All" 옵션은 selectAll 가 true일 때만 렌더링 -->
    <template v-if="selectAll" v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
        <v-list-item-action>
          <v-icon :color="value?.length > 0 ? 'indigo darken-4' : ''">
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Select All </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-0"></v-divider>
    </template>

    <template v-if="selectAll" v-slot:selection="{ item, index }">
      <Chip v-if="index === 0">
        <span>{{ item.name }}</span>
      </Chip>
      <span
        v-if="index === 0 && value?.length > 1"
        class="grey--text text-caption"
      >
        (+{{ value?.length - 1 }} others)
      </span>
      <span v-if="index === 0 && value?.length === 0"> Select All </span>
    </template>

    <template v-else-if="$scopedSlots.item" #item="item">
      <slot name="item" v-bind="item"></slot>
    </template>
    <template v-else-if="$scopedSlots.selection" #selection="data">
      <slot name="selection" v-bind="data"></slot>
    </template>
  </v-select>
</template>
<script>
export default {
  props: {
    /** 리스트 정의 */
    items: {
      type: Array,
      default: undefined,
    },
    /** true일 경우 외곽선 */
    outlined: {
      type: Boolean,
      default: true,
    },
    /** 크기를 지정 */
    size: {
      type: String,
      default: "md",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [Number, String, Boolean, Array],
      default: undefined,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    itemTitle: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: [String, Function],
      default: "value",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    appendClass: {
      type: String,
    },
  },
  emits: ["input", "change", "open", "close"],
  computed: {
    selectAllOption() {
      if (this.selectAll) {
        return this.value?.length === this.items.length;
      }
      return false;
    },
    selectAllIcon() {
      if (this.selectAll) {
        if (this.selectAllOption) return "mdi-close-box";
        if (this.value?.length > 0) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      }
      return false;
    },
  },
  methods: {
    toggleSelectAll() {
      var internalValue = [];
      if (this.selectAllOption) {
        internalValue = [];
      } else {
        // return-object
        internalValue = [...this.items].map((item) =>
          this.returnObject ? item : item[this.itemValue],
        );
      }
      this.$emit("input", internalValue);
      this.$emit("change", internalValue);
    },
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.root.isMenuActive;
      },
      (sw) => {
        if (sw) {
          this.$emit("open");
        } else {
          this.$emit("close");
        }
      },
    );
  },
};
</script>
<style lang="scss">
.atoms__select {
  /** ===== Base ===== */
  & .v-input__slot {
    min-height: initial !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  & .v-text-field__slot {
    padding-top: 0px !important;
  }
  & .v-select__selection {
    margin: 0 !important;
  }
  & .v-input__append-inner {
    position: relative;
    top: 0;
    right: 0;
  }
  & input {
    padding: 0px;
    line-height: 1 !important;
  }

  /** ===== disabled ===== */
  &.v-input--is-disabled {
    & .v-input__slot > fieldset {
      background-color: #e5e7eb !important;
    }
    & .v-select__slot input::placeholder {
      color: #9ca3af !important;
    }
  }

  /** ===== hidedetails ===== */
  &[hidedetails] .v-input__control {
    min-height: initial !important;
  }
  &[hidedetails] .v-input__slot {
    margin-bottom: 0px !important;
  }
}
</style>
