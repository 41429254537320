<template>
  <span
    ref="atoms__tooltip"
    class="atom__tooltip"
    :class="[this.targetSelf ? 'inline-block' : 'hidden']"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  /** ===== props ===== */
  props: {
    /** 가까운 조상 노드 기준노드로 삼는 셀렉터 정의(기본: parent) */
    closest: {
      type: String,
    },
    /** 기준 노드를 자기 자신으로 지정한다 */
    targetSelf: {
      type: Boolean,
    },
    /** 기준 노드를 자기 자신으로 지정시 사용될 툴팁 내용 */
    text: {
      type: String,
      default: "",
    },
    /** 최대 넓이 */
    maxWidth: {
      type: Number,
      default: 300,
    },
    /** 동적으로 생성되는 툴팁에 적용할 클래스 */
    contentClass: {
      type: String,
      default: "",
    },
    /** 툴팁 위치 */
    position: {
      type: String, // "top" | "right" | "bottom" | "left"
      default: "bottom",
    },
    /** 보여질 시 지연시간 */
    showDelay: {
      type: Number, // ms
      default: 0,
    },
    /** 백그라운드 컬러 */
    backgroundColor: {
      type: String,
    },
  },
  /** ===== data ===== */
  data: () => ({
    // 마우스 오버의 대상이 될 엘리먼트
    targetEl: null,
    // 생성된 툴팁 엘리먼트
    tooltipEl: null,
    // 보여지는 중일 경우 true
    showing: false,
    // 마우스를 누르고 있을 경우 true
    pressedMouse: false,
  }),
  /** ===== methods ===== */
  methods: {
    /** 마우스 엔터 */
    handleMouseEnter() {
      if (this.showing === true || this.pressedMouse === true) {
        return;
      }
      this.showing = true;

      const html =
        this.targetSelf === true ? this.text : this.toHTML(this.$slots.default);

      const { left, top, width, height } =
        this.targetEl.getBoundingClientRect();
      const margin = 14;
      const addedY = window.scrollY;
      const addedX = window.scrollX;

      let newLeft = left;
      let newTop = top;

      this.createTooltiop({
        top: newTop,
        left: newLeft,
        html,
      });

      const { width: tooltipWidth, height: tooltipHeight } =
        this.tooltipEl.getBoundingClientRect();

      if (this.position === "top") {
        newTop = top - tooltipHeight - margin + addedY;
        newLeft = left + width / 2 - tooltipWidth / 2 + addedX;
      } else if (this.position === "bottom") {
        newTop = top + height + margin + addedY;
        newLeft = left + width / 2 - tooltipWidth / 2 + addedX;
      } else if (this.position === "right") {
        newTop = top + height / 2 - tooltipHeight / 2 + addedY;
        newLeft = left + width + margin + addedX;
      } else if (this.position === "left") {
        newTop = top + height / 2 - tooltipHeight / 2 + addedY;
        newLeft = left - tooltipWidth - margin + addedX;
      } else {
        throw new Error("invalid position: " + this.position);
      }

      // # 범위를 넘어갈 시 처리
      if (tooltipWidth + left > window.innerWidth) {
        newLeft -= 44 - width / 2;
      }

      this.tooltipEl.style.top = `${newTop}px`;
      this.tooltipEl.style.left = `${newLeft}px`;

      // console.log("> enter: ", showDelayTimeoutId);
      if (showDelayTimeoutId) {
        clearTimeout(showDelayTimeoutId);
      }

      showDelayTimeoutId = setTimeout(() => {
        this.tooltipEl.classList.add("tooltip__show");
      }, this.showDelay);
    },
    /** 마우스 리브 */
    handleMouseLeave() {
      this.cleanupTooltipPopup();
      this.showing = false;
    },
    /** 마우스 다운 */
    handleMouseDown() {
      this.cleanupTooltipPopup();
      this.showing = false;
      this.pressedMouse = true;
    },
    /** 마우스 업 */
    handleMouseUp() {
      this.pressedMouse = false;
    },
    /** tooltip 엘리먼트 생성 */
    createTooltiop({ html }) {
      this.tooltipEl = document.createElement("div");
      this.tooltipEl.style.position = "absolute";
      this.tooltipEl.style.maxWidth = `${this.maxWidth}px`;
      this.tooltipEl.style.backgroundColor =
        this.backgroundColor ?? `rgba(0, 0, 0, 0.5)`;
      this.tooltipEl.style.backdropFilter = `blur(2px)`;
      this.tooltipEl.style.opacity = `0`;
      this.tooltipEl.style.transition = `opacity 0.2s linear`;
      this.tooltipEl.style.zIndex = 1000;
      this.tooltipEl.classList.add("tooltip__popup");
      this.tooltipEl.classList.add(
        "text-xs",
        "text-white",
        "rounded",
        "px-2",
        "py-1",
        "relative",
        "break-all",
      );
      this.tooltipEl.setAttribute("role", "tooltip");
      this.tooltipEl.innerHTML = `<span class="${this.contentClass}">${html}</span>`;

      document.body.appendChild(this.tooltipEl);
    },
    /** slot을 html로 변경 */
    toHTML(slots) {
      return slots
        .reduce((acc, cur) => {
          if (!cur.tag) {
            return (acc += cur.text);
          }

          return (acc += cur.elm.outerHTML);
        }, "")
        .trim();
    },
    /** 존재하는 툴팁 삭제 */
    cleanupTooltipPopup() {
      const tooltipPopups = Array.from(document.body.childNodes).filter(
        (el) => !!el.classList?.contains("tooltip__popup"),
      );
      tooltipPopups.forEach((el) => document.body.removeChild(el));
    },
  },
  /** ===== mounted ===== */
  mounted() {
    // console.log("> el;  ", this.$refs["atoms__tooltip"]);
    if (this.targetSelf === true) {
      this.targetEl = this.$refs["atoms__tooltip"];
    } else {
      this.targetEl = this.closest
        ? this.$refs["atoms__tooltip"].closest(this.closest)
        : this.$refs["atoms__tooltip"].parentElement;
    }

    if (!this.targetEl) {
      return;
    }
    // console.log("> targetEl;  ", this.targetEl);
    this.targetEl.addEventListener("mouseenter", this.handleMouseEnter);
    this.targetEl.addEventListener("mouseleave", this.handleMouseLeave);
    this.targetEl.addEventListener("mousedown", this.handleMouseDown);
    this.targetEl.addEventListener("mouseup", this.handleMouseUp);

    // # target이 parent일 경우 내부의 tooltip 삭제
    if (this.targetSelf !== false) {
      this.targetEl.querySelectorAll(".atom__tooltip").forEach((el) => {
        el.parentElement.removeChild(el);
      });
    }
    // console.log("> mounted: ", this.targetEl);
  },
  /** ===== destroyed ===== */
  destroyed() {
    // # 모든 툴팁 제거
    this.cleanupTooltipPopup();

    if (!this.targetEl) {
      return;
    }
    this.targetEl.removeEventListener("mouseenter", this.handleMouseEnter);
    this.targetEl.removeEventListener("mouseleave", this.handleMouseLeave);
    this.targetEl.removeEventListener("mousedown", this.handleMouseDown);
    this.targetEl.removeEventListener("mouseup", this.handleMouseUp);
  },
};

let showDelayTimeoutId;
</script>

<style lang="scss">
.tooltip__show {
  opacity: 1 !important;
}
</style>
