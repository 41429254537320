import _ from "lodash";

const DATA_TYPE = {
  CPU: "cpu",
  MEMORY: "memory",
  DISK: "disk",
  NETWORK: "network",
};

export const WorkspaceMixin = {
  data() {
    return {
      DATA_TYPE: DATA_TYPE,
    };
  },
  methods: {
    getLatestData(lines) {
      if (_.isNull(lines) || _.isEmpty(lines)) {
        return 0;
      }

      const maxDate = _.maxBy(Object.keys(lines), (v) => new Date(v).getTime());
      return parseFloat(lines[maxDate] || 0);
    },
    // getCurrentUsage(metricData) {
    //   const cpuData = this.getLatestData(metricData.cpu || {});
    //   const memoryData = this.getLatestData(metricData.memory || {});
    //   const diskReadData = this.getLatestData(metricData.diskRead || {});
    //   const diskWriteData = this.getLatestData(metricData.diskWrite || {});

    //   const diskTotal = diskReadData + diskWriteData;

    //   // Get latest data
    //   const currentUsage = {
    //     cpu: this.$helper.coreToMilicore(cpuData) || 0,
    //     memory: this.$helper.byteToMebibyte(memoryData) || 0,
    //     disk: this.$helper.byteToGibibyte(diskTotal) || 0,
    //   };
    //   return currentUsage;
    // },
    formatMetricData(rdeMetricData) {
      if (_.isNull(rdeMetricData) || _.isEmpty(rdeMetricData) === 0) return {};
      return rdeMetricData.reduce(
        (acc, curr) => this.sumNestedObjects(acc, curr),
        {},
      );
    },
    sumNestedObjects(obj1, obj2) {
      if (_.isNull(obj1) || _.isEmpty(obj1)) return obj2;
      if (_.isNull(obj2) || _.isEmpty(obj2)) return obj1;

      const result = {};
      Object.keys(obj1).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
            // Recursive call if both values are objects
            result[key] = this.sumNestedObjects(obj1[key], obj2[key]);
          } else {
            // Sum the values if they are not objects
            result[key] =
              (parseFloat(obj1[key]) || 0) + (parseFloat(obj2[key]) || 0);
          }
        } else {
          // If the key doesn't exist in obj2, take the value from obj1
          result[key] = obj1[key];
        }
      });

      // Handle keys that are in obj2 but not in obj1
      Object.keys(obj2).forEach((key) => {
        if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
          result[key] = obj2[key];
        }
      });

      return result;
    },
    genDataChart(lines, dataType) {
      if (_.isNull(lines) || _.isEmpty(lines)) {
        return {
          labes: [],
          data: [],
        };
      }
      let dataArray = Object.keys(lines).map((key) => ({
        date: new Date(key), // Convert string to Date object
        label: key,
        value: this.convertDataToChartFormat(lines[key], dataType),
      }));

      // Sort the array by date in ascending order
      dataArray.sort((a, b) => a.date - b.date);

      // After sorting, extract labels and data for the chart
      const result = {
        labels: dataArray.map((item) => item.label),
        data: dataArray.map((item) => item.value),
      };

      return result;
    },
    genDataChartIo(lineIn, lineOut, dataType) {
      if (_.isNull(lineIn) || _.isEmpty(lineIn)) {
        return {
          labes: [],
          data: [[], []],
        };
      }
      let dataArrayIn = Object.keys(lineIn).map((key) => ({
        date: new Date(key), // Convert string to Date object
        label: key,
        value: this.convertDataToChartFormat(lineIn[key], dataType),
      }));

      let dataArrayOut = Object.keys(lineOut).map((key) => ({
        date: new Date(key), // Convert string to Date object
        label: key,
        value: -this.convertDataToChartFormat(lineOut[key], dataType),
      }));

      // Sort the array by date in ascending order
      dataArrayIn.sort((a, b) => a.date - b.date);

      // After sorting, extract labels and data for the chart
      const result = {
        labels: dataArrayIn.map((item) => item.label),
        data: [
          dataArrayIn.map((item) => item.value),
          dataArrayOut.map((item) => item.value),
        ],
      };

      return result;
    },
    convertDataToChartFormat(baseData, dataType) {
      let convertedData;

      switch (dataType) {
        case this.DATA_TYPE.CPU:
          // Assuming baseData is in core and 1 core = 1000 milicore
          convertedData = baseData * 1000;
          break;
        case this.DATA_TYPE.MEMORY:
          // Assuming baseData is in bytes and 1 MiB = 1024 * 1024 bytes
          convertedData = baseData / (1024 * 1024);
          break;
        case this.DATA_TYPE.DISK:
          // Assuming baseData is in bytes and 1 KiB = 1024 bytes
          convertedData = baseData / 1024;
          break;
        case this.DATA_TYPE.NETWORK:
          // Assuming baseData is already in bytes
          convertedData = baseData;
          break;
        default:
          convertedData = baseData;
          console.warn(`Unknown dataType: ${dataType}. Data is not converted.`);
      }

      return convertedData;
    },
  },
};
