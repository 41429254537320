import { RequestMixin } from "./RequestMixin";
import api from "@/service/api";
import { ROLE_TYPE, USER_TYPE } from "@/service/constants";
import { mapState } from "vuex";

/**
 * 프로파일에 대한 권한 관리
 */
export const ProfileAuthMixin = {
  /** ===== mixins ===== */
  mixins: [RequestMixin],
  /** ===== computed ===== */
  computed: {
    ...mapState({
      /** 모든 권한일 경우 true */
      hasAllPermissions: (state) => state.project.profileAuth.auth === "ALL",
      /** 마이크로서비스 기본 권한 */
      microserviceBaseAuth(state) {
        if (!this.profileId) {
          return;
        }
        const profileId = Number(this.profileId);
        const result = state.project.profileAuth.microservices?.find(
          (a) => a.id === 0 && (a.profileId === 0 || a.profileId === profileId),
        );
        return result?.auth;
      },
      isAdmin: (state) => {
        return state.accountInfo.auth === USER_TYPE.ADMINISTRATOR;
      },
      isAuthorized: (state) => {
        if (state.accountInfo.auth === USER_TYPE.ADMINISTRATOR) return true;
        else {
          const hasOwnerRole = state.accountInfo.roleList.some(
            (auth) => auth.role === ROLE_TYPE.OWNER,
          );
          return hasOwnerRole;
        }
      },
      userRoleList: (state) => state.accountInfo.roleList,
      /** 마이크로서비스 개별 권한 정보 맵 */
      microserviceAuthMap(state) {
        return state.project.profileAuth?.microservices?.reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur.auth }),
          {},
        );
      },
    }),
  },
  /** ===== methods ===== */
  methods: {
    /** 프로젝트 멤버 정보를 가지고온다(owner, member) */
    async getProjectAuth({ userId, projectId }) {
      return await this.query(
        () =>
          Promise.all([
            api.getProjectMemberList(projectId),
            api.getMemberProjectAuthList(userId, projectId),
          ]),
        {
          errorText: "접근할 수 없습니다",
        },
      );
    },
    /** 프로젝트 멤버 정보를 가져온 뒤 권한을 스토어에 셋팅 */
    async loadProfileAuth({ userId, userRole, projectId }) {
      const [ownerAuths, memberAuths] = await this.getProjectAuth({
        userId,
        projectId,
      });
      const ownerAuth = ownerAuths.find(
        (a) => a.member_id === userId && a.role === "OWNER",
      );
      const newMemberAuths = memberAuths.sort((a, b) =>
        a.profile_id > b.profile_id ? 1 : -1,
      );

      // # 저장할 정보들
      const profileRole = getProfileRole({
        ownerAuth,
        memberAuths: newMemberAuths,
      });
      const profileScope = getProfileScope(userRole);
      const profileAuth = getProfileAuth({ isOwner: profileRole === "OWNER" });
      const profiles = getProfiles(newMemberAuths);
      const microservices = getMicroservices(newMemberAuths);

      // # 저장
      this.$store.dispatch("project/setProfileAuth", {
        role: profileRole,
        scope: profileScope,
        auth: profileAuth,
        profiles,
        microservices,
      });
    },
    /** 마이크로 서비스 아이디를 기준으로 all 권한을 가지고 있을 경우 true 반환 */
    hasMicroserviceItemAllPermissions(microserviceId) {
      return (
        this.microserviceAuthMap[microserviceId] === "ALL" ||
        this.microserviceBaseAuth === "ALL" ||
        this.hasAllPermissions
      );
    },
    /** 프로파일 인증 정보 초기화 */
    profileAuthInitialize() {
      this.$store.dispatch("project/profileAuthInitialize");
    },
    /**
     * 마이크로 서비스에 대해 실행 권한이 있을 경우 true를 리턴
     * @typedef {object} MicroserviceExecutableParam
     * @property {number} microserviceId 마이크로 서비스 아이디
     * @property {array} authMicroservices 권한 정보를 가지고 있는 마이크로서비스 배열
     * @param {MicroserviceExecutableParam} _0
     * @return {boolean}
     */
    microserviceExecutable({ microserviceId, authMicroservices }) {
      const newMicroserviceId = Number(microserviceId);

      if (isNaN(newMicroserviceId) || !Array.isArray(authMicroservices)) {
        throw new Error("invalid microserviceId: " + microserviceId);
      }

      return !!authMicroservices
        ?.filter((a) => a.id === 0 || a.id === newMicroserviceId)
        .find((a) => a.auth === "ALL" || a.auth === "READ_EXECUTE");
    },
    /** 토폴로지 노드의 권한 확인
     * @typedef {object} GetTopologyNodePermissionParam
     * @property {object} node - 권한 정보를 확인할 노드
     * @property {object} nodes - 전체 노드 객체
     * @param {GetTopologyNodePermissionParam} _0
     * @return {"ALL"|"READ"|"READ_EXCUTE"|null}
     */
    getTopologyNodePermission({ node, nodes }) {
      let newNode = node;

      // # 관리자 권한
      if (this.hasAllPermissions === true) {
        return "ALL";
      }

      // # 프로파일 전체 권한
      if (this.microserviceAuthMap["0"]) {
        return this.microserviceAuthMap["0"];
      }

      // # sub node
      if (
        newNode.relationType === "subNode" &&
        newNode.parentConnectableGroups.includes("application")
      ) {
        newNode = Object.values(nodes)
          .filter((a) => a.group === "application")
          .find((a) => a.referenceId === newNode.parentReferenceId);
      }

      // # application Node
      if (newNode?.group === "application") {
        return this.microserviceAuthMap[newNode.referenceId];
      }

      return null;
    },
    verifyOwner(projectId) {
      if (this.isAdmin) return true;
      else {
        return this.userRoleList.some(
          (item) =>
            item.project.id == projectId && item.role == ROLE_TYPE.OWNER,
        );
      }
    },
  },
};

/** 프로파일 역할을 반환 */
const getProfileRole = ({ ownerAuth, memberAuths }) => {
  if (ownerAuth) {
    return "OWNER";
  }
  if (memberAuths?.length > 0) {
    return "MEMBER";
  }
  return null;
};

/** 프로파일 범위를 반환 */
const getProfileScope = (userRole) => {
  if (userRole === "ADMINISTRATOR") {
    return "ALL";
  }
  if (userRole === "DEVELOPER") {
    return "EACH";
  }
  throw new Error("invalid params getProjectScope(userRole) : " + userRole);
};

/** 프로파일 권한을 반환 */
const getProfileAuth = ({ isOwner }) => {
  if (isOwner === true) {
    return "ALL";
  }
  return "READ";
};

/** 프로파일 id, auth 필터링 */
const getProfiles = (memberAuths) => {
  // # 전체 프로파일 권한
  if (memberAuths.find((a) => a.profile_id === 0)) {
    return [{ id: 0, auth: "READ" }];
  }
  // # 개별 권한
  const result = memberAuths.reduce(
    (acc, cur) =>
      acc.find((a) => a.id === cur.profile_id)
        ? acc
        : [
            ...acc,
            {
              id: cur.profile_id,
              auth: "READ",
            },
          ],
    [],
  );
  return result;
};

/** 마이크로서비스 id, auth 필터링 */
const getMicroservices = (memberAuths) => {
  const result = memberAuths.reduce((acc, cur) => {
    const sameProfiles = acc.filter((a) => a.profileId === cur.profile_id);
    const allScopeMicroservice = sameProfiles.find((a) => a.id === 0);

    // # 전체 마이크로서비스의 권한보다 낮거나 같을 경우 무시
    if (allScopeMicroservice && allScopeMicroservice.mode >= cur.mode) {
      return acc;
    }

    const sameMicroservice = sameProfiles.find(
      (a) =>
        a.id !== 0 &&
        a.profileId === cur.profile_id &&
        a.id === cur.resource_id,
    );

    // # 같은 마이크로서비스가 있을 경우 더 큰 권한을 적용
    if (sameMicroservice) {
      const biggerMode = sameMicroservice
        ? Math.max(sameMicroservice.mode, cur.mode)
        : cur.mode;

      return acc.map((a) =>
        a.id === cur.resource_id
          ? {
              ...a,
              mode: biggerMode,
              auth: chmod.router(biggerMode),
            }
          : a,
      );
    }

    // # 아무것도 일치하는게 없을 경우 추가
    return [
      ...acc,
      {
        profileId: cur.profile_id,
        id: cur.resource_id,
        mode: cur.mode,
        auth: chmod.router(cur.mode),
      },
    ];
  }, []);
  return result;
};

/** 리눅스 chmod 를 따르는 권한 정보 */
const chmod = {
  ["4"]: () => "READ",
  ["5"]: () => "READ_EXECUTE",
  ["7"]: () => "ALL",
  router(number, role) {
    if (!this[number]) {
      throw new Error("invalid chmod.router() : " + number);
    }
    return this[number](role);
  },
};
