import httpClient from "../httpClient";

export default {
  getPVCList(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}/pvc`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  createPvc(projectId, profileId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/profile/${profileId}/pvc`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  updatePvc(projectId, profileId, pvcId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/${projectId}/profile/${profileId}/pvc/${pvcId}`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  deletePvc(projectId, profileId, pvcId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/project/${projectId}/profile/${profileId}/pvc/${pvcId}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getApplicationsConnectedToPVC(projectId, profileId, pvcId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}/pvc/${pvcId}/using`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};
