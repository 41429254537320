
<div class="w-full">
  <!-- Table with search box and export button -->
  <v-row>
    <v-col cols="12" md="6" class="pb-0">
      <!-- Search box on the top left -->
      <div class="flex flex-row">
        <span class="pt-2 table-title">Overview</span>
        <FInput
          v-model="searchQuery"
          :append-icon="'mdi-magnify'"
          outlined
          dense
          placeholder="Search"
          label="Search"
          size="lg"
          class="search pl-2"
          hide-details
        />
      </div>
    </v-col>
    <v-col cols="12" md="6" class="text-right pb-0">
      <FButton
        size="md"
        fill
        depressed
        color="white"
        class="text-transform-none buttons"
        @click="exportTable"
      >
        Export
      </FButton>
      <FButton
        size="md"
        fill
        depressed
        color="white"
        class="text-transform-none ml-3 buttons"
        @click="handlePricePolicy"
      >
        Price Policy
      </FButton>
    </v-col>
  </v-row>
  <v-row class="mt-0">
    <v-col class="columns">
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        :items-per-page="5"
        class="elevation-1 overview-table"
        :search="searchQuery"
        :hide-default-header="true"
      >
        <template v-slot:header="{ props }">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="header-cell"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.rdeName }}</td>
            <td>{{ item.workspaceName }}</td>
            <td>{{ item.instanceType }}</td>
            <td>{{ item.cpu }}</td>
            <td>{{ item.memory }}</td>
            <td>{{ item.disk }}</td>
            <td>{{ item.diskType }}</td>
            <td>{{ formatDate(item.createdDate) }}</td>
            <td>{{ item.used }}</td>
            <td>{{ item.billing }}</td>
            <td>{{ item.creator }}</td>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="error" icon="mdi-alert">
            No data available
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</div>
