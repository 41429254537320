<template>
  <div>
    <RouterView v-if="allowed" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    /** 접근 가능 role */
    allowedRoles: {
      type: Array,
      default: undefined,
      required: true,
    },
    /** 실패 시 경로 */
    redirectPath: {
      type: String,
      default: "/",
    },
  },
  computed: {
    ...mapState({
      role: (state) => state.accountInfo?.auth,
    }),
    /** 허가일 경우 true */
    allowed() {
      if (this.allowedRoles.some((a) => a === this.role) === true) {
        return true;
      }
      return false;
    },
  },
  watch: {
    role() {
      this.guard();
    },
  },
  methods: {
    /** 가드 */
    guard() {
      if (this.allowed === true) {
        return;
      }
      this.$router.replace(this.redirectPath);
    },
  },
  mounted() {
    this.guard();
  },
};
</script>

<style lang="scss" scoped></style>
