import store from "../store";

export default {
  ShowUIProgress: () => {
    store.commit("SET_GLOBAL_UI_PROGRESS", true);
  },
  HideUIProgress: () => {
    store.commit("SET_GLOBAL_UI_PROGRESS", false);
  },
  ActivateAppBarLoading: () => {
    store.commit("SET_APP_BAR_LOADING", true);
  },
  DeactivateAppBarLoading: () => {
    store.commit("SET_APP_BAR_LOADING", false);
  },
  ShowErrorToast: (errorMessage, timeout = 5000) => {
    const param = {
      message: errorMessage,
      timeout: timeout,
      color: "error",
      icon: "mdi-alert",
    };
    store.commit("SHOW_TOAST", param);
  },
  ShowWarningToast: (message, timeout = 5000) => {
    const param = {
      message: message,
      timeout: timeout,
      color: "warning",
      icon: "mdi-alert",
    };
    store.commit("SHOW_TOAST", param);
  },
  ShowInfoToast: (message, timeout) => {
    const param = {
      message: message,
      timeout: timeout,
      color: "info",
      icon: "mdi-information",
    };
    store.commit("SHOW_TOAST", param);
  },
  ShowSuccessToast: (message, timeout) => {
    const param = {
      message: message,
      timeout: timeout,
      color: "success",
      icon: "mdi-check-circle",
    };
    store.commit("SHOW_TOAST", param);
  },
  ShowModalDialog: (
    title,
    message,
    confirmButtonText,
    cancelButtonText,
    didCloseCallback,
    isModal,
    icon,
  ) => {
    const param = {
      title: title,
      message: message,
      didCloseDialog: didCloseCallback,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      isModal: !!isModal,
      icon: translateIcon(icon),
    };
    store.commit("SHOW_MODAL_DIALOG", param);
  },
  /**
   * 확인 다이얼로그
   * @param {ConfirmParams} param0
   */
  confirm({
    title,
    message,
    icon = "question",
    confirmText = "확인",
    cancelText = "취소",
    onConfirm,
    onCancel,
  }) {
    const handleButtonClick = async (sw) => {
      // # confirm
      if (sw) {
        if (onConfirm.constructor.name === "AsyncFunction") {
          await onConfirm();
        } else if (typeof onConfirm === "function") {
          onConfirm();
        }
        return;
      }

      // # cancel
      if (typeof onCancel === "function") {
        onCancel();
      }
    };

    this.ShowModalDialog(
      title,
      message,
      confirmText,
      cancelText,
      handleButtonClick,
      false,
      icon,
    );
  },
  CloseModalDialog() {
    store.commit("DISMISS_MODAL_DIALOG");
  },
};

function translateIcon(name) {
  if (name === "alert")
    return { color: "orange lighten-2", value: "mdi-alert-circle-outline" };
  if (name === "ok")
    return {
      color: "green lighten-1",
      value: "mdi-checkbox-marked-circle-outline",
    };
  if (name === "error")
    return { color: "error", value: "mdi-close-circle-outline" };
  if (name === "generate")
    return { color: "indigo darken-1", value: "mdi-graphql" };
  if (name === "pencil")
    return { color: "indigo darken-1", value: "mdi-pencil" };
  if (name === "question")
    return { color: "error", value: "mdi-help-circle-outline" };
  return null;
}

/** @typedef {"alert"|"ok"|"error"|"generate"|"pencil"|"question"} DialogIcon - 다이얼로그에 들어갈 아이콘 */

/**
 * @typedef {object} ConfirmParams
 * @property {string} [title] - 타이틀
 * @property {string} message - 메시지
 * @property {DialogIcon} [icon] - 아이콘. 기본값: "question"
 * @property {string} [confirmText] - 확인 버튼 텍스트. 기본값: "확인"
 * @property {string} [cancelText] - 취소 버튼 텍스트. 기본값: "취소"
 * @property {()=>void} [onConfirm] - 확인 버튼 클릭 시 콜백
 * @property {()=>void} [onCancel] - 취소 버튼 클릭 시 콜백
 */
