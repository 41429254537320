
<div class="common__page-template">
  <!-- header -->
  <PageHeader fixed :breadcrumbs="breadcrumbs" :useBackButton="useBackButton">
    <template #left>
      <slot name="left"></slot>
    </template>
    <template #left-next>
      <slot name="left-next"></slot>
    </template>

    <template #center>
      <slot name="center"></slot>
    </template>
    <template #right>
      <slot name="right"></slot>
    </template>
  </PageHeader>

  <!-- body -->
  <div :class="padding" role="main">
    <Card v-if="useCard" class="page-template__content-card relative">
      <slot></slot>
    </Card>
    <slot v-else></slot>
  </div>
</div>
