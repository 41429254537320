<template>
  <v-app>
    <v-snackbar
      v-model="$store.state.ui.toast.show"
      class="app-g-toast"
      :timeout="$store.state.ui.toast.timeout"
      top
      right
      :color="$store.state.ui.toast.color"
    >
      <v-icon>{{ $store.state.ui.toast.icon }}</v-icon>
      {{ $store.state.ui.toast.message }}
      <v-btn @click="$store.state.ui.toast.show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="$store.state.ui.dialog.show"
      :persistent="$store.state.ui.dialog.isModal"
      scrollable
      max-width="400px"
      content-class="app-g-dialog-alert"
      @click:outside="didClickDialogOutside"
    >
      <v-card>
        <template v-if="$store.state.ui.dialog.showTitle">
          <v-card-title>
            <span class="headline">{{ $store.state.ui.dialog.title }}</span>
            <v-spacer />
            <v-btn icon color="grey" @click="didCloseModalDialog(false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
        </template>

        <!-- <v-card-text :style="{'padding-top': $store.state.ui.dialog.showTitle?'16px':'24px'}">
          <p>{{ $store.state.ui.dialog.message }}</p>
        </v-card-text> -->

        <v-card-text>
          <v-container class="text-center">
            <v-icon
              v-if="$store.state.ui.dialog.icon != null"
              class="icon"
              :color="$store.state.ui.dialog.icon.color"
            >
              {{ $store.state.ui.dialog.icon.value }}
            </v-icon>
            <div v-if="$store.state.ui.dialog.message" class="desc break-keep">
              {{ $store.state.ui.dialog.message }}
            </div>
          </v-container>
        </v-card-text>

        <v-divider />
        <v-card-actions
          v-if="
            $store.state.ui.dialog.showConfirmButton ||
            $store.state.ui.dialog.showCancelButton
          "
        >
          <v-spacer />
          <Button
            v-if="$store.state.ui.dialog.showCancelButton"
            @click="didCloseModalDialog(false)"
          >
            {{ $store.state.ui.dialog.cancelButtonText || "아니오" }}
          </Button>
          <Button
            v-if="$store.state.ui.dialog.showConfirmButton"
            color="primary"
            fill
            @click="didCloseModalDialog(true)"
          >
            {{ $store.state.ui.dialog.confirmButtonText || "예" }}
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="app-area">
      <!-- <SplashView class="splash-view" :is-loading="!loaded" /> -->
      <transition name="fade" mode="out-in">
        <router-view style="z-index: 1" />
      </transition>
    </div>
    <v-overlay :value="$store.state.ui.progress.circular" z-index="1000">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </v-app>
</template>

<script>
import Button from "./components/atoms/Button/Button.vue";
import { AuthMixin } from "./mixins/AuthMixin";
import { SettingsMixin } from "./mixins/SettingsMixin";
import "chartjs-adapter-moment";
// import and register chartjs
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  TimeScale,
  Filler,
} from "chart.js";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import ChartjsPluginAnnotation from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartJSPluginDatalabels,
  ChartjsPluginAnnotation,
  ArcElement,
  TimeScale,
  Filler,
);

export default {
  name: "App",
  components: { Button },
  mixins: [AuthMixin, SettingsMixin],
  data: () => ({}),
  methods: {
    didCloseModalDialog(val) {
      if (this.$store.state.ui.dialog.didCloseDialog) {
        this.$store.state.ui.dialog.didCloseDialog(val);
      }
      this.$store.commit("DISMISS_MODAL_DIALOG");
    },
    didClickDialogOutside() {
      if (!this.$store.state.ui.dialog.isModal) {
        this.didCloseModalDialog(false);
      }
    },
  },
  async mounted() {
    // # 개인정보 가져오기
    await this.signIn();

    // # 로컬에 저장한 셋팅 정보 로드
    this.loadSettings();
  },
};
</script>
<style scoped lang="scss">
.splash-view {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.app-area {
  width: 100%;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<style>
pre code.hljs {
  width: 100%;
  font-size: 100%;
}

pre code.hljs::before {
  content: "";
}

/* 임시조치 - negative margin이 들어가면서 col없이 쓰인 v-row들 높이가 다 뭉게짐. */
div.row {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
