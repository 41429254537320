<template>
  <SmartPopupCard :title="title">
    <template v-slot:content>
      <LineChart :data="chartData.data" :options="chartData.options" />
    </template>
  </SmartPopupCard>
</template>

<script>
import LineChart from "@/components/chart/LineChart/LineChart.vue";
import SmartPopupCard from "@/components/atoms/SmartPopupCard/SmartPopupCard.vue";
import moment from "moment";
import { get } from "lodash";

const METRIC_RDE_CONFIG = {
  cpu: {
    title: "CPU Usage",
    color: "#2EB85C",
    yUnit: "milicores",
  },
  memory: {
    title: "Memory Usage",
    color: "#3399FF",
    yUnit: "GiB",
  },
  disk: {
    title: "Disk Usage",
    color: "#FD7E14",
    yUnit: "MB",
  },
  network: {
    title: "Network I/O",
    color: "#6F42C1",
    yUnit: "Mb",
  },
};
export default {
  props: {
    type: { type: String, required: true },
    data: {
      type: Object,
    },
  },
  components: { LineChart, SmartPopupCard },
  data: () => ({
    chartData: {
      data: {},
      options: {},
    },
  }),
  computed: {
    title() {
      return METRIC_RDE_CONFIG[this.type].title;
    },
  },
  watch: {
    data: {
      handler() {
        this.chartData = this.getChartData();
      },
      immediate: true,
    },
  },
  methods: {
    getChartData() {
      const type = this.type;

      const options = {
        scales: {
          x: {
            type: "time",
            time: {
              unit: "hour",
              displayFormats: {
                hour: "HH:mm",
              },
            },
            skipNull: true,
          },
          y: {
            display: true,
            title: {
              display: false,
              text: "milicores",
            },
          },
        },
        plugins: {
          legend: false,
          tooltip: {
            callbacks: {
              title: () => {
                return "";
              },
              label: function (context) {
                let label = context.dataset.label || "";

                return `${label} : ${
                  context?.raw?.y || context?.formattedValue
                } ${METRIC_RDE_CONFIG[type].yUnit}`;
              },
              footer: (tooltipItems) => {
                return `Time: ${moment(get(tooltipItems, "[0].raw.x")).format(
                  "HH:mm DD/MM/YYYY",
                )}`;
              },
            },
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        },
      };

      const data = {
        datasets: [
          {
            label: METRIC_RDE_CONFIG[this.type].title,
            data: Object.keys(this.data || {})?.map((datetime) => ({
              x: new Date(moment(datetime, "DD/MM/YYYY HH:mm:ss ").toDate()),
              y: Number(this.data[datetime]),
              time: datetime,
            })),
            borderColor: METRIC_RDE_CONFIG[this.type]?.color,
            backgroundColor: `${METRIC_RDE_CONFIG[this.type]?.color}33`,
            borderWidth: 2,
            fill: true,
            radius: 0,
          },
        ],
      };

      return {
        data,
        options,
      };
    },
  },
};
</script>

<style lang="scss"></style>
