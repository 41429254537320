import httpClient from "../httpClient";

export default {
  getAvailableProjectMembers() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/member`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getProjectMemberList(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/member`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getProjectMember(projectId, memberId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/member?memberId=${memberId}`)
        .then((resp) => resolve(resp.data.result))
        .catch((err) => reject(err.response));
    });
  },
  /**
   * 사용자를 프로젝트 멤버로 추가
   * @param {Number} projectId
   * @param {Number} memberId
   * @param {String} role "OWNER" or "MEMBER"
   */
  addMemberToProject(projectId, memberId, role) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/project/${projectId}/member?memberId=${memberId}&role=${role}`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  updateProjectMember(projectId, memberId, role) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/${projectId}/member/${memberId}?role=${role}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  removeMemberFromProject(projectId, memberId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/project/${projectId}/member/${memberId}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};
