<template>
  <v-dialog
    class="compositions__dialog"
    :value="value"
    :maxWidth="maxWidth"
    v-bind="$attrs"
    v-on="$listeners"
    @input="handleInput"
  >
    <!-- header -->
    <div
      class="flex justify-between items-center h-12 border-b border-gray-300 px-4"
    >
      <h2 class="font-semibold text-gray-600">
        {{ title }}
      </h2>
      <div>
        <IconButton
          iconName="mdi-close"
          shape="circle"
          tooltip="닫기"
          :outlined="false"
          @click="$emit('input', false)"
        />
      </div>
    </div>

    <!-- content -->
    <div :class="[usePadding && 'p-4']">
      <slot></slot>
    </div>
  </v-dialog>
</template>

<script>
import IconButton from "@/components/atoms/IconButton/IconButton.vue";

export default {
  props: {
    /** true일 경우 활성화 */
    value: {
      type: Boolean,
    },
    /** false일 경우 기본 패딩 사용안함  */
    usePadding: {
      type: Boolean,
      default: true,
    },
    /** 최대 넓이 */
    maxWidth: {
      type: String,
      default: "600px",
    },
    /** title */
    title: {
      type: String,
    },
  },
  components: { IconButton },
  emits: ["input"],
  /** ===== methods ===== */
  methods: {
    handleInput(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style lang="scss" scoped></style>
