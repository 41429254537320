
<div class="p-2 grid grid-cols-12 gap-4 mt-2">
  <Indicator
    title="CPU Usage"
    :value="cpuMetrics.usageData"
    :usageUnit="cpuMetrics.usageUnit"
    :limit="cpuMetrics.limitData"
    :unit="cpuMetrics.limitUnit"
    :percent="cpuMetrics.percent"
    :showPercent="true"
    icon="cpu"
    extraClass="big-size"
  />
  <Indicator
    title="Memory Usage"
    :value="memoryMetrics.usageData"
    :usageUnit="memoryMetrics.usageUnit"
    :limit="memoryMetrics.limitData"
    :unit="memoryMetrics.limitUnit"
    :percent="memoryMetrics.percent"
    :showPercent="true"
    icon="memory"
    extraClass="big-size"
  />
  <Indicator
    title="Disk Usage"
    :value="diskMetrics.usageData"
    :usageUnit="diskMetrics.usageUnit"
    :limit="diskMetrics.limitData"
    :unit="diskMetrics.limitUnit"
    :percent="diskMetrics.percent"
    :showPercent="true"
    icon="disk"
    extraClass="big-size"
  />
</div>
