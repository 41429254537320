
<div>
  <div
    class="grid grid-cols-[100px,1fr,70px,120px,120px] gap-x-2 px-4 text-sm font-semibold text-gray-600"
  >
    <div>REASON</div>
    <div>MESSAGE</div>
    <div>COUNT</div>
    <div>FIRST OCCURRED</div>
    <div>LAST OCCURRED</div>
  </div>

  <div
    ref="eventLogContainer"
    class="grid grid-flow-row gap-y-4 mt-4 overflow-auto"
    style="max-height: 75vh"
  >
    <div
      v-for="(event, i) of eventLog"
      :key="`rde-event-${i}`"
      :class="'grid grid-cols-[100px,1fr,70px,120px,120px] gap-x-2 min-h-[70px] border border-gray-200 rounded-lg px-4 py-6 text-sm text-gray-600 shadow-md'"
    >
      <div class="break-words" :title="event.reason">
        {{ event.reason }}
      </div>
      <div class="text-xs">{{ event.message }}</div>
      <div>{{ event.count }}</div>
      <div>{{ event.firstTimestamp }}</div>
      <div>{{ event.lastTimestamp }}</div>
    </div>
    <div
      v-if="eventLog.length === 0"
      class="flex justify-center items-center h-20 border rounded-lg shadow-md"
    >
      No data available
    </div>
  </div>
</div>
