<template>
  <v-data-table
    :headers="HEADERS"
    :items="data || []"
    :hide-default-footer="true"
    :dense="true"
    class="pod_rde_state_table"
    disable-pagination
  />
</template>

<script>
const POD_RDE_STATE_HEADER = [
  {
    text: "Namespace",
    sortable: true,
    value: "namespace",
    class: "header-table",
  },
  {
    text: "Pod",
    sortable: true,
    value: "pod",
    class: "header-table",
  },
  {
    text: "Container",
    sortable: true,
    value: "container",
    class: "header-table",
  },
  {
    text: "Requests",
    sortable: true,
    value: "requests",
    class: "header-table",
  },
  {
    text: "Limits",
    sortable: true,
    value: "limit",
    class: "header-table",
  },
  {
    text: "Usage",
    sortable: true,
    value: "usage",
    class: "header-table",
  },
  {
    text: "Usage/Request",
    sortable: true,
    value: "usageRequest",
    class: "header-table",
  },
  {
    text: "Usage/Limit",
    sortable: true,
    value: "usageLimit",
    class: "header-table",
  },
];

export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: function () {
    return {
      HEADERS: POD_RDE_STATE_HEADER,
    };
  },
};
</script>

<style lang="scss">
.pod_rde_state_table {
  overflow: auto;
  min-width: 100%;
  padding: 8px;
  margin-top: 8px;

  .header-table {
    background-color: #f0f1f7;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    height: 33px;
    padding-left: 15px;
  }
  tr,
  td {
    font-size: 12px !important;
    font-weight: 400;
    color: #000000de;
  }
}
</style>
