
<v-tooltip top>
  <template #activator="{ on, attrs }">
    <sup
      class="compositions__tooltip-icon rounded-full bg-gray-400 !inline-flex justify-center items-center"
      :class="[`icon-size__${iconSize}`]"
    >
      <v-icon class="text-[0.5em]" color="white" v-bind="attrs" v-on="on">
        {{ iconName }}
      </v-icon>
    </sup>
  </template>
  <div
    class="break-keep"
    :class="[width === undefined && 'w-fit max-w-sm']"
    :style="{ width: typeof width === 'number' ? `${width}px` : width }"
  >
    <slot></slot>
  </div>
</v-tooltip>
