export default [
  {
    path: "/account/profile",
    component: () => import("../pages/account/ProfilePage.vue"),
  },
  {
    path: "/account/password",
    component: () =>
      import("../pages/main/modernframework/User/UserChangePassword.vue"),
  },
];
