
<div class="file-environment-panel">
  <div
    v-for="(item, index) in mountedConfigList"
    :key="`file-env-${index}`"
    class="grid grid-cols-4 gap-x-2 pb-3"
  >
    <v-autocomplete
      v-model="item.name"
      :items="genOptions(item.name)"
      label="* Resource Name"
      small-chips
      placeholder="Select Resource Name"
      class="app-g-autocomplete-v2 v-auto-help-message"
      outlined
      dense
      :rules="[rules.required]"
      :messages="
        item.name
          ? `Type: ${item.mountedType}, Config type: ${item.configType}`
          : ''
      "
      @change="(value) => onResourceNames(item, value)"
    />
    <v-icon class="h-6 w-5 pt-5" @click="handleRemoveClick(index)">
      mdi-close
    </v-icon>
  </div>
  <Button class="add-button" size="md" @click="handleAddClick">
    <v-icon>mdi-plus</v-icon>
    ADD File/Environment value mount
  </Button>
</div>
