<template>
  <div class="app-detail-rde-popup">
    <div class="header">
      <p>
        <v-icon @click="turnOffFunc"> mdi-arrow-right </v-icon>
        {{ selectedApp?.displayName || selectedApp?.name || "" }}
      </p>
      <div class="flex flex-row gap-2">
        <Button color="primary" :size="'md'" text @click="refreshTab">
          Refresh
        </Button>
        <IconButton color="primary" :size="'md'" text @click="setExpand">
          <v-icon>mdi-arrow-expand-horizontal</v-icon>
        </IconButton>
      </div>
    </div>
    <v-tabs v-model="tab">
      <v-tabs-slider color="#1976d2"></v-tabs-slider>
      <v-tab>SUMMARY</v-tab>
      <v-tab>Event</v-tab>
      <v-tab>Log Information</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div v-if="selectedApp" class="log-dashboard2-section">
          <SummaryTab
            v-if="detail"
            :kind="detail.kind"
            :name="detail.name"
            :displayName="selectedApp?.displayName"
            :namespace="detail.namespace"
            :createdAt="detail.createdAt"
            :message="detail.message"
            :health="detail.health"
            :manifest="detail.manifest"
            class="log-dashboard2-section"
          />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div v-if="selectedApp" class="log-dashboard2-section">
          <EventTab :rdeId="selectedApp?.id" :reload="reloadEvent" />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div v-if="selectedApp" class="log-dashboard2-section">
          <LogsTab
            :rdeId="selectedApp.id"
            :serviceTypes="selectedApp.serviceTypes"
            :reload="reloadLog"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SummaryTab from "@/components/newWorkspace/components/RDERightDetail/SummaryTab.vue";
import EventTab from "@/components/newWorkspace/components/RDERightDetail/EventTab.vue";
import LogsTab from "@/components/newWorkspace/components/RDERightDetail/LogTab.vue";
import { getSummaryRDEState } from "@/service/apis/workspaceApis";
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  props: {
    turnOffFunc: {
      type: Function,
      default() {
        return undefined;
      },
    },
    selectedApp: { type: Object, default: () => null },
    setExpand: { type: Function, default() {} },
    presetTabId: { type: Number, default: 0 },
  },
  components: {
    EventTab,
    LogsTab,
    SummaryTab,
  },
  data: () => ({ detail: null, tab: null, reloadEvent: 0, reloadLog: 0 }),
  watch: {
    selectedApp: {
      handler(newValue) {
        if (newValue?.id) {
          this.hanldeSummaryRDEState(newValue?.id);
        }
      },
      immediate: true,
    },
  },
  methods: {
    hanldeSummaryRDEState(rdeID) {
      CommonUIControl.ShowUIProgress();
      getSummaryRDEState(rdeID)
        .then((res) => {
          const summaryData = res?.data?.data;
          if (summaryData) {
            this.detail = {
              kind: summaryData?.kind,
              name: this.selectedApp?.name,
              namespace: this.selectedApp?.namespace,
              createdAt: this.selectedApp?.createdAt,
              manifest: summaryData,
            };
          }
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    },
    refreshTab() {
      if (this.tab === 0) {
        this.hanldeSummaryRDEState(this.selectedApp?.id);
      } else if (this.tab === 1) {
        this.reloadEvent += 1;
      } else if (this.tab === 2) {
        this.reloadLog += 1;
      }
    },
  },
  mounted() {
    this.tab = this.presetTabId;
  },
};
</script>

<style lang="scss">
.app-detail-rde-popup {
  padding: 0 !important;
  height: 100%;
  background-color: #fff;
  .v-tabs-bar {
    padding: 0px;
    .v-slide-group__content {
      padding: 15px 0px;
    }
    .v-slide-group__prev {
      display: none;
    }
    .v-slide-group__next {
      display: none;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: 46px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: #fff;
    border-bottom: 1px solid #aeaeae;
    p {
      font-size: 16px;
      font-weight: 600;
      color: #4b4b4b;
      margin: 0;
    }
  }

  .v-tabs-bar__content {
    font-size: 14px;
    font-weight: 500;
    color: #1976d2;
    padding: 5px;
    justify-content: center;
  }

  .v-tabs + .v-window {
    box-shadow: unset;
  }
  .v-tabs > .v-item-group {
    padding: 0px !important;
  }

  .custom-tab {
    width: 50%;
  }
  .v-window__container {
    padding: 0px !important;
  }

  .log-dashboard2-section {
    padding: 10px 5px 0px 10px;
    overflow: auto;
  }
}
</style>
