/** 토폴로지에서 연결가능한 노드간의 관계를 정의 */
export const connectableRules = {
  ["springboot"]: () => ({
    parentConnectableGroups: [],
    childConnectableGroups: ["database", "messageChannel", "inMemory", "pvc"],
    dummyChildConnectableGroups: ["application"],
    excludeNodes: [],
  }),
  ["react"]: () => ({
    parentConnectableGroups: [],
    childConnectableGroups: ["pvc"],
    dummyChildConnectableGroups: ["application"],
    excludeNodes: [],
  }),
  ["vuejs"]: () => ({
    parentConnectableGroups: [],
    childConnectableGroups: ["pvc"],
    dummyChildConnectableGroups: ["application"],
    excludeNodes: [],
  }),
  ["nexacro"]: () => ({
    parentConnectableGroups: [],
    childConnectableGroups: ["pvc"],
    dummyChildConnectableGroups: ["application"],
    excludeNodes: [],
  }),
  ["docker"]: () => ({
    parentConnectableGroups: [],
    childConnectableGroups: [],
    dummyChildConnectableGroups: ["application"],
    excludeNodes: [],
  }),
  ["mariadb"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    dummyChildConnectableGroups: [],
    excludeNodes: ["react", "vuejs", "docker"],
  }),
  ["oracle"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    excludeNodes: ["react", "vuejs", "docker"],
  }),
  ["postgresql"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    dummyChildConnectableGroups: ["application"],
    excludeNodes: ["react", "vuejs", "docker"],
  }),
  ["mongodb"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    excludeNodes: ["react", "vuejs", "docker"],
  }),
  ["redis"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    excludeNodes: ["react", "vuejs", "docker"],
  }),
  ["rabbitmq"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    excludeNodes: ["react", "vuejs", "docker"],
  }),
  ["kafka"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    excludeNodes: ["react", "vuejs", "docker"],
  }),
  ["ingress"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    excludeNodes: [],
  }),
  ["pvc"]: () => ({
    parentConnectableGroups: ["application"],
    childConnectableGroups: [],
    excludeNodes: ["docker"],
  }),
  router(type) {
    if (!this[type]) {
      throw new Error("invalid type: " + type);
    }
    return this[type](type);
  },
};
