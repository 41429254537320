
<div class="app-detail-rde-popup">
  <div class="header">
    <p>
      <v-icon @click="turnOffFunc"> mdi-arrow-right </v-icon>
      {{ selectedApp?.displayName || selectedApp?.name || "" }}
    </p>
    <div class="flex flex-row gap-2">
      <Button color="primary" :size="'md'" text @click="refreshTab">
        Refresh
      </Button>
      <IconButton color="primary" :size="'md'" text @click="setExpand">
        <v-icon>mdi-arrow-expand-horizontal</v-icon>
      </IconButton>
    </div>
  </div>
  <v-tabs v-model="tab">
    <v-tabs-slider color="#1976d2"></v-tabs-slider>
    <v-tab>SUMMARY</v-tab>
    <v-tab>Event</v-tab>
    <v-tab>Log Information</v-tab>
  </v-tabs>
  <v-tabs-items v-model="tab">
    <v-tab-item>
      <div v-if="selectedApp" class="log-dashboard2-section">
        <SummaryTab
          v-if="detail"
          :kind="detail.kind"
          :name="detail.name"
          :displayName="selectedApp?.displayName"
          :namespace="detail.namespace"
          :createdAt="detail.createdAt"
          :message="detail.message"
          :health="detail.health"
          :manifest="detail.manifest"
          class="log-dashboard2-section"
        />
      </div>
    </v-tab-item>
    <v-tab-item>
      <div v-if="selectedApp" class="log-dashboard2-section">
        <EventTab :rdeId="selectedApp?.id" :reload="reloadEvent" />
      </div>
    </v-tab-item>
    <v-tab-item>
      <div v-if="selectedApp" class="log-dashboard2-section">
        <LogsTab
          :rdeId="selectedApp.id"
          :serviceTypes="selectedApp.serviceTypes"
          :reload="reloadLog"
        />
      </div>
    </v-tab-item>
  </v-tabs-items>
</div>
