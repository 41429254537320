// import Colors from "./colors.scss";
// import Size from "./size.scss";
// import Fonts from "./fonts.scss";
import Vue from "vue";

const colorVariable = {
  // primary: Colors.primary,
  // secondary: Colors.secondary,
  // accent: Colors.accent,
  // error: Colors.error,
  // info: Colors.info,
  // success: Colors.success,
  // warning: Colors.warning,
  // anchor: Colors.anchor,
  // dark1: Colors.dark1,
  // dark2: Colors.dark2,
  // blue1: Colors.blue1,
  // blue2: Colors.blue2,
  // gray0: Colors.gray0,
  // gray1: Colors.gray1,
  // gray2: Colors.gray2,
  // gray3: Colors.gray3,
  // gray4: Colors.gray4,
  // gray5: Colors.gray5,
  // gray6: Colors.gray6,
  // gray7: Colors.gray7,
  // gray8: Colors.gray8,
  // gray9: Colors.gray9,
  // gray10: Colors.gray10,
  // gray11: Colors.gray11,
  // gray12: Colors.gray12,
  // gray13: Colors.gray13,
  // text: Colors.text
};

const sizeVariable = {
  // contentsMinWidth: Size.contentsMinWidth,
  // borderRadiusDefault: Size.borderRadiusDefault,
  // xSmall: Size.xSmall,
  // small: Size.small,
  // normal: Size.normal,
  // large: Size.large,
  // xLarge: Size.xLarge
};

const fontsVariable = {
  // font00: Fonts["font-00"],
  // font01: Fonts["font-01"],
  // font02: Fonts["font-02"],
  // font03: Fonts["font-03"],
  // font04: Fonts["font-04"],
  // font05: Fonts["font-05"],
  // font06: Fonts["font-06"],
  // font08: Fonts["font-08"],
  // font20: Fonts["font-20"],
  // font22: Fonts["font-22"],
  // font24: Fonts["font-24"],
  // font30: Fonts["font-30"],
  // fontFamily: Fonts["font-family"],
  // lineHeight: Fonts["line-height"],
};

Vue.prototype.$colors = colorVariable;
Vue.prototype.$size = sizeVariable;
Vue.prototype.$fonts = fontsVariable;
