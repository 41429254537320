
<v-chip
  class="atoms__chip !text-gray-600"
  :color="color"
  :x-small="size === 'xs'"
  :small="size === 'sm'"
  :large="size === 'lg'"
  :x-large="size === 'xl'"
  :close="close"
  v-bind="$attrs"
  v-on="$listeners"
>
  <slot></slot>
</v-chip>
