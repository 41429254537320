import { RequestMixin } from "@/mixins/RequestMixin";
import api from "@/service/api";
import { USER_TYPE } from "@/service/constants";

export const AuthMixin = {
  mixins: [RequestMixin],
  data: () => ({
    AUTH_LOGOUT_PROCESS: false,
  }),
  watch: {
    $route(route) {
      if (this.AUTH_LOGOUT_PROCESS === true && route.path === "/") {
        this.$keycloak.logoutFn();
      }
    },
  },
  methods: {
    /** 로그인 */
    async signIn() {
      // # 유저 정보 저장
      const user = await this.query(() => api.getCurrentUser());

      if (user.auth !== USER_TYPE.ADMINISTRATOR) {
        const roleList = await this.query(() =>
          api.getUserAuthList(user.id, true),
        );

        this.$set(user, "roleList", roleList);
      }

      // console.log("> sign in : ", user);
      this.$store.commit("SET_ACCOUNT_INFO", user);
    },
    /** 로그아웃 */
    signOut() {
      if (this.$route.path === "/") {
        this.$keycloak.logoutFn();
      } else {
        this.$router.replace("/");
      }
      setTimeout(() => {
        document.cookie.split(";").forEach((cookie) => {
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        });
        window.localStorage.removeItem(`customized-metrics`);
        window.localStorage.removeItem(`favoriteProject`);
        window.localStorage.removeItem(`recentProject`);
        return false;
      }, 300);
      window.localStorage.removeItem(process.env.VUE_APP_AUTH_TOKEN_HEADER_KEY);
      this.AUTH_LOGOUT_PROCESS = true;
    },
    /** 토큰을 반환 */
    getToken() {
      return localStorage.getItem("Authorization");
    },
  },
};
