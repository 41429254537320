import ProjectGuard from "@/components/common/ProjectGuard/ProjectGuard";
import ProjectAuthProvider from "@/components/common/ProjectAuthProvider/ProjectAuthProvider";

export default [
  {
    path: "MicroserviceList",
    name: "MicroServiceList",
    component: () =>
      import("../pages/main/modernframework/MicroService/MicroServiceList.vue"),
  },
  {
    path: "/projects",
    component: ProjectAuthProvider,
    children: [
      {
        path: "",
        component: ProjectGuard,
        children: [
          {
            path: ":projectId/profiles/:profileId/MicroServiceCreate",
            name: "MicroServiceCreate",
            component: () =>
              import(
                "../pages/main/modernframework/MicroService/MicroServiceCreatePage.vue"
              ),
          },
          {
            path: ":projectId/profiles/:profileId/microservices/:microserviceId/modify",
            name: "MicroServiceModify",
            component: () =>
              import(
                "@/pages/main/modernframework/MicroService/MicroServiceModifyPage.vue"
              ),
          },
          {
            path: ":projectId/profiles/:profileId/microservices/:microserviceId",
            name: "MicroServiceDetail",
            component: () =>
              import(
                "../pages/main/modernframework/MicroService/MicroServiceDetailPage.vue"
              ),
          },
          {
            path: ":projectId/profiles/:profileId/microservices/:microserviceId/clone",
            component: () =>
              import("../pages/application/ApplicationClonePage.vue"),
          },
        ],
      },
    ],
  },
];
