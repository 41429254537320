<template>
  <v-row class="flex-wrap w-full m-0" style="min-height: 280px">
    <v-col lg="3" md="6" class="p-0 pr-2 m-0">
      <MonitorChart :data="finalData.cpu" type="cpu" />
    </v-col>
    <v-col lg="3" md="6" class="p-0 pr-2 m-0">
      <MonitorChart :data="finalData.memory" type="memory" />
    </v-col>
    <v-col lg="3" md="6" class="p-0 pr-2 m-0">
      <MonitorChart :data="finalData.disk" type="disk" />
    </v-col>
    <v-col lg="3" md="6" class="p-0 m-0">
      <MonitorChart :data="finalData.network" type="network" />
    </v-col>
  </v-row>
</template>
<script>
import { roundNumber } from "@/pages/dashboard/helper";
import MonitorChart from "./MonitorChart.vue";

export default {
  props: { data: { type: Object, default: () => {} } },
  components: { MonitorChart },
  data: () => ({ finalData: {} }),
  watch: {
    data: {
      handler() {
        this.finalData = this.handleParsingData();
      },
      immediate: true,
    },
  },
  methods: {
    mergeObjects(obj1, obj2) {
      Object.keys(obj1 || {}).forEach((key) => {
        if (obj1?.[key] && obj2?.[key]) {
          obj1[key] = Number(obj1[key]) + Number(obj2[key]);
        }
      });

      return obj1;
    },
    convertUnitTransform(data, unit, digit = 2) {
      return Object.keys(data || {}).reduce((acc, key) => {
        acc[key] = roundNumber(Number(data[key]) / unit, digit);
        return acc;
      }, {});
    },
    handleParsingData() {
      const { cpu, memory, diskRead, diskWrite, networkIn, networkOut } =
        this.data;

      const parseData = {
        cpu,
        memory,
        disk: this.mergeObjects(diskRead, diskWrite),
        network: this.mergeObjects(networkIn, networkOut),
      };

      // convert unit CPU (milicores)
      parseData.cpu = this.convertUnitTransform(parseData.cpu, 1 / 1000, 5);

      // convert unit Memory (GiB)
      parseData.memory = this.convertUnitTransform(
        parseData.memory,
        1073741824,
        2,
      );

      // convert unit disk usage (MB)
      parseData.disk = this.convertUnitTransform(parseData.disk, 1000000, 2);

      // convert unit network (Mb)
      parseData.network = this.convertUnitTransform(
        parseData.network,
        1000000,
        2,
      );

      return parseData;
    },
  },
};
</script>

<style lang="scss"></style>
