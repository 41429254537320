
<div class="w-full">
  <h3 class="table-title pb-2">RDE Usage</h3>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-header="true"
    item-key="id"
    class="elevation-1 rde-usage-table w-full"
    show-expand
    :expanded.sync="expanded"
    :single-expand="singleExpand"
  >
    <template v-slot:header="{ props }">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          class="header-cell"
        >
          {{ header.text }}
        </th>
      </tr>
    </template>
    <template v-slot:item="{ item, expand, isExpanded }">
      <tr>
        <td class="expand-icon-cell">
          <v-icon @click="expand(!isExpanded)">
            {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </td>
        <td>{{ item.rdeName }}</td>
        <td>{{ item.workspaceName }}</td>
        <td>{{ item.instanceType }}</td>
        <td>{{ formatDate(item.createdDate) }}</td>
        <td>{{ item.used }}</td>
        <td>{{ item.billing }}</td>
        <td>{{ item.creator }}</td>
      </tr>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="3"></td>
      <td :colspan="headers.length + 1" class="pb-1">
        <v-data-table
          :headers="headersExpand"
          :items="item.details"
          :hide-default-header="false"
          :hide-default-footer="true"
          item-key="id"
          class="elevation-1 rde-usage-table w-full"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.instanceType }}</td>
              <td>{{ item.cpu }}</td>
              <td>{{ item.memory }}</td>
              <td>{{ item.disk }}</td>
              <td>{{ item.diskType }}</td>
              <td>{{ item.used }}</td>
              <td>{{ item.billing }}</td>
            </tr>
          </template>
        </v-data-table>
      </td>
    </template>
    <template v-slot:no-data>
      <v-alert :value="true" color="error" icon="mdi-alert">
        No data available
      </v-alert>
    </template>
  </v-data-table>
</div>
