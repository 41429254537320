import RoleGuard from "@/components/common/RoleGuard/RoleGuard.vue";

export default [
  {
    path: "",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR"],
      redirectPath: "/",
    },
    children: [
      {
        path: "/Setup/user",
        name: "UserList",
        component: () =>
          import("../pages/main/modernframework/User/UserList.vue"),
        meta: {
          auth: "ADMINISTRATOR",
        },
      },
      {
        path: "/Setup/userCreate",
        name: "UserCreate",
        component: () =>
          import("../pages/main/modernframework/User/UserCreate.vue"),
      },
      {
        path: "/Setup/user/:id/modify",
        name: "UserModify",
        component: () =>
          import("../pages/main/modernframework/User/UserCreate.vue"),
      },
      {
        path: "/Setup/user/:id/change-password",
        name: "ChangeAnotherUserPassword",
        component: () =>
          import(
            "../pages/main/modernframework/User/ChangeAnotherUserPasswordPage.vue"
          ),
      },
    ],
  },
  {
    path: "",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR", "DEVELOPER"],
      redirectPath: "/",
    },
    children: [
      {
        path: "/Setup/user/:userId",
        component: () => import("../pages/user/UserDetailPage.vue"),
      },
      // {
      //   path: "Setup/user/view",
      //   name: "UserDetail",
      //   alias: ["user/:userId/view"],
      //   component: () =>
      //     import("../pages/main/modernframework/User/UserDetail.vue"),
      // },
      // {
      //   path: "Setup/user/password",
      //   name: "UserChangePassword",
      //   component: () =>
      //     import("../pages/main/modernframework/User/UserChangePassword.vue"),
      // },
    ],
  },
];
