import { merge } from "lodash";

export const transformOptions = (options) => {
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: () => "",
      },
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 10,
          },
          boxWidth: 10,
          color: "#4F4F4F",
        },
      },
    },
    interaction: {
      intersect: false,
    },
    radius: 0,
  };

  return merge(defaultOptions, options);
};

export const transformPlugins = (plugins) => {
  // We can set some default plugins that be suitable for amdb
  return plugins || [];
};

export const transformData = (data) => {
  // We can customize data that be suitable for amdb
  return data;
};
