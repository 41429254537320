<template>
  <RouterView v-if="loaded" />
</template>

<script>
import { mapState } from "vuex";
import { RequestMixin } from "@/mixins/RequestMixin";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";

export default {
  /** ===== props ===== */
  props: {
    /** 접근 실패 시 리다이렉트 경로 */
    failedPath: {
      type: String,
      default: "/",
    },
  },
  mixins: [RequestMixin, ProfileAuthMixin],
  /** ===== data ===== */
  data: () => ({
    /** true일 경우 기본 마운트 처리 완료 되었을 경우 */
    loaded: false,
  }),
  computed: {
    ...mapState({
      accountInfo: (state) => state.accountInfo,
      project: (state) => state.project,
    }),
  },
  /** ===== mounted ===== */
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        const user = this.accountInfo;
        const { id, projectId } = route.params;
        const newProjectId = Number(projectId ?? id);

        try {
          await this.loadProfileAuth({
            userId: user.id,
            userRole: user.auth,
            projectId: newProjectId,
          });
        } finally {
          this.loaded = true;
        }
      },
    },
  },
  /** ===== methods ===== */
  methods: {},
  /** ===== mounted ===== */
  async mounted() {},
  /** ===== unmounted ===== */
  destroyed() {
    this.profileAuthInitialize();
  },
};
</script>

<style lang="scss" scoped></style>
