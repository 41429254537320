
<div class="rde-state-page">
  <div class="header">
    <div class="left-header">
      <img
        src="~@/assets/img/arrow-back.svg"
        alt="SVG Icon"
        :style="{ cursor: 'pointer' }"
        @click="goBack"
      />
      <span>{{ rdeState.name }}</span>
    </div>
  </div>
  <v-container class="p-2 m-0" style="max-width: unset">
    <ChartGroup :data="overallChartData" />

    <PodTable :data="podList" />

    <v-expansion-panels v-model="activePanel" class="pt-2">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            {{ rdeState.name }} - <span class="font-bold">VS Code</span></span
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ChartGroup :data="vscodeChartData" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span
            >{{ rdeState.name }} - <span class="font-bold">SSH</span></span
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ChartGroup :data="sshChartData" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span
            >{{ rdeState.name }} -
            <span class="font-bold">Jupiter</span></span
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ChartGroup :data="jupiterChartData" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</div>
