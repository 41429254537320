
<v-text-field
  :id="id"
  ref="input"
  class="app-g-text-field atoms__input"
  autocomplete="off"
  :disabled="disabled"
  :readonly="readonly"
  :placeholder="placeholder"
  :outlined="outlined"
  :dense="dense"
  :clearable="newClearable"
  :type="type"
  :value="value"
  :rules="rules"
  :counter="counter"
  :error-messages="innerErrorMessage"
  :queryValidation="queryValidation"
  :hideDetails="hideDetails"
  :class="[
    `size-${size}`,
    text && 'text-style',
    hideDetails && 'hide-details',
    newBackgroupColor && bgClass.router(newBackgroupColor),
    appendClass,
  ]"
  v-bind="$attrs"
  :label="label"
  v-on="$listeners"
  @click.stop
  @input="handleInput"
>
  <template v-if="$scopedSlots.prepend" slot="prepend">
    <slot name="prepend"></slot>
  </template>
  <template v-if="$scopedSlots.append" slot="append">
    <slot name="append"></slot>
  </template>
</v-text-field>
