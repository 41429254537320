import WorkspaceGuard from "@/components/common/WorkspaceGuard/WorkspaceGuard.vue";
import EditRDEPage from "../pages/newWorkspace/EditRDEPage.vue";
import RDEState from "../pages/newWorkspace/RDEState.vue";
import MeteringPricingPage from "../pages/mertingPricing/MeteringPricingPage.vue";

export default [
  {
    path: "newwp",
    component: WorkspaceGuard,
    children: [
      {
        path: "",
        component: () => import("../pages/newWorkspace/newWorkspacePage.vue"),
      },
      {
        path: "list-rde/:workspaceId/:updatedId?",
        component: () => import("../pages/newWorkspace/listRdePage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "workspace/:workspaceId/rde/create",
    component: EditRDEPage,
    meta: {
      title: "Create RDE",
      isMenuItem: true,
      breadCrumb: [{ text: "Create RDE" }],
    },
  },
  {
    path: "workspace/:workspaceId/rde/:rdeId/modify",
    component: EditRDEPage,
    meta: {
      title: "Edit RDE",
      isMenuItem: true,
      breadCrumb: [{ text: "Edit RDE" }],
    },
  },
  {
    path: "workspace/rde-state/:namespace/:wsName/:rde",
    component: RDEState,
    meta: {
      title: "RDE State",
      isMenuItem: true,
    },
  },
  {
    path: "metering-pricing",
    component: MeteringPricingPage,
    meta: {
      title: "Metering & Pricing",
      breadCrumb: [{ text: "Metering & Pricing" }],
    },
  },
];
