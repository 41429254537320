import isEmpty from "lodash/isEmpty";
import { isValidCron } from "cron-validator";

const rules = {
  /** 필수값 */
  required: (v) => {
    // use it when value is object
    if (typeof v === "object" && isEmpty(v)) return "Required";

    return !!v || "Required";
  },
  /** 비밀번호 */
  password: (v) => {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    if (!v) {
      return "비밀번호를 입력하십시오";
    }
    if (v.length < 6) {
      return "최소 6글자 이상 입력해 주십시오";
    }
    if (!reg.test(v)) {
      return "영문자 대문자, 소문자, 그리고 숫자가 반드시 하나 이상 들어가야 합니다";
    }
    return true;
  },
  /** (알파벳 | 숫자 | _ | -) 허용 */
  alphanumeric: (value) => {
    const reg = /^[\w-]+$/;
    if (!reg.test(value)) {
      return "Only alphanumeric, underscore(_) and hyphen(-) are allowed.";
    }
    return true;
  },
  /** 이름 등록 시 규칙 */
  name: (value) => {
    // # 값이 존재 해야함
    if (value == null || value.length < 1) {
      return "Required";
    }

    //SKCPL-338 value == undefined일때 앞에서 안걸러지고 여기까지 넘어옴
    if (value != null && value.length > 253) {
      return "No more then 253 characters";
    }

    // # 허용문자(영문 소문자 | 숫자 | - | .)
    if (!/^[a-z0-9-.]+$/.test(value)) {
      return "only lowercase alphanumeric characters, '-' or '.' allowed";
    }

    // # 시작문자, 종료 문자 제한
    const alphanumeric = /^[a-z0-9]+$/;
    if (value != null && !alphanumeric.test(value.slice(0, 1))) {
      return "Must start with an alphanumeric character";
    }
    if (value != null && !alphanumeric.test(value.slice(-1))) {
      return "Must end with an alphanumeric character";
    }

    return true;
  },
  /** DNS Rules(한글 허용 안함) */
  dns: (value) => {
    const reg = /[a-z]([-a-z0-9]*[a-z0-9])?/;
    if (typeof value !== "string") {
      return "Required ";
    }
    // // # 2자 이상
    // if (value.length < 2) {
    //   return "Minimum of 2 characters";
    // }

    // // # 63자 이하
    // if (value.length > 63) {
    //   return "Maximum of 63 characters";
    // }
    // console.log(reg.test(value));
    // # dns명 검사
    if (!reg.test(value)) {
      return "Invalid DNS format";
    }

    return true;
  },
  /** 이메일 규칙 */
  email: (value) => {
    var reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(value) == true ? true : "Wrong email address format";
  },
  /** 도메인 규칙 */
  domain: (value) => {
    // https://stackoverflow.com/a/26987741
    const reg =
      /^(((?!-))(xn--|_{1,1})?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
    return reg.test(value) || "Invalid domain format";
  },
  /** url 규칙 */
  url: (value) => {
    if (!value) return true;
    const reg =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=](?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    if (!reg.test(value)) {
      return "Wrong URL format";
    }
    return true;
  },
  /** git url 규칙 */
  gitUrl: (value) => {
    const reg =
      /(?:git|ssh|https?|git@[-\w.]+):(\/\/)?(.*?)(\.git)(\/?|#[-\d\w._]+?)$/;
    return reg.test(value) || "Invalid URL format";
  },
  /** 시간 문자열 입력 규칙(`01` 같이 처음이 0으로 시작하는 문자 허용) */
  hour: (value) => {
    const reg = /^((0)|([0-1])?([0-9])|(2[0-3]))$/;
    return reg.test(value) || "0-23의 숫자를 입력";
  },
  /** 분 문자열 입력 규칙(`01` 같이 처음이 0으로 시작하는 문자 허용) */
  minute: (value) => {
    const reg = /^((0)|([0-5])?([0-9]))$/;
    return reg.test(value) || "0-59의 숫자를 입력";
  },
  /** 지정된 문자가 포함되어 있을 경우 차단 */
  blockText: (text) => (value) => {
    // console.log("> block: ", text, value);
    if (!value) {
      return true;
    }
    return !value.includes(text) || `"${text}" 는 입력할 수 없습니다`;
  },
  /** Linux Cron 정규식 */
  cronText: (value) => {
    const reg =
      /^(\*|[0-9\-\,]+)\s(\*|[0-9\-\,]+)\s(\*|[0-9\-\,]+)\s(\*|[0-9\-\,]+)\s(\*|[0-9\-\,]+)$/;
    return (
      reg.test(value) ||
      "Linux Cron 정규식게 맞게 입력해주세요. 예시) 5 * * * *"
    );
  },
  prefix_task: (value) => {
    const regex = /^\[[0-9a-zA-Z]{0,13}\]$/;
    if (value && !regex.test(value)) {
      return "The value should be formatted like [XXXX]. Examples: [AMDP], [ZCP]. The commit message will then be [AMDP-123] Add new feature.";
    }

    return true;
  },
  cron_job_general: (value) => {
    return (
      isValidCron(value || "", {
        seconds: true,
        alias: true,
        allowSevenAsSunday: true,
      }) || "Cron regex is not correct"
    );
  },
  maxLength(max) {
    return (v) => {
      if (!v) return true;
      return v.length <= max || `Max ${max} characters`;
    };
  },
};

export const RulesMixin = {
  data: () => ({
    rules,
  }),
};
