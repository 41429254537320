<template>
  <RouterView v-if="allowed" />
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  data: () => ({
    allowed: !(process.env.VUE_APP_MENU_HIDE_WORKSPACE === "true"),
  }),
  async mounted() {
    if (!this.allowed) {
      CommonUIControl.ShowErrorToast("접근할 수 없습니다");
      await this.$router.replace("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
