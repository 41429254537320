
<div>
  <!-- Row 1: Label and dropdown box -->
  <div class="row my-0">
    <div class="col columns">
      <!-- Label on the left -->
      <span class="total-label">Total</span>
      <span class="total-value"> ₩10,000</span>
    </div>
    <div class="col columns text-right">
      <!-- Dropdown box on the right -->
      <DateRangePicker
        v-model="dateRange"
        opens="left"
        :genRanges="genRanges"
        :max-duration-start-end="0"
        viewFormat="DD/MM/YYYY"
        class="w-auto ml-auto"
        :timePicker="false"
      />
    </div>
  </div>
  <!-- Row 2: Two multi-select components -->
  <div v-if="isAdmin" class="row my-0">
    <div class="col py-0 px-0">
      <!-- Left multi-select component -->
      <v-autocomplete
        v-model="workspaceSelected"
        :items="workspaceItems"
        placeholder="Select left item"
        class="app-g-autocomplete-v2"
        multiple
        outlined
        dense
        chips
        clearable
        deletable-chips
        small-chips
      >
      </v-autocomplete>
    </div>
    <div class="col py-0 px-0">
      <!-- Right multi-select component -->
      <v-autocomplete
        v-model="userSelected"
        :items="userItems"
        placeholder="Select right item"
        class="app-g-autocomplete-v2"
        multiple
        outlined
        dense
        chips
        clearable
        deletable-chips
        small-chips
      />
    </div>
  </div>

  <!-- Row 3: Table -->
  <div class="row">
    <RdeCountTable />
  </div>

  <!-- Row 4: Table with pagination -->
  <div class="row">
    <RdeUsageTable />
    <!-- Pagination controls -->
    <div class="pagination-controls">
      <!-- Pagination buttons or controls go here -->
    </div>
  </div>

  <!-- Row 5: Table with search box and export button -->
  <div class="row">
    <!-- <div class="col py-0 px-0">
      <FInput
        v-model="inputSearch"
        :append-icon="'mdi-magnify'"
        outlined
        dense
        placeholder="Search"
        label="Search"
        size="lg"
        class="search pb-3"
        hide-details
        @input="debounceInput"
      />
    </div>
    <div class="col py-0 px-0 text-right">
      <FButton
        size="md"
        fill
        depressed
        color="white"
        class="text-transform-none buttons"
      >
        Export
      </FButton>
      <FButton
        size="md"
        fill
        depressed
        color="white"
        class="text-transform-none ml-3 buttons"
      >
        Price Policy
      </FButton>
    </div> -->
    <div class="col py-0 px-0 columns">
      <OverviewTable />
    </div>
  </div>
</div>
