
<div
  class="indicator-wrapper flex flex-col px-3"
  :class="[extraClass, { 'mr-6': splitter }]"
>
  <div class="indicator w-full" :class="titleClass">
    <div class="text-sm i-title w-auto flex flex-row gap-1">
      <SvgIcon v-if="icon" :iconName="icon" size="sm" color="#87888C" />
      {{ title }}
    </div>
    <div class="">
      <span class="font-bold ivalue">{{
        $helper.roundNumber(value, 1)
      }}</span>
      <span v-if="usageUnit" class="pl-2 text-sm i-unit">{{
        usageUnit
      }}</span>
      <span v-if="limitNumber >= 0" class="mx-1 text-lg i-unit slash">/</span>
      <span v-if="limitNumber >= 0" class="font-bold ivalue">{{
        limitNumber
      }}</span>
      <span class="pl-2 text-sm i-unit">{{ unit }}</span>
    </div>
  </div>
  <div v-if="showPercent && percent >= 0" class="resource-bar mt-2">
    <div
      class="resource-bar-inner"
      :style="{ width: percent + '%', backgroundColor: color }"
    ></div>
  </div>
</div>
