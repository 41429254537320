import Vue from "vue";

export default {
  namespaced: true,
  state: () => ({
    workspaceList: [],
    rdeMetrics: [],
  }),
  mutations: {
    SET_WORKSPACE_LIST(state, payload) {
      state.workspaceList = payload;
    },
    SET_RDE_METRIC(state, { id, currentUsage }) {
      const index = state.rdeMetrics.findIndex((metric) => metric.id === id);
      if (index !== -1) {
        Vue.set(state.rdeMetrics, index, { id, currentUsage });
      } else {
        state.rdeMetrics.push({ id, currentUsage });
      }
    },
    REMOVE_RDE_METRIC(state, id) {
      state.rdeMetrics = state.rdeMetrics.filter((metric) => metric.id !== id);
    },
    INIT_RDE_METRIC(state, payload) {
      state.rdeMetrics = payload;
    },
    CLEAR_RDE_METRIC(state) {
      state.rdeMetrics = [];
    },
  },
  actions: {
    setWorkspaceList: ({ commit }, payload) =>
      commit("SET_WORKSPACE_LIST", payload),
    setRdeMetric({ commit }, { id, currentUsage }) {
      commit("SET_RDE_METRIC", { id, currentUsage });
    },
    removeRdeMetric({ commit }, id) {
      commit("REMOVE_RDE_METRIC", id);
    },
    initRdeMetric({ commit }, payload) {
      commit("INIT_RDE_METRIC", payload);
    },
    clearRdeMetric({ commit }) {
      commit("CLEAR_RDE_METRIC", []);
    },
  },
};
