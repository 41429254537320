
<div
  class="compositions__collapse"
  :class="{ outlined, expand: sw }"
  tabindex="0"
>
  <!-- title -->
  <div
    class="collapse__title"
    :class="[
      titlePadding === false ? '' : 'p-3',
      titleClass,
      sw ? expandedTitleClass : collapsedTitleClass,
      hideIcon ? '' : 'flex justify-between items-center',
    ]"
    :title="title"
    @click.stop="handleToggleClick"
  >
    <div name="title" :class="darkMode ? 'text-white' : ''">
      {{ $props.title }}
      <slot name="title" :sw="sw"></slot>
    </div>
    <v-icon
      v-if="!hideIcon"
      class="icon"
      small
      role="button"
      :color="iconColor"
      :dark="darkMode"
    >
      {{ sw ? "mdi-minus" : "mdi-plus" }}
    </v-icon>
  </div>

  <!-- content -->
  <div
    class="collapse__content border-gray-300"
    :class="[
      outlined && sw ? 'border-t' : '',
      contentPadding === false ? '' : 'px-3',
      animationDisabled === true ? 'animation-disabled' : '',
    ]"
    :style="{
      height:
        (animationDisabled === true && sw === true) ||
        (!animationDisabled && done === true)
          ? 'initial'
          : `${contentHeight}px`,
    }"
    @transitionend="handleContentTransitionEnd"
  >
    <!-- :style="{ height: done === false ? `${contentHeight}px` : 'initial' }" -->
    <div
      ref="contentBodyEl"
      class="content__body"
      :class="{ visibleBody: visibleBody, 'py-4': outlined }"
    >
      <slot :sw="sw" :toggle="toggle" :close="close"></slot>
    </div>
  </div>
</div>
