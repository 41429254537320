import httpClient from "../httpClient";
import { request } from "../request";
import qs from "qs";
const requestOption = { timeout: 40000 };

export default {
  /** {
    "name": "awesome-order.demo.awesome-shopping",
    "configPath": "configuration/overlay/demo/awesome-order-demo",
    "namespace": "amdp-demo",
    "projectName": "awesome-demo",
    "projectDescription": "awesome-demo description",
    "repoUrl": "https://eks-dev-zdb-git.cloudzcp.io/mptf/awesome-shopping-demo-configuration.git",
    "repoUsername": "repousername",
    "repoPassword": "repopassword",
    "repoTlsClientCertData": "",
    "repoTlsClientCertKey": "",
    "clusterUrl": "https://kubernetes.default.svc"
  } */
  createArgoCDApplication(pipelineId, param) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/pipeline/query/${pipelineId}/argocd/deploy`,
          param,
          requestOption,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  syncArgoCDApplication(pipelineId) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/query/${pipelineId}/argocd/sync`, requestOption)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  commitsyncArgoCDApplication(pipelineId, fullSemVer) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/pipeline/query/${pipelineId}/argocd/commitsync`,
          { fullSemVer },
          requestOption,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getArgoCDApplications(pipelineId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/query/${pipelineId}/argocd`, requestOption)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getApplicationByProjectAndProfile(projectIds, profileIds) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/dashboard/filter/microservices`, {
          params: {
            projectIds,
            profileIds,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getApplicationLatestList({ appIds }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/cicd-dashboard/honeycomb", {
          params: {
            appIds,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};

/**
 * 애플리케이션 복제
 * @typedef {object} CloneApplicationParam
 * @property {number|string} projectId - 프로젝트 아이디
 * @property {number|string} profileId - 프로파일 아이디
 * @property {number|string} microserviceId - 애플리케이션 아이디
 * @property {CloneApplicationParamData} data - 업데이트 데이터
 */
export const cloneApplication = ({
  projectId,
  profileId,
  microserviceId,
  data,
}) => {
  return request({
    method: "post",
    uri: `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/clone`,
    data,
    timeout: 1000 * 120,
  });
};

/**
 * @typedef {object} CloneApplicationParamData
 * @property {string} name - 애플리케이션 명
 * @property {string} description - 설명
 * @property {string} branch - 브랜치 명
 * @property {string} monoRepo - 모노레포 경로
 * @property {"SPRINGBOOT"|"VUEJS"|"REACT"|"NEXACRO"|"DOCKER"} framework - 프레임워크 종류
 * @property {string} frameworkBaseVersion - SPRINGBOOT일 경우 프레임워크 버전
 * @property {"MAVEN"|"GRADLE"} buildType - SPRINGBOOT일 경우 빌드 타입
 * @property {string} labels - 레이블
 * @property {number} databaseId - 데이터 베이스 아이디
 * @property {{id: number, mountPath: string}[]} pvcList - pvc 목록
 */

/**
 * @typedef {Readiness & Liveness} ServiceStabilizeProperty - 서비스 안정화 설정
 */

/**
 * @typedef {object} Readiness
 * @property {boolean} useReadiness - `true`일 경우 readiness 사용
 * @property {"exec"|"httpGet"} readinessExecType
 * @property {string} readinessCommand - readinessExecType이 exec일 경우 사용 커맨드
 * @property {boolean} useDefaultReadinessPath - true일 경우 readinessPath 기본값 적용
 * @property {string} readinessPath - readinessExecType이  httpGet일 경우 path
 * @property {boolean} useDefaultReadinessPort - true일 경우 readinessPort 기본값 적용
 * @property {number} readinessPort - readinessExecType이 httpGet일 경우 port
 * @property {boolean} useDefaultReadinessInitialDelaySeconds - true일 경우 readinessInitialDelaySeconds 기본값 적용(0)
 * @property {number} readinessInitialDelaySeconds - Readiness Probe가 시작되기 전에 컨테이너가 시작된 후 시간(초)입니다. StartUP Probe가 정의된 경우에는 해당 설정에 대한 값은 대기 후에 진행 됩니다. 기본값은 0, 최솟값은 0입니다.
 * @property {boolean} useDefaultReadinessTimeoutSeconds - true일 경우 readinessTimeoutSeconds 기본값 적용(1)
 * @property {number} readinessTimeoutSeconds - Readiness Probe의 시간 초과에에 대한 설정값입니다. 단위는 시간(초)이고 기본값은 1, 최솟값은 1입니다.
 * @property {boolean} useDefaultReadinessPeriodSeconds - true일 경우 readinessPeriodSeconds 기본값 적용(10)
 * @property {number} readinessPeriodSeconds - Readiness Probe을 수행하는 주기에 대한 설정값입니다. 단위는 시간(초)이고 기본값은 10, 최솟값은 1입니다.
 * @property {boolean} useDefaultReadinessSuccessThreshold - true일 경우 readinessPeriodSeconds 기본값 적용(1)
 * @property {number} readinessSuccessThreshold - Readiness Probe가 실패한 경우에 성공으로 처리하기 위한 최소 연속 성공 횟수를 설정합니다. 기본값은 1, 최솟값은 1입니다.
 * @property {boolean} useDefaultReadinessFailureThreshold - true일 경우 readinessFailureThreshold 기본값 적용(3)
 * @property {number} readinessFailureThreshold - Readiness Probe가 컨테어니를 실패로 처리하기 위한 연속 실패 회수를 설정합니다. 기본값은 3, 최솟값은 1입니다.
 */

/**
 * @typedef {object} Liveness
 * @property {boolean} useLiveness - true일 경우 liveness 적용
 * @property {"exec"|"httpGet"} livenessExecType
 * @property {string} livenessCommand - livenessExecType 이 exec일 경우 사용 커맨드
 * @property {boolean} useDefaultLivenessPath - true일 경우 livenessPath 기본값 적용
 * @property {string} livenessPath - livenessExecType 이 httpGet일 경우 path
 * @property {boolean} useDefaultLivenessPort - true일 경우 livenessPort 기본값 적용
 * @property {number} livenessPort - livenessExecType 이 httpGet일 경우 port
 * @property {boolean} useDefaultLivenessInitialDelaySeconds - true일 경우 livenessInitialDelaySeconds 기본값 적용(0)
 * @property {number} livenessInitialDelaySeconds - Liveness Probe가 시작되기 전에 컨테이너가 시작된 후 시간(초)입니다. StartUP Probe가 정의된 경우에는 해당 설정에 대한 값은 대기 후에 진행 됩니다. 기본값은 0, 최솟값은 0입니다.
 * @property {boolean} useDefaultLivenessTimeoutSeconds - true일 경우 livenessTimeoutSeconds 기본값 적용(1)
 * @property {number} livenessTimeoutSeconds - Liveness Probe의 시간 초과에에 대한 설정값입니다. 단위는 시간(초)이고 기본값은 1, 최솟값은 1입니다.
 * @property {boolean} useDefaultLivenessPeriodSeconds - true일 경우 livenessPeriodSeconds 기본값 적용(10)
 * @property {number} livenessPeriodSeconds - Liveness Probe을 수행하는 주기에 대한 설정값입니다. 단위는 시간(초)이고 기본값은 10, 최솟값은 1입니다.
 * @property {boolean} useDefaultLivenessSuccessThreshold - true일 경우 livenessSuccessThreshold 기본값 적용(1)
 * @property {number} livenessSuccessThreshold - Liveness Probe가 실패한 경우에 성공으로 처리하기 위한 최소 연속 성공 횟수를 설정합니다. 기본값은 1, 최솟값은 1입니다.
 * @property {boolean} useDefaultLivenessFailureThreshold - true일 경우 livenessFailureThreshold 기본값 적용(3)
 * @property {number} livenessFailureThreshold - Liveness Probe가 컨테어니를 실패로 처리하기 위한 연속 실패 회수를 설정합니다. 기본값은 3, 최솟값은 1입니다.
 */
