<template>
  <div class="common__page-template">
    <!-- header -->
    <PageHeader fixed :breadcrumbs="breadcrumbs" :useBackButton="useBackButton">
      <template #left>
        <slot name="left"></slot>
      </template>
      <template #left-next>
        <slot name="left-next"></slot>
      </template>

      <template #center>
        <slot name="center"></slot>
      </template>
      <template #right>
        <slot name="right"></slot>
      </template>
    </PageHeader>

    <!-- body -->
    <div :class="padding" role="main">
      <Card v-if="useCard" class="page-template__content-card relative">
        <slot></slot>
      </Card>
      <slot v-else></slot>
    </div>
  </div>
</template>

<script>
import Card from "@/components/atoms/Card/Card.vue";
import PageHeader from "../PageHeader/PageHeader.vue";

export default {
  props: {
    /** 헤더에 breadcrumb를 표시 */
    breadcrumbs: {
      type: Array,
    },
    /** true일 경우 기본 Card 컴포넌트가 적용된다. */
    useCard: {
      type: Boolean,
      default: true,
    },
    /** true일 경우 헤더에 뒤로가기 버튼이 표시된다. */
    useBackButton: {
      type: Boolean,
      default: false,
    },
    padding: { type: String, default: "p-8" },
  },
  components: { PageHeader, Card },
};
</script>

<style lang="scss"></style>
