<template>
  <v-checkbox
    class="atoms__checkbox"
    :class="[simply && 'simple']"
    :inputValue="innerValue"
    :label="label"
    hide-details
    v-bind="$attrs"
    v-on="$listeners"
    @change="handleCheckboxChange"
  >
    <template #label>
      <slot name="label"></slot>
    </template>
    <template>
      <slot></slot>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  props: {
    /** 값 */
    value: {
      type: Boolean,
    },
    /** true일 경우 스타일을 단순화.(헬퍼텍스트등의 공간을 없앤다) */
    simply: {
      type: Boolean,
      default: true,
    },
    /** label */
    label: {
      type: String,
    },
  },
  emits: ["input"],
  data: () => ({
    innerValue: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.innerValue = v;
      },
    },
  },
  methods: {
    handleCheckboxChange(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style lang="scss">
.atoms__checkbox {
  display: inline-block;
  & .v-input__slot {
    width: initial;
  }
  label {
    width: max-content;
  }

  /** 단순화 */
  &.simple {
    margin-top: 0px;
    padding-top: 0px;
    & .v-input__control {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .v-input__slot {
      min-width: 24px;
      min-height: 24px !important;
      margin-bottom: 0px;
      gap: 4px;
    }
    & .v-messages {
      display: none;
    }
  }
}
</style>
