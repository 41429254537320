import { connectableRules } from "./rules/connectableRules";
import { gropuRules } from "./rules/groupRules";

/** 커넥터 정보 생성 */
export const createConnector = ({ node, x, y }) => {
  const newX = node.x + node.width + 20;
  const newY = node.y;

  return {
    M: [newX, newY],
    L: [newX, newY],
    id: node.id,
    startX: x,
    startY: y,
  };
};

/**
 * 마이크로 서비스와의 관계 정보
 * @return {[parentReferenceId, childReferenceId][]}
 */
export const relationshipWithMicroservice = ({
  microserviceDetails,
  nodes,
}) => {
  const referenceIdSet = new Set();

  nodes.forEach((a) => referenceIdSet.add(a.referenceId));

  // console.log("> ", deepCopy(nodes), referenceIdSet);

  const microserviceRelationships = microserviceDetails
    .map((a) => {
      const { backend_property: backendService, pvc } = a;
      const {
        useDatabase,
        databaseId,
        useMessageChannel,
        messageChannelId,
        useCache,
        cacheId,
        useSession,
        sessionId,
      } = backendService;

      const newNode = [
        [useDatabase, databaseId],
        [useMessageChannel, messageChannelId],
        [useCache || useSession, cacheId || sessionId],
      ]
        .filter(
          ([condition, id]) =>
            condition === true && id && referenceIdSet.has(id),
        )
        .map(([, id]) => [
          a.id, // 부모 노드 아이디
          id, // 자식 노드 아이디
        ]);

      const pvcIds = pvc.map((b) => [a.id, b.id]);

      return [...newNode, ...pvcIds];
    })
    .filter((a) => a.length > 0)
    .flat();

  return microserviceRelationships;
};

/** 인그레스의 관계 정보
 * @return {[microserviceReferenceId, ingressReferenceId][]}
 */
export const relationshipWithIngress = ({ ingresses, nodes = {} }) => {
  const referenceIdSet = new Set();

  nodes.forEach((a) => referenceIdSet.add(a.referenceId));

  const ingressRelationships = ingresses
    .map((a) => [
      a.id,
      a.hosts?.reduce((acc, cur) => [...acc, ...(cur?.paths ?? [])], []),
    ])
    .filter(
      ([ingressesId, paths]) =>
        paths?.length > 0 && referenceIdSet.has(ingressesId),
    )
    .flatMap(([ingressesId, paths]) =>
      paths.map((a) => [ingressesId, a.microservice_id]),
    )
    .reduce(
      (acc, [a, b]) =>
        acc.find(([c, d]) => a === c && b === d) ? acc : [...acc, [a, b]],
      [],
    );
  return ingressRelationships;
};

/** 중복되지 않은 노드 데이터 */
export const createNewNodes = (services) => {
  const nodes = [];
  let i = 0;

  // # 기본 노드 정보 생성
  for (const [serviceName, serviceList] of Object.entries(services)) {
    for (const service of serviceList) {
      const newNode = convertNode.router({
        serviceName,
        data: {
          ...service,
        },
        index: i,
      });

      i++;
      nodes.push(newNode);
    }
  }

  return nodes;
};

/** 메인노드는 전체 보여주고 서브 노드는 연결되어 있는 것만 보여주기 */
export const createConnectionSubNodes = ({ nodes, relationships }) => {
  const referenceIdMap = nodes.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.referenceId]: cur,
    }),
    {},
  );
  // console.log("> a: ", deepCopy(referenceIdMap), deepCopy(relationships));

  const childNodes = relationships
    // 부모, 자식 노드 중 하나라도 없을 경우 제외
    .filter(([parentId, childId]) => {
      const parentNode = referenceIdMap[parentId];
      const childNode = referenceIdMap[childId];
      return !!(!!parentNode && !!childNode);
    })
    .map(([parentId, childId]) => {
      const parentNode = referenceIdMap[parentId];
      const childNode = referenceIdMap[childId];
      return getNewNode(childNode, { parentNode });
    });

  // # mainNode에 subNode 합치기
  const result = nodes
    .filter((a) => a.relationType === "mainNode")
    .concat(childNodes);

  // console.log("> ", deepCopy(result));
  return result;
};

/** 사각형이 히트 했는지 검사 */
export const hitRect = (rectA, rectB) => {
  return !(
    rectA.x + rectA.width < rectB.x ||
    rectB.x + rectB.width < rectA.x ||
    rectA.y + rectA.height < rectB.y ||
    rectB.y + rectB.height < rectA.y
  );
};

/** 새로운 노드 반환 */
export const getNewNode = (node = {}, { parentNode } = {}) => {
  const { type, referenceId } = node;
  const { group } = gropuRules.router(type);
  const { width, height, iconSize } = nodeSize.router(group);
  const newRelationType = relationType.router(group);
  const parentType = parentNode?.type;
  const parentReferenceId = parentNode?.referenceId;
  const newNode = template.router("node", {
    ...node,
    id: getRandomId(),
    uniqueKey: getUniqueKey({
      type: type,
      referenceId: referenceId,
      parentType,
      parentReferenceId,
    }),
    group,
    width,
    height,
    iconSize,
    relationType: newRelationType,
    parentType,
    parentReferenceId,
  });

  return newNode;
};

/** targetNode가 sourceNode의 부모가 가능할 경우 */
export const nodeRelationship = (sourceNode, targetNode) => {
  const { parentConnectableGroups, childConnectableGroups } =
    connectableRules.router(sourceNode.type);
  const isParent = parentConnectableGroups.includes(targetNode.group);
  const isChild = childConnectableGroups.includes(targetNode.group);
  let parentNode;
  let childNode;

  if (isParent === true || isChild === true) {
    parentNode = isParent === true ? targetNode : sourceNode;
    childNode = isChild === true ? targetNode : sourceNode;
  }

  return {
    isParent,
    isChild,
    parentNode,
    childNode,
  };
};

/** 지정된 단위 반올림 */
export const rounding = (num, unit = 50) => Math.round(num / unit) * unit;

/** 자릿수 구하기 */
export const getDigit = (num) => {
  return num.toString().length;
};

/** debounce & throttle */
export const timeoutFn = (type) => {
  let throttleFn;
  let debounceFn;

  /** debounce */
  const debounce = (f, time = 100) => {
    if (debounceFn) {
      clearTimeout(debounceFn);
    }
    debounceFn = setTimeout(() => {
      if (typeof f === "function") {
        f();
      }
    }, time);
  };

  /** throttle */
  const throttle = (f, time = 100) => {
    if (throttleFn) {
      debounce(f, time);
      return;
    }
    // console.log("> ", typeof f);
    if (typeof f === "function") {
      f();
    }
    throttleFn = setTimeout(() => {
      throttleFn = null;
    }, time);
  };

  /** instance */
  return (f, time = 100) => {
    switch (type) {
      case "debounce":
        return debounce(f, time);
      case "throttle":
        return throttle(f, time);
      default:
        throw new Error("timeoutFn() invalid type: " + type);
    }
  };
};

/** 쓰로틀 함수 */
export const throttle = timeoutFn("throttle");

/** 디바운스 */
export const debounce = timeoutFn("debounce");

/** 노드에 사용될 새로운 아이디 생성 */
export const getRandomId = () => Math.random().toString(16).substring(2);
export const getNewId = ({ referenceId, type }) => {
  if (!type) {
    throw new Error("not found type: ");
  }
  if (!referenceId) {
    throw new Error("not found referenceId");
  }
  return type + "-" + referenceId;
};

/** 아이디당 하나만 가질 수 있는 유니크 키 생성 */
export const getUniqueKey = ({
  type,
  referenceId,
  parentType,
  parentReferenceId,
}) => {
  if (!type) {
    throw new Error("not found type: " + type);
  }
  if (!referenceId) {
    throw new Error("not found referenceId: " + referenceId);
  }
  let key = `${type}_${referenceId}`;
  if (parentType) {
    key += `_${parentType}`;
  }
  if (parentReferenceId) {
    key += `_${parentReferenceId}`;
  }
  return key;
};

/** 깊은 객체 복사 */
export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

/** 중점 */
export const getCenter = ({ x: x1, y: y1 }, { x: x2, y: y2 }, rate = 2) => {
  return {
    x: (x1 + x2) / rate,
    y: (y1 + y2) / rate,
  };
};

/** 지정된 각도에 위치하는 좌표 */
export const getPoint = (a, distance, degree) => {
  return {
    x: a.x + distance * Math.cos(degree * (Math.PI / 180)),
    y: a.y + distance * Math.sin(degree * (Math.PI / 180)),
  };
};

/** 각도 */
export const getDegree = (a, b) => {
  const dy = b.y - a.y;
  const dx = b.x - a.x;
  let theta = Math.atan2(dy, dx);
  theta *= 180 / Math.PI;
  if (theta < 0) {
    theta = 360 + theta;
  }
  return theta;
};

/** 두점 사이의 각도(radian) */
export const getAngle = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
  const rad = Math.atan2(y2 - y1, x2 - x1);
  let angle = (rad * 180) / Math.PI;
  angle = (angle + 360) % 360;
  return angle;
};

/** 템플릿 반환 */
export const template = {
  ["node"]: (data = {}) => ({
    id: null,
    referenceId: null,
    type: "",
    name: "",
    uniqueKey: "",
    x: 50,
    y: 100,
    width: 62,
    height: 62,
    iconSize: 38,
    isGrapped: false,
    group: "",
    subGroups: [],
    parentConnectableGroups: [],
    childConnectableGroups: [],
    excludeNodes: [],
    ...data,
  }),
  ["edge"]: (data = {}) => ({
    id: null,
    startId: null,
    endId: null,
    curveDistance: 50,
    curveAngle: 270,
    ...data,
  }),
  ["connector"]: (data = {}) => ({
    id: null,
    startX: 0,
    startY: 0,
    isParent: false,
    isGrapped: false,
    L: [0, 0],
    M: [0, 0],
    ...data,
  }),
  router(type, data) {
    if (!this[type]) {
      throw new Error("invalid template type: " + type);
    }
    return this[type](data);
  },
};

/** 노드의 그룹별 크기 정보 반환 */
export const nodeSize = {
  ["application"]: () => ({ width: 58, height: 58, iconSize: 40 }),
  ["ingress"]: () => ({ width: 58, height: 58, iconSize: 40 }),
  router(groupType) {
    if (!this[groupType]) {
      return { width: 50, height: 50, iconSize: 35 };
    }
    return this[groupType]();
  },
};

/** 노드의 관계성 타입 */
export const relationType = {
  ["application"]: () => "mainNode",
  ["ingress"]: () => "mainNode",
  ["database"]: () => "subNode",
  ["inMemory"]: () => "subNode",
  ["messageChannel"]: () => "subNode",
  ["pvc"]: () => "subNode",
  router(group) {
    if (!this[group]) {
      throw new Error("invalid group: " + group);
    }
    return this[group]();
  },
};

/** 서비스 정보를 노드로 변환  */
const convertNode = {
  // # main nodes
  ["microService"]: ({ id, name, namespace, framework }, index) =>
    getNewNode({
      referenceId: id,
      name,
      namespace,
      type: framework.toLowerCase(),
      x: index * 40,
      y: index * 40,
      ...gropuRules.router(framework.toLowerCase()),
      ...connectableRules.router(framework.toLowerCase()),
    }),
  ["ingress"]: ({ id, name, namespace }, index) =>
    getNewNode({
      referenceId: id,
      name,
      namespace,
      type: "ingress",
      x: index * 40,
      y: index * 40,
      ...gropuRules.router("ingress"),
      ...connectableRules.router("ingress"),
    }),
  // # sub nodes
  ["backendService"]: ({ id, name, namespace, service }, index) =>
    getNewNode({
      referenceId: id,
      name,
      namespace,
      type: service,
      x: index * 40,
      y: index * 40,
      ...gropuRules.router(service),
      ...connectableRules.router(service),
    }),
  ["pvc"]: ({ id, name, namespace }, index) =>
    getNewNode({
      referenceId: id,
      name,
      namespace,
      type: "pvc",
      x: index * 40,
      y: index * 40,
      ...gropuRules.router("pvc"),
      ...connectableRules.router("pvc"),
    }),
  router({ serviceName, data, index }) {
    if (!this[serviceName]) {
      throw new Error(
        "convertNode.router() invalid serviceName: " + serviceName,
      );
    }
    // console.log("> ", data);
    return this[serviceName](data, index);
  },
};
