<template>
  <RouterView v-if="pass" />
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    redirectPath: {
      type: String,
      default: "/",
    },
  },
  data: () => ({
    pass: false,
  }),
  computed: {
    ...mapState({
      userRole: (state) => state.accountInfo?.auth,
      hasProfileRole: (state) => !!state.project?.profileAuth?.role,
    }),
  },
  watch: {},
  mounted() {
    /** administrator가 아니면서 프로젝트 멤버가 아닌 경우 진입 불가 */
    if (this.userRole !== "ADMINISTRATOR" && !this.hasProfileRole) {
      this.$router.replace(this.redirectPath);
      return;
    }

    this.pass = true;
  },
};
</script>

<style lang="scss" scoped></style>
