<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <sup
        class="compositions__tooltip-icon rounded-full bg-gray-400 !inline-flex justify-center items-center"
        :class="[`icon-size__${iconSize}`]"
      >
        <v-icon class="text-[0.5em]" color="white" v-bind="attrs" v-on="on">
          {{ iconName }}
        </v-icon>
      </sup>
    </template>
    <div
      class="break-keep"
      :class="[width === undefined && 'w-fit max-w-sm']"
      :style="{ width: typeof width === 'number' ? `${width}px` : width }"
    >
      <slot></slot>
    </div>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    /** 넓이 */
    width: {
      type: [String, Number],
      default: undefined,
    },
    /** 아이콘의 크기("xs" | "sm" | "md" | "lg" | "xl") */
    iconSize: {
      type: String,
      default: "xs",
    },
    /** 아이콘 모양 */
    iconName: {
      type: String,
      default: "mdi-help",
    },
  },
};
</script>
<style lang="scss">
.compositions__tooltip-icon {
  &.icon-size__xs {
    width: 14px;
    height: 14px;
    & i {
      font-size: 10px;
      line-height: 0;
    }
  }
  &.icon-size__sm {
    width: 16px;
    height: 16px;
    & i {
      font-size: 12px;
      line-height: 0;
    }
  }
  &.icon-size__md {
    width: 20px;
    height: 20px;
    & i {
      font-size: 1rem;
      line-height: 0;
    }
  }
  &.icon-size__lg {
    width: 26px;
    height: 26px;
    & i {
      font-size: 1.2rem;
      line-height: 0;
    }
  }
  &.icon-size__xl {
    width: 32px;
    height: 32px;
    & i {
      font-size: 1.6rem;
      line-height: 0;
    }
  }
}
</style>
