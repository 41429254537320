
<div
  class="compositions__monaco-editor"
  :class="[
    hideDetails && 'hide-details',
    disabledInteraction && 'disabled-interaction',
  ]"
>
  <div class="rounded-md overflow-hidden relative">
    <div
      id="container"
      ref="containerRef"
      :style="{ width: newWidth, height: newHeight }"
    ></div>
  </div>

  <VInput
    class="mt-1"
    :value="value"
    :rules="rules"
    @update:error="(v) => (error = v)"
  />
</div>
