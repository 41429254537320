<template>
  <v-btn
    class="atoms__icon-button"
    icon
    :color="color"
    :x-small="size === 'xs'"
    :small="size === 'sm'"
    :large="size === 'lg'"
    :x-large="size === 'xl'"
    :elevation="elevation"
    :dark="dark || fill"
    :outlined="outlined"
    :disabled="disabled || loading"
    :loading="loading"
    :to="to"
    :class="{
      'icon-button__dark': dark || fill,
      'icon-button__primary-dark': (dark || fill) && color === 'primary',
      'icon-button__secondary-dark': (dark || fill) && color === 'secondary',
      'icon-button__accent-dark': (dark || fill) && color === 'accent',
      'icon-button__error-dark': (dark || fill) && color === 'error',
      'icon-button__info-dark': (dark || fill) && color === 'info',
      'icon-button__success-dark': (dark || fill) && color === 'success',
      'icon-button__warning-dark': (dark || fill) && color === 'warning',
      'icon-button__anchor-dark': (dark || fill) && color === 'anchor',
      [`shape-${shape}`]: true,
      ['dark-icon']: darkIcon,
      [`shadow-${shadow}`]: !!shadow,
      ['fill']: dark || fill,
    }"
    v-bind="$attrs"
    v-on="$listeners"
    @mouseover="visibleTooltip = true"
    @mouseleave="visibleTooltip = false"
  >
    <v-icon v-if="iconName" :dark="dark || fill" :size="iconSize">
      {{ iconName }}
    </v-icon>
    <slot></slot>

    <!-- tooltip -->
    <Tooltip v-if="tooltip" :position="tooltipPosition" closest=".v-btn">
      <span>{{ tooltip }}</span>
    </Tooltip>
  </v-btn>
</template>

<script>
import Tooltip from "../Tooltip/Tooltip.vue";
export default {
  props: {
    /** 버튼의 색상 */
    color: {
      type: String,
      default: "accent",
    },
    /** 아이콘명(material-icon) */
    iconName: {
      type: String,
      require: true,
    },
    /** 툴팁 */
    tooltip: {
      type: String,
    },
    /** 툴팁 위치 */
    tooltipPosition: {
      type: String,
      default: "bottom",
    },
    /** 버튼의 크기 */
    size: {
      type: String,
      default: "sm",
    },
    /** 아이콘의 형태 */
    shape: {
      type: String,
      default: "rectangle",
    },
    /** 그림자의 크기 */
    shadow: {
      type: String,
      default: "none",
    },
    /** `true`일 내부 채움 */
    dark: {
      type: Boolean,
      default: false,
    },
    /** `true`일 경우 내부 채움 */
    fill: {
      type: Boolean,
    },
    /** `true`일 경우 아이콘 색상을 검은색으로 */
    darkIcon: {
      type: Boolean,
      default: false,
    },
    /** `true`일 경우 흰배경에 아이콘에만 색상적용 */
    outlined: {
      type: Boolean,
      default: true,
    },
    /** `true`일 경우 전체를 비활성화 한다 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** `true`일 경우 로딩표시 */
    loading: {
      type: Boolean,
    },
    /** 링크 */
    to: {
      type: [String, Object],
    },
  },
  components: { Tooltip },
  data: () => ({
    /** true일 경우 툴팁 활성화 */
    visibleTooltip: true,
  }),
  computed: {
    elevation() {
      return shadowR.router(this.shadow);
    },
    iconSize() {
      return iconSizeMap.router(this.size);
    },
  },
};

const iconSizeMap = {
  ["xs"]: () => 10,
  ["sm"]: () => 18,
  ["md"]: () => 24,
  ["lg"]: () => 32,
  ["xl"]: () => 40,
  router(size) {
    if (!this[size]) {
      throw new Error("ERROR: iconSizeMap.router()  invalid `size`: " + size);
    }
    return this[size]();
  },
};

const shadowR = {
  ["none"]: () => 0,
  ["xs"]: () => 2,
  ["sm"]: () => 6,
  ["md"]: () => 8,
  ["lg"]: () => 12,
  ["xl"]: () => 16,
  ["full"]: () => 24,
  router(elevation) {
    if (!this[elevation]) {
      throw new Error(
        "ERROR: shadow.router()  invalid `elevation`: " + elevation,
      );
    }
    return this[elevation]();
  },
};
</script>

<style lang="scss">
.atoms__icon-button {
  &.shape-rectangle {
    border-radius: 4px !important;
  }
  &.shape-circle {
    border-radius: 50% !important;
  }
  &.icon-button__dark i {
    color: #fff !important;
  }
  &.dark-icon i {
    color: #333 !important;
    &:hover {
      color: #fff !important;
    }
  }

  /** ===== outlined ===== */
  &.accent--text.v-btn--outlined {
    @apply border-gray-300;
  }

  /** ===== disabled ===== */
  &[disabled],
  &.v-btn--disabled {
    @apply border-gray-300 text-gray-300;
    &.fill {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  /** ===== shadow ===== */
  &.shadow-none {
    box-shadow: none;
  }

  /** ===== dark ===== */
  &.icon-button__primary-dark {
    background-color: var(--v-primary-base) !important;
    caret-color: var(--v-primary-base) !important;
  }
  &.icon-button__secondary-dark {
    background-color: var(--v-secondary-base) !important;
    caret-color: var(--v-secondary-base) !important;
  }
  &.icon-button__accent-dark {
    background-color: var(--v-accent-base) !important;
    caret-color: var(--v-accent-base) !important;
  }
  &.icon-button__error-dark {
    background-color: var(--v-error-base) !important;
    caret-color: var(--v-error-base) !important;
  }
  &.icon-button__info-dark {
    background-color: var(--v-info-base) !important;
    caret-color: var(--v-info-base) !important;
  }
  &.icon-button__success-dark {
    background-color: var(--v-success-base) !important;
    caret-color: var(--v-success-base) !important;
  }
  &.icon-button__warning-dark {
    background-color: var(--v-warning-base) !important;
    caret-color: var(--v-warning-base) !important;
  }
  &.icon-button__anchor-dark {
    background-color: var(--v-anchor-base) !important;
    caret-color: var(--v-anchor-base) !important;
  }
}
</style>
