export default [
  {
    path: "todos/add",
    name: "todosAdd",
    component: () => import("../pages/todos/TodosAddPage"),
  },
  {
    path: "todos/:id",
    name: "todosDetail",
    component: () => import("../pages/todos/TodosDetailPage"),
  },
  {
    path: "todos/:id/edit",
    name: "todosEdit",
    component: () => import("../pages/todos/TodosEditPage"),
  },
  {
    path: "todos",
    name: "todos",
    component: () => import("../pages/todos/TodosPage"),
  },
];
