
<v-img
  v-if="iconName"
  class="atoms__image-icon"
  contain
  :height="width || height ? height : `${newSize}px`"
  :width="width || height ? width : `${newSize}px`"
  :src="newImageIconName"
  :style="{ opacity: opacity }"
/>
