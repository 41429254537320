<template>
  <div>
    <div
      class="grid grid-cols-[100px,1fr,70px,120px,120px] gap-x-2 px-4 text-sm font-semibold text-gray-600"
    >
      <div>REASON</div>
      <div>MESSAGE</div>
      <div>COUNT</div>
      <div>FIRST OCCURRED</div>
      <div>LAST OCCURRED</div>
    </div>

    <div
      ref="eventLogContainer"
      class="grid grid-flow-row gap-y-4 mt-4 overflow-auto"
      style="max-height: 75vh"
    >
      <div
        v-for="(event, i) of eventLog"
        :key="`rde-event-${i}`"
        :class="'grid grid-cols-[100px,1fr,70px,120px,120px] gap-x-2 min-h-[70px] border border-gray-200 rounded-lg px-4 py-6 text-sm text-gray-600 shadow-md'"
      >
        <div class="break-words" :title="event.reason">
          {{ event.reason }}
        </div>
        <div class="text-xs">{{ event.message }}</div>
        <div>{{ event.count }}</div>
        <div>{{ event.firstTimestamp }}</div>
        <div>{{ event.lastTimestamp }}</div>
      </div>
      <div
        v-if="eventLog.length === 0"
        class="flex justify-center items-center h-20 border rounded-lg shadow-md"
      >
        No data available
      </div>
    </div>
  </div>
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";
import { getEventsRDEState } from "@/service/apis/workspaceApis";

export default {
  props: {
    rdeId: {
      type: String,
    },
    reload: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    eventLog: [],
  }),
  watch: {
    reload: {
      immediate: true,
      handler(value) {
        if (value) this.fetchData();
      },
    },
  },
  methods: {
    fetchData() {
      if (this.rdeId) {
        CommonUIControl.ShowUIProgress();
        getEventsRDEState(this.rdeId)
          .then((res) => {
            this.eventLog = (res?.data?.data?.eventLog || []).map((event) => ({
              ...event,
              firstTimestamp: relativeTime(new Date(event.firstTimestamp)),
              lastTimestamp: relativeTime(new Date(event.lastTimestamp)),
            }));
            this.$nextTick(() => {
              // Scroll to the bottom of the event log container
              const container = this.$refs.eventLogContainer;
              container.scrollTop = container.scrollHeight;
            });
          })
          .finally(() => {
            CommonUIControl.HideUIProgress();
          });
      }
    },
  },
  async mounted() {
    this.fetchData();
  },
};

const relativeTime = (t1, t2 = Date.now()) => {
  const betweenMs = t2 - t1;
  const formatter = new Intl.RelativeTimeFormat("en");

  const betweenMinutes = Math.floor(betweenMs / 1000 / 60);
  if (betweenMinutes < 1) {
    return "just before";
  }
  if (betweenMinutes < 60) {
    return formatter.format(betweenMinutes * -1, "minutes");
  }

  const betweenHours = Math.floor(betweenMinutes / 60);
  if (betweenHours < 24) {
    return formatter.format(betweenHours * -1, "hours");
  }

  const betweenDays = Math.floor(betweenHours / 24);
  if (betweenDays < 365) {
    return formatter.format(betweenDays * -1, "days");
  }

  const betweenYears = Math.floor(betweenDays / 365);
  return formatter.format(betweenYears * -1, "years");
};
</script>

<style lang="scss"></style>
