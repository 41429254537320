
<v-radio-group
  :value="value"
  :row="direction === 'row'"
  :col="direction === 'col'"
  class="compositions__radio-group"
  v-bind="$attrs"
  v-on="$listeners"
  @change="(v) => $emit('input', v)"
>
  <v-radio
    v-for="(item, i) of items"
    :key="`radio-${item.value}-${i}`"
    :label="item.label"
    :value="item.value"
    v-bind="item"
  >
    <template #label>
      <slot name="label" :item="item" :index="i"></slot>
    </template>
  </v-radio>
</v-radio-group>
