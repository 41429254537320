
<section>
  <div class="flex flex-col">
    <div class="w-full ml-1">
      <SearchBar
        :onSearch="onSearch"
        :searchFields="searchFields"
        :isFavoriteOnly="isFavoriteOnly"
        :isFavoriteFirst="isFavoriteFirst"
      >
        <div class="flex flex-row gap-2 mr-2">
          <v-btn
            v-if="showRefreshBtn"
            size="md"
            color="primary"
            outlined
            @click="() => onClickRefresh()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <FButton
            v-if="showFavoriteBtn"
            size="md"
            fill
            color="white"
            class="text-black"
            @click="() => handleClickFavoriteFirst()"
          >
            <SvgIcon
              v-if="isFavoriteFirst"
              iconName="sortFavoriteFilled"
              color="#1A232B"
              size="md"
              :width="16"
              :height="16"
            />
            <SvgIcon
              v-else
              size="md"
              iconName="sortFavorite"
              color="#1A232B"
              :width="16"
              :height="16"
            />
            Favorite First
          </FButton>
          <FButton
            v-if="showFavoriteBtn"
            size="md"
            fill
            color="white"
            class="text-black"
            @click="() => handleClickFavoriteOnly()"
          >
            <v-icon v-if="isFavoriteOnly">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
            Favorite Only
          </FButton>
          <FButton
            v-if="showSelectBtn"
            :disabled="!anySelected"
            size="md"
            color="primary"
            @click="() => onClickShutdown()"
          >
            <v-icon>mdi-power</v-icon>
            Shutdown
          </FButton>

          <FButton
            v-if="showAddBtn"
            size="md"
            fill
            depressed
            color="primary"
            @click="() => onClickAdd()"
          >
            <v-icon>mdi-plus</v-icon>
            Add RDE
          </FButton>
        </div>
      </SearchBar>
    </div>
    <div class="p-1">
      <WebIdeTable
        ref="webIdeTable"
        :items="filterItems"
        :showFavoriteBtn="showFavoriteBtn"
        :showSelectBtn="showSelectBtn"
        :onFavorite="onFavorite"
        :viewStateRDE="viewStateRDE"
        :viewResourceRDE="viewResourceRDE"
        :showSshInfo="showSshInfo"
        @click:remove="handleRemoveItem"
        @selectedws="updateSelectedItems"
        @getting-rde-status-error="onRdeStatusError"
      />
    </div>
  </div>
  <div
    :class="{
      'app-rde-popup-detail': true,
      'app-rde-popup-detail-ative': !!selectedApp || isShowSshInfo,
      'detail-full': isExpanded,
    }"
  >
    <RDELogPopup
      v-if="!!selectedApp"
      :turnOffFunc="() => (selectedApp = null)"
      :selectedApp="selectedApp"
      :setExpand="setExpand"
      :presetTabId="tabId"
    />

    <SshInfoPopup
      v-if="isShowSshInfo"
      :turnOffFunc="() => (isShowSshInfo = false)"
      :selectedApp="selectedApp"
      :setExpand="setExpand"
      :selectedRde="selectedRde"
    />
  </div>
  <keep-alive>
    <Dialog
      v-model="showResourceRDE"
      persistent
      title="Resource Detail"
      max-width="700"
      @input="() => (selectedResourceRDE = null)"
    >
      <ResourceDetailRDE
        v-if="!!selectedResourceRDE"
        :selectedResourceRDE="selectedResourceRDE"
      />
    </Dialog>
  </keep-alive>
</section>
