
<v-row class="flex-wrap w-full m-0" style="min-height: 280px">
  <v-col lg="3" md="6" class="p-0 pr-2 m-0">
    <MonitorChart :data="finalData.cpu" type="cpu" />
  </v-col>
  <v-col lg="3" md="6" class="p-0 pr-2 m-0">
    <MonitorChart :data="finalData.memory" type="memory" />
  </v-col>
  <v-col lg="3" md="6" class="p-0 pr-2 m-0">
    <MonitorChart :data="finalData.disk" type="disk" />
  </v-col>
  <v-col lg="3" md="6" class="p-0 m-0">
    <MonitorChart :data="finalData.network" type="network" />
  </v-col>
</v-row>
