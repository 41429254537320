import { deepCopy, getNewId, template } from "@/service/topologyHelper";
import { gropuRules } from "@/service/rules/groupRules";
import { connectableRules } from "@/service/rules/connectableRules";

export default {
  namespaced: true,
  state: () => deepCopy(initialState),
  getters: {
    /** 오퍼레이션 렌더링에 사용될 노드 */
    mergerdOperationNodes({ nodes, operationNodes }) {
      const operationNodeNameMap = operationNodes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.name]: cur,
        }),
        {},
      );
      const mainNodes = nodes
        .filter(
          (a) =>
            !!operationNodeNameMap[a.name] && a.deploymentStatus === "DEPLOYED",
        )
        .map((a) => {
          const application = operationNodeNameMap[a.name];
          return {
            ...a,
            projectName: application.projectName,
            profileName: application.profileName,
            health: application.health,
            k8sNode: application.node,
          };
        });
      const mainNodeUniqueMap = mainNodes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.uniqueKey]: cur,
        }),
        {},
      );
      const ingressNodes = nodes.filter(
        (a) => a.type === "ingress" && a.deploymentStatus === "DEPLOYED",
      );
      const subNodes = nodes.filter(
        (a) =>
          a.relationType === "subNode" &&
          !!mainNodeUniqueMap[`${a.parentType}_${a.parentReferenceId}`],
      );

      return [...mainNodes, ...ingressNodes, ...subNodes];
    },
    /** 오퍼레이션에 사용될 엣지 정보 */
    operationEdges({ edges }, { mergerdOperationNodes }) {
      const uniqueKeyMap = mergerdOperationNodes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.uniqueKey]: cur,
        }),
        {},
      );
      const newEdges = edges.filter(
        (a) => a.dummy !== true && !!uniqueKeyMap[a.parentUniqueKey],
      );

      return newEdges;
    },
    /** 필터가 적용된 node 데이터 */
    nodes(state) {
      return state.nodes;
    },
    /** 노드 배열 */
    nodeArr(state) {
      return Object.values(state.nodes);
    },
    /** 엣지 배열 */
    edgeArr(state) {
      return Object.values(state.edges);
    },
    /** 어플리케이션 배포맵 */
    deploymentMap(state) {
      const { application, ingress, pvc } = state.deployments;
      const applicationMap = application.reduce(
        (acc, cur) => ({ ...acc, [cur.microservice]: cur }),
        {},
      );
      const ingressMap = ingress.reduce(
        (acc, cur) => ({ ...acc, [cur.name]: cur }),
        {},
      );
      const pvcMap = pvc.reduce(
        (acc, cur) => ({ ...acc, [cur.name]: cur }),
        {},
      );

      return {
        application: applicationMap,
        ingress: ingressMap,
        pvc: pvcMap,
      };
    },
    /** backing service 연결 정보 맵 */
    connectionMap(state) {
      const connections = state.connections ?? [];
      return connections.reduce(
        (acc, cur) => ({ ...acc, [cur.name]: cur }),
        {},
      );
    },
  },
  mutations: {
    initialize(state) {
      for (const [k, v] of Object.entries(initialState)) {
        state[k] = v;
      }
    },
    initializeNodeMap(state) {
      for (const [k, v] of Object.entries(initialState).filter(
        ([key]) => key !== "project" && key !== "profile",
      )) {
        state[k] = v;
      }
    },
    reset(state) {
      // console.log(this.getters["topology/nodes"]);
      state.config = { ...initialState.config };
      state.container = { ...initialState.container };
    },
    setProject(state, payload = null) {
      state.project = payload;
    },
    setProfile(state, payload = null) {
      state.profile = payload;
    },
    setTopologies(state, payload) {
      state.topologies = payload;
    },
    setTopology(state, payload = null) {
      state.topology = payload;
      if (!payload) {
        return;
      }
      const newInitialState = { ...initialState };
      const { config, container, nodes } = payload.data;
      state.config = config ?? newInitialState.config;
      state.container = container ?? newInitialState.container;
      state.nodes = nodes ?? newInitialState.ndoes;
      // console.log("> ", state.nodes, nodes);
    },
    setContainer(state, payload) {
      state.container = payload;
    },
    setNodes(state, payload) {
      state.nodes = payload;
    },
    addNode(state, payload) {
      const newId = getNewId({
        referenceId: payload.referenceId,
        type: payload.type,
      });

      if (state.nodes[newId]) {
        alert("노드 추가 실패: " + payload?.toString());
        return state;
      }

      const newNode = template.router("node", {
        id: newId,
        ...gropuRules.router(payload.type),
        ...connectableRules.router(payload.type),
        ...payload,
      });
      state.nodes = {
        ...state.nodes,
        [newId]: newNode,
      };
    },
    editNode(state, { id, ...data }) {
      const findedNode = state.nodes.find((a) => a.id === id);
      if (!findedNode) {
        throw new Error("Not found node : " + id);
      }
      // state.nodes[id] = {
      //   ...state.nodes[id],
      //   ...data,
      // };
      state.nodes = state.nodes.map((a) =>
        a.id === id
          ? {
              ...a,
              ...data,
            }
          : a,
      );
    },
    removeNode(state, id) {
      state.nodes = state.nodes.filter((a) => a.id !== id);
    },
    setOriginalNodes(state, originalNodes) {
      state.originalNodes = originalNodes;
    },
    setSelectedNode(state, payload) {
      state.selectedNode = payload;
    },
    initializeNodes(state) {
      state.nodes = { ...initialState.nodes };
    },
    setSelectedSubNode(state, node) {
      state.selectedSubNode = node;
    },
    setSameTypeSubNodeIds(state, ids) {
      state.sameTypeSubNodeIds = ids;
    },
    setEdges(state, edges) {
      // state.edges = [...edges];
      state.edges = edges;
    },
    editEdge(state, { id, ...edge }) {
      state.edges[id] = { ...state.edges[id], ...edge };
    },
    setOperationNodes(state, payload) {
      state.operationNodes = payload;
    },
    setK8sNodes(state, payload) {
      state.k8sNodes = payload;
    },
    setDraggingItem(state, payload) {
      state.draggingItem = payload;
    },
    setAddForm(state, payload) {
      state.addForm = payload;
    },
    // setConnectablePanel(state, payload) {
    //   state.connectablePanel = payload;
    // },
    setMicroserviceDetails(state, payload) {
      state.microserviceDetails = payload;
    },
    updateMicroserviceDetail(state, payload) {
      const { id } = payload;
      state.microserviceDetails = state.microserviceDetails.map((a) =>
        a.id === id ? payload : a,
      );
    },
    upsertMicroservice(state, microservice) {
      const prevMicroservice = state.microserviceDetails.find(
        (a) => a.id === microservice.id,
      );
      // # 동일아이디가 있을 경우 업데이트
      if (prevMicroservice) {
        state.microserviceDetails = state.microserviceDetails.map((a) =>
          a.id === microservice.id ? microservice : a,
        );
        return;
      }
      // # 동일아이디가 없을 경우 삽입
      state.microserviceDetails.push(microservice);
    },
    setScale(state, scale) {
      state.config.scale = scale;
    },
    setMagnet(state, magnet) {
      state.config.magnet = magnet;
    },
    setConfig(state, config) {
      state.config = config;
    },
    setConnector(state, connector) {
      state.connector = connector;
    },
    setSelectedEdge(state, edge) {
      const newEdge = { ...edge };
      state.selectedEdge = newEdge;
    },
    setVisibleRightPanel(state, sw) {
      state.visibleRightPanel = sw;
    },
    setVisibleRightPanelLock(state, sw) {
      state.visibleRightPanelLock = sw;
    },
    setRightPanel(state, rightPanel) {
      state.rightPanel = rightPanel;
    },
    setPvcs(state, pvcs) {
      state.pvcs = pvcs;
    },
    setDeployments(state, deployments) {
      state.deployments = deployments;
    },
    setConnections(state, connections) {
      state.connections = connections;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    /** 전체 초기화 */
    initialize: ({ commit }) => commit("initialize"),
    /** 노드 맵 정보만을 초기화 */
    initializeNodeMap: ({ commit }) => commit("initializeNodeMap"),
    /** 스케일, 마그네틱, 컨테이너 정보, 노드위치 초기화 */
    reset: ({ commit }) => commit("reset"),
    /** 프로젝트 변경 */
    setProject: ({ commit }, payload) => commit("setProject", payload),
    /** 프로파일 변경 */
    setProfile: ({ commit }, payload) => commit("setProfile", payload),
    /** 토폴로지 셋팅 */
    setTopology: ({ commit }, payload) => commit("setTopology", payload),
    /** 토폴로지 리스트 셋 */
    setTopologies: ({ commit }, topologies) =>
      commit("setTopologies", topologies),
    /** 컨테이너 셋팅 */
    setContainer: ({ commit }, container) => commit("setContainer", container),
    /** 노드 셋팅 */
    setNodes: ({ commit }, nodes) =>
      commit("setNodes", Array.isArray(nodes) ? nodes : Object.values(nodes)),
    /** 노드 추가 */
    addNode: ({ commit }, { referenceId, name, type, x = 50, y = 100 }) => {
      commit("addNode", { referenceId, name, type, x, y });
    },
    /** 노드 수정 */
    editNode: ({ commit }, { id, ...data }) =>
      commit("editNode", { id, ...data }),
    /** 노드 제거 */
    removeNode: ({ commit }, id) => commit("removeNode", id),
    /** 노드 선택 */
    selectNode: ({ commit }, node) => commit("setSelectedNode", node),
    /** 노드 선택 해제 */
    deselectNode: ({ commit }) => commit("setSelectedNode", null),
    /** 노드 초기화 */
    initializeNodes: ({ commit }) => commit("initializeNodes"),
    /** 리소스 선택 */
    setSelectedSubNode: ({ commit }, node) =>
      commit("setSelectedSubNode", node),
    /** 같인 타입의 서브 노드 아이디 셋 */
    setSameTypeSubNodeIds: ({ commit }, payload) =>
      commit("setSameTypeSubNodeIds", payload),
    /** 원본 노드(중복되는 노드정보가 없는 노드) */
    setOriginalNodes: ({ commit }, originalNodes) => {
      commit("setOriginalNodes", originalNodes);
    },
    /** 엣지 셋팅 */
    setEdges: ({ commit }, edges) =>
      commit("setEdges", Array.isArray(edges) ? edges : Object.values(edges)),
    /** 엣지 정보 수정 */
    editEdge: ({ commit }, { id, ...data }) => {
      if (!id) {
        throw new Error("not defined edge id: " + id);
      }
      commit("editEdge", { id, ...data });
    },
    /** 오퍼레이션용 노드 */
    setOperationNodes({ commit }, payload) {
      commit("setOperationNodes", payload);
    },
    /** k8s 노드 셋 */
    setK8sNodes({ commit }, payload) {
      commit("setK8sNodes", payload);
    },
    /** 토폴로지 구성을 위한 아이템 셋팅 */
    setDraggingItem: ({ commit }, { id, name }) =>
      commit("setDraggingItem", { id, name }),
    /** add form 활성화 */
    showAddForm: ({ commit }, { id, type, x, y }) =>
      commit("setAddForm", { id, type, x, y }),
    /** add form 비활성화 */
    hideAddForm: ({ commit }) => commit("setAddForm", null),
    /** 마이크로 서비스 상세 정보 셋팅 */
    setMicroserviceDetails({ commit }, payload) {
      commit("setMicroserviceDetails", payload);
    },
    /** 마이크로 서비스 상세 정보 업데이트 */
    updateMicroserviceDetail({ commit }, microservice) {
      commit("updateMicroserviceDetail", microservice);
    },
    /** 동일아이디의 마이크로 서비스가 없을 경우는 삽입하고 있을 경우는 업데이트 */
    upsertMicroservice({ commit }, microservice) {
      commit("upsertMicroservice", microservice);
    },
    /** pvs 리스트 */
    setPvcs({ commit }, pvcs) {
      commit("setPvcs", pvcs);
    },
    /** scale 변경 */
    setScale({ commit }, scale = 0) {
      commit("setScale", scale);
    },
    /** 위치 보정 활성화 유무 */
    setMagnet({ commit }, magnet) {
      if (typeof magnet !== "boolean") {
        throw new Error("invalid magnet type: " + magnet);
      }
      commit("setMagnet", magnet);
    },
    /** 컨피그 셋팅 */
    setConfig({ commit }, config) {
      commit("setConfig", config);
    },
    /** 엣지 선택 */
    selectEdge({ commit }, edge) {
      commit("setSelectedEdge", edge);
    },
    /** 엣지 선택해제 */
    deselectEdge({ commit }) {
      commit("setSelectedEdge", null);
    },
    /** ===== 연결선 ===== */
    setConnector({ commit }, connector) {
      commit("setConnector", connector);
    },
    /** ===== right panel ===== */
    /** 우측 패널 활성화 */
    setVisibleRightPanel: ({ commit }, sw) => {
      commit("setVisibleRightPanel", sw);
    },
    /** 우측 패널 활성화 */
    setVisibleRightPanelLock: ({ commit }, sw) => {
      commit("setVisibleRightPanelLock", sw);
    },
    /** 우측 폼 데이터 셋팅 */
    setRightPanel: ({ commit }, rightPanel) => {
      commit("setRightPanel", rightPanel);
    },
    /** ===== 배포 정보 ===== */
    /** 배포 정보 */
    setDeployments({ commit }, deployments) {
      commit("setDeployments", deployments);
    },
    /** ===== 연결 상태 정보 ===== */
    /** 연결 정보 */
    setConnections({ commit }, connections) {
      commit("setConnections", connections);
    },
    /** ===== 로딩 ===== */
    /** 로딩 활성화 */
    setLoading({ commit }, loading) {
      commit("setLoading", loading);
    },
  },
};

/** 템플릿 */
export const initialState = Object.freeze({
  microserviceDetails: null, // 마이크로 서비스 상세 정보들
  pvcs: null, // pvc 기본 정보
  config: {
    scale: 1,
    magnet: false,
  },
  deployments: {}, // 배포 정보
  connections: [], // 백킹 서비스 연결 정보
  project: null, // 선택된 프로젝트 정보
  profile: null, // 선택된 프로파일 정보
  topologies: [], // 토폴로지 목록
  topology: null, // 선택된 토폴로지 정보
  addForm: null, // 노드 등록 모달. "ingress"
  draggingItem: null, // 드래그중인 토폴로지 구성 아이템
  selectedNode: null, // 클릭으로 선택되어있는 노드
  selectedEdge: null, // 선택된 엣지
  selectedSubNode: null, // {node} 선택된 리소스들
  // 좌표의 기준이 될 컨테이너
  container: {
    x: 0,
    y: 0,
  },
  /** 연결선 */
  connector: {
    ...template.router("connector"),
  },
  visibleRightPanel: false, // `true`일 경우 우측 패널 활성화
  visibleRightPanelLock: false, // `true`일 경우 지정된 버튼을 이용해 닫히기를 제한
  rightPanel: null, // 우측 패널 정보({title!, contentType!, fetchingType, data})
  originalNodes: [], // 노드의 원본 정보
  nodes: [], // 노드 데이터
  edges: [], // 엣지 데이터
  sameTypeSubNodeIds: [], // 같은 타입의 서브노드의 아이디
  operationNodes: [], // 오퍼레이션에서 사용되는 노드 정보
  k8sNodes: [], // k8s의 노드 정보
  loading: false,
});
