import httpClient from "./httpClient";
import API_USER from "./apis/user";
import API_VOLUME from "./apis/persistentvolume";
import API_APPLICATIONS from "./apis/applications";
import API_PIPELINE from "./apis/pipeline";
import API_CLUSTER from "./apis/cluster";
import API_PROJECTMEMBER from "./apis/projectMember";
import API_PROFILE from "./apis/profile";
import API_GROUP from "./apis/group";
import API_PROJECTAUTH from "./apis/projectAuth";
import API_DASHBOARD from "./apis/dashboard";
import qs from "qs";

const PROFILE_LABEL_KEY = "amdp.io/profile";

export default {
  //백엔드(msa-dev-platform-backend) 마이크로 서비스

  /* Common APIs */
  // getClusters() {
  //   return new Promise((resolve,reject)=>{
  //     httpClient.get('/v1/common/cluster').then(res => {
  //       resolve(res.data.result)
  //     }).catch(err => {
  //       reject(err.response || err);
  //     })
  //   })
  // },

  /* Project APIs */
  checkProjectName(projectName, profileName) {
    const pjt = encodeURIComponent(projectName);
    const pfl = encodeURIComponent(profileName);
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/common/check/project?projectName=${pjt}&profileName=${pfl}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  checkProfileName(projectName, profileName) {
    projectName = encodeURIComponent(projectName);
    profileName = encodeURIComponent(profileName);
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/common/check/profile/${profileName}?projectName=${projectName}`,
        )
        .then((resp) => {
          if (resp.data && resp.data.success) {
            resolve(resp.data.result);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  createProject(meta) {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/v1/project", meta)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  updateProject(projectId, meta) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/${projectId}`, meta)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getProjects() {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/v1/project/")
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getProject(projectId) {
    let url = `/v1/project/${projectId}`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  /** 프로젝트 서비스 타입 **/
  getProjectServiceTypes(projectId) {
    const url = `/v1/project/${projectId}/serviceTypes`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getAllProjectServiceTypes() {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/v1/project/serviceTypes")
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  deleteProject(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/project/${projectId}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deleteProfile(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  downloadProject(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/download`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  releaseProject(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/release`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  /* 프로젝트-프로파일 */
  getProjectProfileList(projectId, projectName) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`v1/project/${projectId}/profile/list?name=${projectName}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getProjectProfile(projectId, profileId) {
    let url = `/v1/project/${projectId}/profile`;
    if (profileId) {
      url += `/${profileId}`;
    }

    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getProfileByProjects(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/dashboard/filter/profiles`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          if (res?.data) {
            resolve(res?.data);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  createProjectProfile(projectId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/profile`, data)
        .then((resp) => {
          if (resp && resp.data && resp.data.success) {
            resolve(resp.data.result);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  updateProjectProfile(projectId, profileId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/${projectId}/profile/${profileId}`, data)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  //deprecated
  // copyProjectToNewProfile(projectId, data) {
  //   return new Promise((resolve, reject) => {
  //     httpClient.post(`/v1/project/${projectId}/copy`, data).then(res => {
  //       if(res && res.data && res.data.success) {
  //         resolve(res.data.result)
  //       } else {
  //         reject(res)
  //       }
  //     }).catch(err =>{
  //       reject(err.response)
  //     })
  //   })
  // },
  //SKCPL-691 복제는 프로파일 단위로 수행한다
  copyProfileV2(parentProfileId, data) {
    const payload = {
      parent_profile_id: parentProfileId,
      ...data,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/common/copy/profile`, payload)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  copyProfileMicroserviceV2(profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/common/copy/profile/${profileId}/microservice`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  updateMicroserviceUpdateStatus(projectId, profileId, mid, status, namespace) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${mid}/copystatus?` +
            `status=${status}&namespace=${namespace}`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  /* 프로젝트 소속 마이크로서비스 */
  checkMicroserviceName(projectName, profileName, microserviceName) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/common/check/microservice/${microserviceName}?projectName=${projectName}&profileName=${profileName}`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getProjectMicroservices(projectId, profileId) {
    let url = `/v1/project/${projectId}/microservice`;
    if (profileId) {
      url = `/v1/project/${projectId}/profile/${profileId}/microservice`;
    }

    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getProjectMicroservice(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  addProjectMicroservice(projectId, profileId, meta) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/project/${projectId}/profile/${profileId}/microservice`,
          meta,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  updateProjectMicroservice(projectId, profileId, microserviceId, meta) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}`,
          meta,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deleteProjectMicroservice(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getMicroserviceSchema(projectId, propertyType, workloadType) {
    let url = `/v1/project/${projectId}/property`;
    if (propertyType) {
      url += `?type=${propertyType}&workloadType=${workloadType}`;
    }
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getMicroserviceViewDetail(property) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/common/property/yaml`, property, { timeout: 10 * 1000 })
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getMicroserviceViewDetail2(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/preview`,
          { timeout: 10 * 1000 },
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  downloadMicroservice(pid, mid) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${pid}/microservice/${mid}/download`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  releaseMicroservice(pid, mid) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${pid}/microservice/${mid}/release`, {
          timeout: 60 * 1000,
        })
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getMicroservices() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/microservice`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  restartApplication(projectID, profileID, appID) {
    return new Promise((resolve, reject) => {
      // POST
      httpClient
        .post(
          `/v1/project/${projectID}/profile/${profileID}/microservice/${appID}/restart`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getFrameworkVersions() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/common/framework/versions?distinct=true`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  /* 프로젝트 활용 백엔드 서비스 */
  getProjectBackendService(projectId, profileId) {
    return new Promise((resolve, reject) => {
      let url = `/v1/project/${projectId}`;
      if (profileId) {
        url += `/profile/${profileId}/backendservice`;
      } else {
        url += `/backendservice`;
      }

      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getProjectBackendServiceByMemberId() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/backendservice`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  addProjectBackend(projectId, profileId, meta) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/project/${projectId}/profile/${profileId}/backendservice`,
          meta,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deleteProjectBackends(projectId, profileId, backendId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          `/v1/project/${projectId}/profile/${profileId}/backendservice/${backendId}`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  modifyProjectBackend(backendId, backendInfo) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/backendservice/${backendId}`, backendInfo)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  /* 프로젝트 소속 클러스터 목록 */
  getProjectCluster(projectId) {
    return new Promise((resolve) => {
      resolve([
        {
          id: "311",
          project_id: projectId,
          name: "eks-sample1-cluster",
          url: "https://somewhere",
          cad: "LS0tsalkwejrlkjadflkajsdlfkjasdf",
          account: "hello@world.com",
          token: "eyjhalskzqiuohflkjsdflkjwelejklrwer",
          microservices: [{ id: "123asd", name: "awesome-account" }],
        },
      ]);
    });
  },

  /* Ingress API */
  getIngresses(projectId, profileId) {
    return new Promise((resolve, reject) => {
      let url = "/v1/project";
      if (!projectId) {
        url += "/ingress";
      } else {
        if (profileId) {
          url += `/${projectId}/profile/${profileId}/ingress`;
        } else {
          url += `/${projectId}/ingress`;
        }
      }

      httpClient
        .get(url)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
          // resolve(ingressListSample.result)
        });
    });
  },
  getIngress(projectId, profileId, ingressId) {
    return new Promise((resolve, reject) => {
      if (!projectId || !ingressId) {
        reject("no project id or ingress id provided");
        return;
      }

      let urlString = `/v1/project/${projectId}`;
      if (profileId) {
        urlString = `${urlString}/profile/${profileId}/ingress/${ingressId}`;
      } else {
        urlString = `${urlString}/ingress/${ingressId}`;
      }

      httpClient
        .get(urlString)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err || "error.");
        });
    });
  },

  getProjectIngress(projectId) {
    return new Promise((resolve, reject) => {
      if (!projectId) {
        reject("no project id provided");
        return;
      }
      let urlString = `/v1/project/${projectId}/ingress`;
      httpClient
        .get(urlString)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err || "error.");
        });
    });
  },
  createIngress(projectId, profileId, payload) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/profile/${profileId}/ingress`, payload)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  modifyIngress(projectId, profileId, ingressId, detail) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/ingress/${ingressId}`,
          detail,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deleteIngress(projectId, profileId, ingressId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          `/v1/project/${projectId}/profile/${profileId}/ingress/${ingressId}`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  checkBackendName(backendName) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/common/check/backend/${backendName}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  /* 마이크로서비스의 CICD 속성 가져오기 */
  getMicroserviceCicdProperty(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/cicd`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  updateMicroserviceCicdProperty(projectId, profileId, microserviceId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/cicd`,
          data,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getRelatedCICDImages(
    projectId,
    profileId,
    microserviceId,
    imageProject,
    imageName,
  ) {
    const params = {};
    if (imageProject) {
      params.image_project = imageProject;
    }
    if (imageName) {
      params.image_name = imageName;
    }
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/cicd/images`,
          { params },
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getDeploymentByLabel(namespace, project_name, profile_name, cluster_id) {
    let url =
      `/proxy/${cluster_id}` +
      `/v1/namespace/${namespace}/deployment?label=${PROFILE_LABEL_KEY}=${project_name}.${profile_name}`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getBatchByLabel(namespace, projectName, profileName, ClusterId) {
    let url =
      `/proxy/${ClusterId}` +
      `/v1/namespace/${namespace}/job?label=${PROFILE_LABEL_KEY}=${projectName}.${profileName}`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getServiceByLabel(namespace, project_name, profile_name, cluster_id) {
    let url =
      `/proxy/` +
      cluster_id +
      `/v1/namespace/${namespace}/service?label=${PROFILE_LABEL_KEY}=${project_name}.${profile_name}`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getPodByLabel(namespace, project_name, profile_name, cluster_id) {
    let url =
      `/proxy/` +
      cluster_id +
      `/v1/namespace/${namespace}/pod?label=${PROFILE_LABEL_KEY}=${project_name}.${profile_name}`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  verifyProjectBackendOracleDB(param, cluster_id) {
    let reqData = {
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/proxy/` + cluster_id + `/v1/backend/oracle`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(0);
          reject(err.response || err);
        });
    });
  },
  verifyProjectBackendMariaDB(param, cluster_id) {
    let reqData = {
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/proxy/` + cluster_id + `/v1/backend/mariadb`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(0);
          reject(err.response || err);
        });
    });
  },
  verifyProjectBackendRedis(param, cluster_id) {
    let reqData = {
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/proxy/` + cluster_id + `/v1/backend/redis`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(0);
          reject(err.response || err);
        });
    });
  },
  verifyProjectBackendRabbitMQ(param, cluster_id) {
    let reqData = {
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/proxy/` + cluster_id + `/v1/backend/rabbitmq`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(0);
          reject(err.response || err);
        });
    });
  },
  verifyProjectBackendKafka(param, cluster_id) {
    let reqData = {
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/proxy/` + cluster_id + `/v1/backend/kafka`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(0);
          reject(err.response || err);
        });
    });
  },
  verifyProjectBackendMongodb(param, cluster_id) {
    let reqData = {
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/proxy/` + cluster_id + `/v1/backend/mongodb`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(0);
          reject(err.response || err);
        });
    });
  },
  verifyMicroserviceGitRepository(projectId, profileId, microserviceId, param) {
    let reqData = {
      projectId,
      profileId,
      microserviceId,
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/verify/microservice/repository`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  verifyProjectGitRepository(projectId, profileId, param) {
    let reqData = {
      projectId,
      profileId,
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/verify/project/repository`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  /** 이미지 레지스트리 연결 상태 검증(수정) */
  verifyImageRegistryConnection(
    {
      projectId,
      imageRegistryId,
      image_registry_credential,
      image_registry_url,
      image_registry_user,
    },
    options,
  ) {
    return httpClient.post(
      `/v1/pipeline/verify/project/registry`,
      {
        projectId,
        imageRegistryId,
        image_registry_credential,
        image_registry_url,
        image_registry_user,
      },
      options,
    );
  },
  /** 이미지 레지스트리 연결 상태 검증 시 url, name, credential 필요 */
  verifyImageRegistryConnectionWithDetail(
    {
      projectId,
      image_registry_url,
      image_registry_user,
      image_registry_credential,
    },
    options,
  ) {
    return httpClient.post(
      `/v1/pipeline/verify/project/registry`,
      {
        projectId,
        image_registry_url,
        image_registry_user,
        image_registry_credential,
      },
      options,
    );
  },
  verifyProjectImageRegistry(projectId, profileId, param) {
    let reqData = {
      projectId,
      profileId,
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/verify/project/registry`, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getStorageClasses(cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/storageclass`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getLoggingUrl() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/url/logging`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getMonitoringUrl() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/url/monitoring`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getIngressByLabel(namespace, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/namespace/${namespace}/ingress`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deployIngress(namespace, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/ingress`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  applyDestRule(namespace, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/destinationrule`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  applyVirtualServiceForShiftingType(namespace, reqData, cluster_id) {
    console.log("reqData ", reqData);
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/virtualservice/shifting`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  applyVirtualServiceForRequestType(namespace, reqData, cluster_id) {
    console.log("reqData ", reqData);
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/virtualservice/request`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  applyVirtualServiceForMirroringType(namespace, reqData, cluster_id) {
    console.log("reqData ", reqData);
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/virtualservice/mirror`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  removeVirtualservice(namespace, name, cluster_id, reqData) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/virtualservice/${name}/delete`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  removeDestinationrule(namespace, name, cluster_id, reqData) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/destinationrule/${name}/delete`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  unDeployIngress(namespace, name, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/ingress/${name}/delete`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  updateIngress(namespace, name, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/ingress/${name}`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  saveIngressDeploymentStatus(
    projectId,
    profileId,
    ingressId,
    deploymentStatus,
  ) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/ingress/${ingressId}?deployed=${deploymentStatus}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  /* Ingress API */
  getSecretList(namespace, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/namespace/${namespace}/secret`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getSecret(namespace, name, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/secret/${name}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getConfigmap(namespace, name, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/configmap/${name}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getConfigmapList(namespace, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/namespace/${namespace}/configmap`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getDeployedSecret(namespace, name, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/secret/${name}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getKibanaIndexPatternId(param) {
    let reqData = {
      ...param,
    };
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/logging/indexpattern`, reqData)
        .then((res) => {
          resolve(res.data.saved_objects[0].id);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deployPVC(namespace, reqData, cluster_id, isStatic) {
    const suffixPath = isStatic ? "/static" : "";
    const url = `/proxy/${cluster_id}/v1/namespace/${namespace}/pvc${suffixPath}`;
    return new Promise((resolve, reject) => {
      httpClient
        .post(url, reqData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  undeployPVC(namespace, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` +
            cluster_id +
            `/v1/namespace/${namespace}/pvc/${reqData.name}`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getDeployedPvc(namespace, name, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/namespace/${namespace}/pvc/${name}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getK8sPvcList(namespace, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/namespace/${namespace}/pvc`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getPvList(cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/pv`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getNamespaces(cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/proxy/` + cluster_id + `/v1/namespace`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getSecrets(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}/secret`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getFileEnvSecrets(projectId, profileId, namespace) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/secret?namespace=${namespace}&isImageSecret=false`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getImageSecrets(projectId, profileId, namespace) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/secret?namespace=${namespace}&isImageSecret=true`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getConfigmaps(projectId, profileId, namespace) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/configmap?namespace=${namespace}`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getPvcByNamespace(projectId, profileId, namespace) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/pvc?namespace=${namespace}`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getConfigmapDetail(projectId, profileId, configmapId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/configmap/${configmapId}`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  createConfigmap(projectId, profileId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/profile/${profileId}/configmap`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getSecretDetail(projectId, profileId, secretId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}/secret/${secretId}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getImageRegistryList(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/imageregistry`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getImageRegistryDetail(projectId, imageregistryId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/imageregistry/${imageregistryId}/internal?imageregistryId=${imageregistryId}`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  createSecret(projectId, profileId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/profile/${profileId}/secret`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  updateSecret(projectId, profileId, secretId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/secret/${secretId}`,
          data,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  deleteSecret(projectId, profileId, secretId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          `/v1/project/${projectId}/profile/${profileId}/secret/${secretId}`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  getHpaByLabel(namespace, cluster_id) {
    let url = `/proxy/` + cluster_id + `/v1/namespace/${namespace}/hpa`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getAutoScaling(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/scaling`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  updateAutoScaling(projectId, profileId, microserviceId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/scaling`,
          data,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  addAutoScaling(projectId, profileId, microserviceId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/scaling`,
          data,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deleteAutoScaling(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/scaling`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  deploySecret(namespace, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/secret`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  replaceSecret(namespace, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/secret`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  deployConfigmap(namespace, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/configmap`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  replaceConfigmap(namespace, reqData, cluster_id) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/proxy/` + cluster_id + `/v1/namespace/${namespace}/configmap`,
          reqData,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  createGit(projectId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/repository`, data)
        .then((res) => {
          resolve(res.data.result && res.data.success);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  modifyGit(projectId, data) {
    let repository_id = data.repository_id;
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/${projectId}/repository/${repository_id}`, data)
        .then((res) => {
          resolve(res.data.result && res.data.success);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getGits(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/repository`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getProfileGits(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/repository?resourceType=PROFILE`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getGit(projectId, repositoryId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/repository/${repositoryId}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getProfileGit(projectId, repositoryId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/repository/${repositoryId}?resourceType=PROFILE`,
        )
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getBranches(projectId, repositoryId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/repository/${repositoryId}/branch`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  deleteGits(projectId, gitId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/project/${projectId}/repository/${gitId}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  verifyGit(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/validation/git/status`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  createK8SAgentClusterInfo(param) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/proxy/1/v1/namespace/cluster`, param)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },
  updateK8SAgentClusterInfo(clusterID, param) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/proxy/1/v1/namespace/cluster/${clusterID}`, param)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  deleteK8SAgentClusterInfo(clusterID) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/proxy/1/v1/namespace/cluster/${clusterID}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getMicroserviceResources(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/resource`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getProjectImageregistries(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/imageregistry/simple`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getProfileTypes() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/admin/profiletype`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  createImageregistry(projectId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/imageregistry`, data)
        .then((res) => {
          resolve(res.data.result && res.data.success);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  deleteImageregistries(projectId, ids) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/project/${projectId}/imageregistry/${ids}`)
        .then((res) => {
          if (res && res.data && res.data.success) {
            resolve(res.data.result);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          console.log(" err ", err);
          reject(err.response || err);
        });
    });
  },

  modifyImageregistry(projectId, data) {
    let imageregistry_id = data.imageregistry_id;
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/${projectId}/imageregistry/${imageregistry_id}`, data)
        .then((res) => {
          resolve(res && res.data.success);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  createAddonServiceType(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/addonservice/type`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getAddonServiceTypeList() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/addonservice/type`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getAddonServiceList() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/addonservice`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getAddonServiceInitList() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/addonservice/init`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getAddonServiceDetail(id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/addonservice/${id}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  createAddonService(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/addonservice`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  updateAddonService(id, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/addonservice/${id}`, data)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  deleteAddonService(addonServiceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/addonservice/${addonServiceId}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getProfileAddonServices(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}/addonservices`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getProjectAddonServiceDetail(
    projectId,
    profileId,
    addonServiceId,
    predefined_envs,
  ) {
    return new Promise((resolve, reject) => {
      let url = `/v1/project/${projectId}/profile/${profileId}/addonservices/${addonServiceId}`;
      if (predefined_envs) {
        url += `?predefined_envs=${predefined_envs}`;
      }
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  getMicroserviceAddonServiceDetail(projectId, profileId, microserviceId) {
    return new Promise((resolve, reject) => {
      let url = `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/addonservices`;
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  createProfileAddonService(projectId, profileId, addonServiceId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/project/${projectId}/profile/${profileId}/addonservices/${addonServiceId}`,
          data,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  updateProfileAddonService(projectId, profileId, addonServiceId, data) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(
          `/v1/project/${projectId}/profile/${profileId}/addonservices/${addonServiceId}`,
          data,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  deleteProfileAddonService(projectId, profileId, addonServiceIds) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          `/v1/project/${projectId}/profile/${profileId}/addonservices/${addonServiceIds}`,
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response || err);
        });
    });
  },

  /** 마이크로 서비스 등록(토폴로지용 api) */
  addMicroserviceWithTopology: ({ projectId, profileId }, data) =>
    httpClient.post(
      `/v1/project/${projectId}/profile/${profileId}/microservice/topology`,
      data,
    ),
  /** 마이크로 서비스 수정(토폴로지용 api) */
  editMicroserviceWithTopology: (
    { projectId, profileId, microserviceId },
    data,
  ) =>
    httpClient.put(
      `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/topology`,
      data,
    ),

  ...API_USER,
  ...API_VOLUME,
  ...API_APPLICATIONS,
  ...API_PIPELINE,
  ...API_CLUSTER,
  ...API_PROJECTMEMBER,
  ...API_PROFILE,
  ...API_GROUP,
  ...API_PROJECTAUTH,
  ...API_DASHBOARD,
};
