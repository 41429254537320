import ProjectAuthProvider from "@/components/common/ProjectAuthProvider/ProjectAuthProvider";
import ProfileGuard from "@/components/common/ProfileGuard/ProfileGuard";

export default [
  {
    path: "/projects",
    component: ProjectAuthProvider,
    children: [
      {
        path: "",
        component: ProfileGuard,
        children: [
          /** ===== 프로파일 ===== */
          {
            path: ":id",
            name: "ProfileList",
            component: () =>
              import("../pages/main/modernframework/Profile/ProfileList.vue"),
          },
          {
            path: ":id/profiles/create",
            name: "ProfileCreate",
            component: () =>
              import("../pages/main/modernframework/Profile/ProfileCreate.vue"),
          },
          {
            path: ":id/profiles/:profileId",
            name: "ProfileDetail",
            component: () =>
              import(
                "../pages/main/modernframework/Profile/ProfileDetailPage.vue"
              ),
          },
          {
            path: ":id/profiles/:profileId/modify",
            name: "ProfileModify",
            component: () =>
              import("../pages/main/modernframework/Profile/ProfileModify.vue"),
          },
          /** 프로파일 복제 */
          {
            path: ":projectId/profiles/:profileId/copy",
            name: "ProfileCopy",
            component: () =>
              import("../pages/main/modernframework/Profile/ProfileCopy.vue"),
          },
          /** 배포 상태 정보 */
          {
            path: ":projectId/profiles/:profileId/:microserviceId/deployment",
            name: "DeploymentDetail",
            component: () =>
              import(
                "../pages/main/modernframework/Project/DeploymentDetail.vue"
              ),
          },
        ],
      },
    ],
  },
];
