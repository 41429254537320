import client from "../service/httpClient";
import { HttpClient } from "../service/httpClient";

export default {
  async install(Vue) {
    //토큰 발급 안정화된 이후에 설정할 수 있게 약간씩 딜레이 제공
    let count = 0;
    setTimeout(async () => {
      if (HttpClient.getToken()) {
        Vue.prototype.$pipeline = await fetchPipelineProperty();
      } else {
        const initialTimer = setInterval(async () => {
          if (HttpClient.getToken()) {
            Vue.prototype.$pipeline = await fetchPipelineProperty();
            clearInterval(initialTimer);
          }
          count++;
          if (count > 5) {
            clearInterval(initialTimer);
          }
        }, 1000);
      }
    }, 100);

    setInterval(async () => {
      Vue.prototype.$pipeline = await fetchPipelineProperty();
    }, 1000 * 60 * 5);
  },
};

const fetchPipelineProperty = async () => {
  const result = await new Promise((resolve) => {
    client
      .get("/v1/pipeline/admin/systemproperty")
      .then((res) => resolve(res?.data?.result))
      .catch(() => resolve(null));
  });

  return result;
};
