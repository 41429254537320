
<div class="p-0 m-0">
  <div class="border p-2 mb-2 rounded-md border-gray-300">
    <p class="label-mini-section">Register Installation Packages</p>
    <div class="grid grid-cols-4 mt-0">
      <v-autocomplete
        v-model="values.installPackages.javaVersion"
        :items="javaVersions"
        label="Java Version"
        small-chips
        placeholder="Select Java Version"
        class="app-g-autocomplete-v2"
        outlined
        dense
      />
      <v-autocomplete
        v-model="values.installPackages.nodeVersion"
        :items="nodeVersions"
        label="Node Version"
        small-chips
        placeholder="Select Node Version"
        class="app-g-autocomplete-v2"
        outlined
        dense
      />
      <v-autocomplete
        v-model="values.installPackages.aptPackages"
        :items="linuxPackages"
        label="Linux Packages"
        small-chips
        placeholder="Select Linux Packages"
        class="app-g-autocomplete-v2"
        clearable
        deletable-chips
        outlined
        dense
        multiple
      />
      <v-autocomplete
        v-model="values.installPackages.pipPackages"
        :items="pythonPackages"
        label="Python Packages"
        small-chips
        placeholder="Select Python Packages"
        class="app-g-autocomplete-v2"
        clearable
        deletable-chips
        outlined
        dense
        multiple
      />
    </div>
  </div>
  <div class="border p-2 mb-2 rounded-md border-gray-300">
    <p class="label-mini-section">Register Pre-start scripts</p>
    <v-autocomplete
      v-model="values.installPackages.preflightConfig.name"
      :items="preflightConfigResourceNames"
      small-chips
      placeholder="Resource Name"
      label="Select Resource Name"
      class="app-g-autocomplete-v2 pt-2 v-auto-help-message"
      outlined
      dense
      :messages="
        values.installPackages.preflightConfig.configType
          ? `Type: ${values.installPackages.preflightConfig.configType}`
          : ''
      "
      @change="onChangePreflightResourceName"
    />
  </div>
  <div class="border p-2 mb-2 rounded-md border-gray-300">
    <p class="label-mini-section">File and Environment Value Mount</p>
    <FileEnvironment
      :mountedConfigList="values.installPackages.mountedConfigList"
      :installPackages="installPackages"
      :resourceNames="resourceNames"
      @change="setValue('installPackages.mountedConfigList', $event)"
    />
  </div>

  <div class="border p-2 mb-2 rounded-md border-gray-300">
    <p class="label-mini-section">Register Shared Storage</p>
    <StorageShare
      :mountedFSList="values.installPackages.mountedFSList"
      :installPackages="installPackages"
      :validate="validate"
      @change="setValue('installPackages.mountedFSList', $event)"
    />
  </div>
</div>
