
<label
  class="text-sm block relative"
  :class="{
    required: required,
    'direction-row': direction === 'row',
    'font-semibold': bold === true,
  }"
>
  <div
    v-if="label"
    class="break-all text-gray-600"
    :class="{ 'label-row': direction === 'row' }"
    :style="{ width: direction === 'row' ? `${labelWidth}px` : '100%' }"
  >
    {{ label }}
  </div>
  <slot></slot>

  <!-- tooltip -->
  <DescriptionTooltip v-if="tooltip" :tooltipWidth="tooltipWidth">
    {{ tooltip }}
  </DescriptionTooltip>
</label>
