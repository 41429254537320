import Keycloak from "@dsb-norge/vue-keycloak-js";
import { HttpClient } from "@/service/httpClient";

export const keycloakOptions = ({ router, url, realm, clientId, onReady }) => ({
  init: {
    onLoad: "login-required",
    checkLoginIframe: false,
  },
  config: {
    url,
    realm,
    clientId,
  },
  onReady: (keycloak) => {
    // console.log("> on ready: ", keycloak);

    // # 토큰이 없을 경우 로드안함
    if (!keycloak.authenticated) {
      return;
    }

    HttpClient.setToken(keycloak.token);

    if (typeof onReady === "function") {
      onReady();
    }
  },
  onInitError: (error, err) => {
    // console.log("> init error");
    throw new Error(error.message || err.error);
  },
  onAuthRefreshSuccess: (keycloak) => {
    HttpClient.setToken(keycloak.token);
  },
  onAuthRefreshError: () => {
    HttpClient.removeToken();
    router.app.$keycloak.logoutFn();
  },
});

export default Keycloak;
