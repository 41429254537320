
<date-range-picker
  v-model="dateRange"
  :opens="opens"
  :timePicker="timePicker"
  :timePicker24Hour="timePicker24Hour"
  :time-picker-increment="1"
  :showDropdowns="showDropdowns"
  class="custom-date-range-picker"
  :ranges="ranges"
  @toggle="toggleDateTimePicker"
  @update="updateValues"
>
  <template #input="picker">
    {{ picker | date(viewFormat) }}
  </template>
</date-range-picker>
