<template>
  <v-chip
    class="atoms__chip !text-gray-600"
    :color="color"
    :x-small="size === 'xs'"
    :small="size === 'sm'"
    :large="size === 'lg'"
    :x-large="size === 'xl'"
    :close="close"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  props: {
    /** 버튼의 색상 */
    color: {
      type: String,
      default: undefined,
    },
    /** 버튼의 크기 */
    size: {
      type: String, // "xs", "sm", "md", "lg", "xl"
      default: "sm",
    },
    /** true일 경우 닫기 아이콘 활성화 및 `click:close` 이벤트 적용 */
    close: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.atoms__chip {
  & .v-chip__content {
    height: initial;
  }
  &.error,
  &.success {
    color: #fff !important;
  }
}
</style>
