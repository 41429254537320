import { request } from "@/service/request";

/**
 * 애플리케이션의 이벤트 정보를 가져온다
 * @param {{projectName:string,profileName:string,applicationName:string}} param0
 * @returns
 */
export const getApplicationEvents = ({
  projectName,
  profileName,
  applicationName,
}) =>
  request({
    method: "get",
    uri: `/v1/topology/applications/${applicationName}.${profileName}.${projectName}/events`,
  });

/**
 * 토폴로지 등록
 * @param {number} projectId (required)프로젝트 아이디
 * @param {number} profileid (required)프로파일 아이디
 * @param {object} data (required)저장할 json data
 * @return {object} {
 *  id: number, project_id: number, profile_id: number, data: object
 * }
 */
export const addTopology = async ({ projectId, profileId, data }) =>
  await request({
    method: "post",
    uri: `/v1/project/${projectId}/topology`,
    query: {
      profile_id: profileId,
    },
    data,
  });

/** 토폴로지 수정 */
export const editTopology = async ({
  projectId,
  profileId,
  topologyId,
  data,
}) =>
  await request({
    method: "put",
    uri: `/v1/project/${projectId}/topology/${topologyId}`,
    query: { profile_id: profileId },
    data,
  });

/**
 * 토폴로지 리스트 가져오기
 * 정의 하는 파라메터에 따라 레벨별로 데이터를 가져온다.
 * @param {number} projectId (required)
 * @param {number} profileId (optional)
 * ~~@param {number} topologyId (optional)~~ 사용할 일이 없다
 */
export const getTopologies = async ({ projectId, profileId }) => {
  const res = await request({
    method: "get",
    uri: `/v1/project/${projectId}/topology`,
    query: { profile_id: profileId },
  });
  if (res.length > 0) {
    return res.map((a) => ({ ...a, data: a.data ? JSON.parse(a.data) : a }));
  }
  return res;
};

/** 토폴로지 상세 */
export const getTopology = async ({ projectId, topologyId }) => {
  const res = await request({
    method: "get",
    uri: `/v1/project/${projectId}/topology/${topologyId}`,
  });

  if (res.data) {
    return JSON.parse(res.data);
  }
  return res;
};

/** 토폴로지 삭제 */
export const removeTopology = async ({ projectId, topologyId }) => {
  const res = await request({
    method: "delete",
    uri: `/v1/project/${projectId}/topology/${topologyId}`,
  });
  return res;
};

/** 토폴로지용 마이크로 서비스 상세 */
export const getMicroservice = async ({
  projectId,
  profileId,
  microserviceId,
}) => {
  const res = await request({
    method: "get",
    uri: `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/topology`,
  });
  return res;
};

/** 토폴로지용 마이크로 서비스 수정 */
export const editMicroservice = async ({
  projectId,
  profileId,
  microserviceId,
  data,
}) => {
  const res = await request({
    method: "put",
    uri: `/v1/project/${projectId}/profile/${profileId}/microservice/${microserviceId}/topology`,
    data,
  });

  return res;
};

/** 그룹화된 백엔드 서비스 목록 */
export const getGroupedBackendService = async ({
  projectId,
  profileId,
  type,
}) => {
  const res = await request({
    method: "get",
    uri: `/v1/project/${projectId}/profile/${profileId}/backendservice/simple`,
    query: { type },
  });
  // console.log(res);
  return res;
};

/** 토폴로지 접근 여부 반환 */
export const checkTopologyAccess = () =>
  request({
    method: "get",
    uri: `/v1/plugins/topology`,
  });

/**
 * 토폴로지의 애플리케이션 목록
 * @param {{projectName:string, profileName:string}} param0
 * @return {Promise<{totalCount:number, items:OperationApplication[], nodes:{name:string, cpu:ApplicationCpuResource, memory:ApplicationMemoryResource, systemInfo:ApplicationSystem}[]}>}
 */
export const getTopologyApplications = ({ projectName, profileName }) =>
  request({
    method: "get",
    uri: `/v1/topology/applications?selector=amdp.io/profile=${projectName}.${profileName}&project=${projectName}`,
  });

/**
 * 애플리케이션의 상세 정보를 반환한다.
 * @param {{projectName:string, profileName:string, applicationName:string, managedFields:"true"|"false"}} param0
 * @returns
 */
export const getTopologyApplication = ({
  projectName,
  profileName,
  applicationName,
  managedFields = "true",
} = {}) =>
  request({
    method: "get",
    uri: `/v1/topology/applications/${applicationName}.${profileName}.${projectName}?managedFields=${managedFields}`,
  });

/**
 * 애플리케이션의 로그 정보를 반환한다.
 * @param {{projectName:string, profileName:string, applicationName:string}} param0
 * @returns
 */
export const getTopologyApplicationLogs = ({
  projectName,
  profileName,
  applicationName,
} = {}) =>
  request({
    method: "get",
    uri: `/v1/topology/applications/${applicationName}.${profileName}.${projectName}/logs`,
  });

/**
 * @typedef {object} OperationApplication - 토폴로지에서 사용하는 어플리케이션 정보
 * @property {string} name - 어플리케이션 명
 * @property {string} namespace - 프로파일 네임스페이스
 * @property {string} projectName - 프로젝트 명
 * @property {string} profileName - 프로파일 명
 * @property {ApplicationHealth} health - 상태 정보
 * @property {ApplicationNode} node - 애플리케이션 노드 정보
 */

/**
 * @typedef {object} ApplicationNode
 * @property {string} name - 어플리케이션 노드 명
 * @property {[ApplicationCpuResource, ApplicationMemoryResource]} resourcesInfo - 어플리케이션 리소스 정보 목록(cpu, memory 2가지만 존재)
 * @property {ApplicationSystem} systemInfo - 어플리케이션 시스템
 */

/**
 * @typedef {"Healthy"|"Progressing"|"Degraded"|"Missing"|"Unknown"} ApplicationHealth - 애플리케이션의 건강 상태
 * . "Healthy" - 건강
 * . "Progressing" - 진행중이거나 로딩 중
 * . "Degraded" - 무언가의 문제가 있거나 불완전한 상태
 * . "Missing" - 누락된 항목이나 컨텐츠 있음
 * . "Unknown" - 알수 없는 상태
 */

/**
 * @typedef {object} ApplicationResource - 애플리케이션의 리소스 정보
 * @property {number} capacity - node 전체 용량
 * @property {number} requestedByApp - 애플리케이션 요청 용량(Request 가 없으면 필드도 없음)
 * @property {number} requestedByNeighbors - 동일 노드의 다른 pod 가 사용하는 용양
 */

/**
 * @typedef {ApplicationResource & {resourceName: "cpu"}} ApplicationCpuResource - 어플리케이션의 cpu 리소스
 */

/**
 * @typedef {ApplicationResource& {resourceName: "memory"}} ApplicationMemoryResource - 어플리케이션의 memory 리소스
 */

/**
 * @typedef {object} ApplicationSystem - 애플리케이션의 인스턴스 정보
 * @property {string} architecture - cpu 아키텍처
 * @property {string} bootId
 * @property {string} containerRuntimeVersion
 * @property {string} kernelVersion
 * @property {string} kubeProxyVersion
 * @property {string} kubeletVersion
 * @property {string} machineID
 * @property {string} operatingSystem - OS 정보
 * @property {string} osImage - OS 이미지 정보
 * @property {string} systemUUID
 */
