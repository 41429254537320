const initialState = {
  // 프로파일 권한 정보
  profileAuth: {
    role: null, // "OWNER"|"MEMBER"|null
    scope: null, // "ALL"|"EACH"|null
    auth: null, // "ALL"|"READ"|null
    profiles: [], // {id: number, auth: "ALL"|"READ"}[]
    microservices: [], // {id: number, profileId: number, auth: "ALL"|"READ"|"READ_EXECUTE"}
  },
};

export default {
  namespaced: true,
  /** ===== state ===== */
  state: () => ({ ...initialState }),
  /** ===== mutations ===== */
  mutations: {
    initialize(state) {
      for (const [k, v] of Object.entries(initialState)) {
        state[k] = v;
      }
    },
    profileAuthInitialize(state) {
      state.profileAuth = { ...initialState.profileAuth };
    },
    setProfileAuth(state, payload) {
      state.profileAuth = payload;
    },
  },
  /** ===== actions ===== */
  actions: {
    /** 전체 초기화 */
    initialize({ commit }) {
      commit("initialize");
    },
    /** 프로파일 권한 초기화 */
    profileAuthInitialize({ commit }) {
      commit("profileAuthInitialize");
    },
    /** 프로파일 권한 셋팅 */
    setProfileAuth(
      { commit },
      { role, scope, auth, profiles = [], microservices = [] } = {},
    ) {
      commit("setProfileAuth", { role, scope, auth, profiles, microservices });
    },
  },
};
