export const SettingsMixin = {
  /** ===== data ===== */
  // data: () => ({
  //   settingsMixinData: {},
  // }),
  /** ===== methods ===== */
  methods: {
    /** 설정을 셋팅한다 */
    setSetting(key, data) {
      const userId = this.$store.state.accountInfo.id;
      const allData = storage.getAll();
      const settings = allData.find((a) => a.userId === userId).settings;
      const newData = typeof data === "function" ? data(settings[key]) : data;
      const newSettings = {
        ...settings,
        [key]: newData,
      };
      const newAllData = allData.map((a) =>
        a.userId === userId
          ? {
              ...a,
              settings: { ...newSettings },
            }
          : a,
      );

      storage.set(newAllData);
      this.$store.dispatch("settings/setSetting", {
        key,
        settings: newSettings[key],
      });
    },
    /** 지정된 설정을 반환 한다  */
    getSetting(key) {
      if (!key) {
        throw `not defined key: ` + key;
      }
      const userId = this.$store.state.accountInfo.id;
      const allData = storage.getAll();
      const settings = allData?.find((a) => a.userId === userId).settings;

      if (!settings) {
        return null;
      }

      return settings[key];
    },
    /** 나의 전체 설정을 반환 한다  */
    getAllSettings() {
      const userId = this.$store.state.accountInfo.id;
      const all = storage.getAll();
      // console.log("> get all: ", all);
      return all.find((a) => a.userId === userId).settings;
    },
    /** local storage의 상태 정보를 stroe에 로드 한다 */
    loadSettings() {
      const settingStorage = storage.getAll();
      const userId = this.$store.state.accountInfo.id;
      const initData = {
        userId,
        settings: {},
      };

      if (typeof userId !== "number") {
        throw `SettingMixin.js Error. invalid userId: ` + userId;
      }

      // # 설정이 되어 있지 않을경우 초기화
      if (!settingStorage) {
        storage.set([initData]);
      }

      const allData = storage.getAll();
      const myData = allData.find((a) => a.userId === userId);

      // # 나의 데이터 없을 경우 기본 데이터 등록
      if (!myData) {
        storage.set([...allData, initData]);
      }

      // # 스토어에 저장
      this.$store.dispatch(
        "settings/setSettings",
        myData?.settings ?? initData.settings,
      );
    },
  },
  /** ===== mounted ===== */
  mounted() {},
};

const Storage = (STORAGE_KEY) => () => {
  return {
    set: (data) => {
      localStorage.setItem(
        STORAGE_KEY,
        typeof data === "object" || Array.isArray(data)
          ? JSON.stringify(data)
          : data,
      );
    },
    getAll: () =>
      localStorage.getItem(STORAGE_KEY)
        ? JSON.parse(localStorage.getItem(STORAGE_KEY))
        : localStorage.getItem(STORAGE_KEY),
  };
};

const storage = Storage("SETTING_STORAGE")();
