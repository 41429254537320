<!-- <template>
  <v-data-table
    :headers="headers"
    :items="filteredItems"
    :hide-default-header="true"
    class="elevation-1 w-full"
    :search="searchQuery"
  >
    <template v-slot:header="{ props }">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          class="header-cell"
        >
          {{ header.text }}
        </th>
      </tr>
    </template>
    <template v-slot:no-data>
      <v-alert :value="true" color="error" icon="mdi-alert">
        No data available
      </v-alert>
    </template>
  </v-data-table>
</template> -->
<template>
  <div class="w-full">
    <!-- Table with search box and export button -->
    <v-row>
      <v-col cols="12" md="6" class="pb-0">
        <!-- Search box on the top left -->
        <div class="flex flex-row">
          <span class="pt-2 table-title">Overview</span>
          <FInput
            v-model="searchQuery"
            :append-icon="'mdi-magnify'"
            outlined
            dense
            placeholder="Search"
            label="Search"
            size="lg"
            class="search pl-2"
            hide-details
          />
        </div>
      </v-col>
      <v-col cols="12" md="6" class="text-right pb-0">
        <FButton
          size="md"
          fill
          depressed
          color="white"
          class="text-transform-none buttons"
          @click="exportTable"
        >
          Export
        </FButton>
        <FButton
          size="md"
          fill
          depressed
          color="white"
          class="text-transform-none ml-3 buttons"
          @click="handlePricePolicy"
        >
          Price Policy
        </FButton>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="columns">
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :items-per-page="5"
          class="elevation-1 overview-table"
          :search="searchQuery"
          :hide-default-header="true"
        >
          <template v-slot:header="{ props }">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                class="header-cell"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.rdeName }}</td>
              <td>{{ item.workspaceName }}</td>
              <td>{{ item.instanceType }}</td>
              <td>{{ item.cpu }}</td>
              <td>{{ item.memory }}</td>
              <td>{{ item.disk }}</td>
              <td>{{ item.diskType }}</td>
              <td>{{ formatDate(item.createdDate) }}</td>
              <td>{{ item.used }}</td>
              <td>{{ item.billing }}</td>
              <td>{{ item.creator }}</td>
            </tr>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" color="error" icon="mdi-alert">
              No data available
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      searchQuery: "",
      headers: [
        { text: "RDE Name", value: "rdeName", sortable: false },
        { text: "Workspace Name", value: "workspaceName", sortable: false },
        { text: "Instance Type", value: "instanceType", sortable: false },
        { text: "CPU", value: "cpu", sortable: false },
        { text: "Memory", value: "memory", sortable: false },
        { text: "Disk", value: "disk", sortable: false },
        { text: "Disk Type", value: "diskType", sortable: false },
        { text: "Created Date", value: "createdDate", sortable: false },
        { text: "Used", value: "used", sortable: false },
        { text: "Billing", value: "billing", sortable: false },
        { text: "Creator", value: "creator", sortable: false },
      ],
      items: [
        {
          rdeName: "{rde-name}",
          workspaceName: "{workspace-name}",
          instanceType: "Node",
          cpu: "1c",
          memory: "100Gi",
          disk: "100Gi",
          diskType: "Block-storage",
          createdDate: "2024-09-24",
          used: "1d 10h",
          billing: "₩10,000",
          creator: "skcc-test",
        },
        {
          rdeName: "{rde-name}",
          workspaceName: "{workspace-name}",
          instanceType: "Node",
          cpu: "1c",
          memory: "100Gi",
          disk: "100Gi",
          diskType: "Block-storage",
          createdDate: "2024-09-24",
          used: "1d 10h",
          billing: "₩10,000",
          creator: "skcc-test",
        },
        {
          rdeName: "{rde-name}",
          workspaceName: "{workspace-name}",
          instanceType: "Node",
          cpu: "1c",
          memory: "100Gi",
          disk: "100Gi",
          diskType: "Block-storage",
          createdDate: "2024-09-24",
          used: "1d 10h",
          billing: "₩10,000",
          creator: "skcc-test",
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(this.searchQuery.toLowerCase()),
        ),
      );
    },
  },
  methods: {
    exportTable() {
      // Your export logic here
      console.log("Export table");
    },
    handlePricePolicy() {
      // Your price policy logic here
      console.log("Price policy");
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.elevation-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.header-cell {
  background-color: #e4e5eb;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
  padding-left: 10px;
}
.search {
  max-width: 200px;
}
.buttons {
  /* color: rgba(19, 19, 22, 1); */
  color: var(--gray2);
  border: 1px solid rgba(19, 19, 22, 0.1) !important;
  &.v-btn__content {
    font-size: 14px;
    font-weight: normal !important;
    line-height: 16.41px;
    letter-spacing: 0.0089em;
    text-align: left;
  }
}
.table-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
}
.overview-table table tbody tr td {
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.004em;
  text-align: left;
}
</style>
