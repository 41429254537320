export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations: {
    initialize(state) {
      for (const [k, v] of Object.entries(initialState)) {
        state[k] = v;
      }
    },
    setSettings(state, payload) {
      state.settings = payload;
    },
    setSetting(state, { key, settings }) {
      state.settings = {
        ...state.settings,
        [key]: settings,
      };
    },
  },
  actions: {
    /** initialize */
    initialize: ({ commit }) => commit("initialize"),
    /** 전체 데이터 셋팅 */
    setSettings: ({ commit }, payload) => commit("setSettings", payload),
    /** 지정된 key 셋팅 */
    setSetting: ({ commit }, { key, settings }) =>
      commit("setSetting", { key, settings }),
  },
};

const initialState = Object.freeze({
  settings: {},
});
