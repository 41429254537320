export default [
  {
    path: "projects/:projectId/profiles/:profileId/traffic-managements/add",
    name: "trafficManagementsAdd",
    component: () =>
      import("../pages/trafficManagement/TrafficManagementAddPage"),
  },
  {
    path: "projects/:projectId/profiles/:profileId/traffic-managements/:trafficGroupId/edit",
    name: "trafficManagementsEdit",
    component: () =>
      import("../pages/trafficManagement/TrafficManagementEditPage"),
  },
];
