<template>
  <nav
    class="compositions__breadcrumbs flex gap-2 text-xs"
    role="navigation"
    aria-label="breadcrumbs"
  >
    <div v-for="(item, index) in items" :key="index" class="flex items-center">
      <!-- link -->
      <RouterLink v-if="item?.link" :to="item.link" class="font-semibold">
        <!-- icon -->
        <v-icon v-if="item.icon" small class="block pb-1">
          {{ item.icon }}
        </v-icon>
        {{ item?.text }}
      </RouterLink>

      <!-- normal text -->
      <div
        v-else
        class="text-gray-500"
        :aria-current="index === items.length - 1 ? 'page' : undefined"
      >
        <!-- icon -->
        <v-icon v-if="item.icon" small class="block pb-1">
          {{ item.icon }}
        </v-icon>
        {{ item?.text }}
      </div>

      <!-- divider -->
      <div v-if="index < items.length - 1" class="ml-2">/</div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    /** breadcrumb 데이터 배열 / {text, icon, link} */
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.compositions__breadcrumbs {
  a {
    color: #4b5563;
  }
}
</style>
