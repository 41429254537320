<template>
  <div class="rde-state-page">
    <div class="header">
      <div class="left-header">
        <img
          src="~@/assets/img/arrow-back.svg"
          alt="SVG Icon"
          :style="{ cursor: 'pointer' }"
          @click="goBack"
        />
        <span>{{ rdeState.name }}</span>
      </div>
    </div>
    <v-container class="p-2 m-0" style="max-width: unset">
      <ChartGroup :data="overallChartData" />

      <PodTable :data="podList" />

      <v-expansion-panels v-model="activePanel" class="pt-2">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              {{ rdeState.name }} - <span class="font-bold">VS Code</span></span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ChartGroup :data="vscodeChartData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span
              >{{ rdeState.name }} - <span class="font-bold">SSH</span></span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ChartGroup :data="sshChartData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span
              >{{ rdeState.name }} -
              <span class="font-bold">Jupiter</span></span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ChartGroup :data="jupiterChartData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import ChartGroup from "@/components/newWorkspace/RDEState/ChartGroup/ChartGroup.vue";
import PodTable from "@/components/newWorkspace/RDEState/PodTable.vue";
import { cloneDeep, omit } from "lodash";
import { getRDEStateMetrics } from "@/service/apis/workspaceApis";
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  components: { ChartGroup, PodTable },
  data: () => ({
    rdeState: {
      name: "admin.localDomainRS.ags-webide-workspace",
    },
    activePanel: 0,
    overallChartData: {},
    vscodeChartData: {},
    sshChartData: {},
    jupiterChartData: {},
  }),
  methods: {
    goBack() {
      this.$router.back();
    },
  },
  mounted() {
    const days = 14; // query in last 14 days
    const namespace = this.$route?.params.namespace;
    const rde = this.$route?.params.rde;
    const wsName = this.$route?.params.wsName;

    if (namespace && rde) {
      CommonUIControl.ShowUIProgress();
      getRDEStateMetrics({
        namespace: namespace,
        rde: rde,
        wsName,
        from: Math.round(new Date().getTime() / 1000) - 60 * 60 * 24 * days,
        to: Math.round(new Date().getTime() / 1000),
      })
        .then((res) => {
          if (res?.result) {
            const result = res.result;
            this.overallChartData = omit(result, "containers");

            const specifyMetric = (type) =>
              result?.containers?.find((item) => item.name === type) || {};

            this.vscodeChartData = cloneDeep(specifyMetric("vscodeserver"));
            this.sshChartData = cloneDeep(specifyMetric("sshserver"));
            this.jupiterChartData = cloneDeep(specifyMetric("jupyter"));
          }
        })
        .catch((err) => {
          console.error(err?.message || "");
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.rde-state-page {
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: #fff;

    .left-header {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        color: #000000de;
        padding-left: 10px;
      }
    }
  }
}
</style>
