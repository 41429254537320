<template>
  <RouterView v-if="hasAccountInfo" />
</template>

<script>
import { AuthMixin } from "@/mixins/AuthMixin";
import { RequestMixin } from "@/mixins/RequestMixin";
import { mapState } from "vuex";

export default {
  components: {},
  mixins: [RequestMixin, AuthMixin],
  computed: {
    ...mapState({
      hasAccountInfo: (state) => !!state.accountInfo,
    }),
  },
  watch: {},
  async mounted() {},
};
</script>

<style lang="scss" scoped></style>
