<template>
  <v-select
    v-model="infraData"
    :items="infraBaselineList"
    :item-text="showLabel"
    item-value="id"
    attach
    class="app-g-select-v2"
    clearable
    outlined
    dense
    :label="required ? '* Resource Classes' : 'Resource Classes'"
    :rules="[validateRequired]"
    :multiple="multiple"
    @input="onChange"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ infraSubtitleFormat(data.item) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template #selection="{ attrs, item, parent, select, selected }">
      <v-chip
        color="primary"
        close-icon="mdi-close"
        v-bind="attrs"
        :input-value="selected"
        :close="multiple"
        small
        @click="select"
        @click:close="parent.selectItem(item)"
      >
        {{ item.name }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { isEmpty } from "lodash";

export default {
  name: "InfraSelect",
  props: {
    values: { type: Object },
    infraBaselineList: {
      type: Array,
      default: () => [],
    },
    onChangeInfra: {
      type: Function,
      default: () => undefined,
    },
    defaultValue: {
      type: [Object, String, Number, Array],
    },
    multiple: {
      type: [Boolean],
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      infraData: null,
    };
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler() {
        this.setDefaultValue();
      },
    },
    infraBaselineList: {
      immediate: true,
      handler() {
        this.setDefaultValue();
      },
    },
  },
  methods: {
    validateRequired() {
      if (!this.required) return true;

      if (this.multiple && (!this.infraData || isEmpty(this.infraData)))
        return "Required";

      if (!this.multiple && !this.infraData) return "Required";

      return true;
    },
    infraSubtitleFormat(item) {
      let cpuValue, cpuUnit;
      let memoryValue, memoryUnit;
      // CPU
      if (item.limitCpuUnit === "m") {
        if (item.limitCpu < 1000) {
          cpuValue = item.limitCpu;
          cpuUnit = "Millicore";
        } else {
          cpuValue = item.limitCpu / 1000;
          cpuUnit = "Core";
        }
      } else {
        cpuValue = item.limitCpu;
        cpuUnit = item.limitCpuUnit;
      }

      // Memory
      if (item.limitMemoryUnit === "MiB") {
        if (item.limitMemory < 1024) {
          memoryValue = item.limitMemory;
          memoryUnit = "MiB";
        } else {
          memoryValue = item.limitMemory / 1024;
          memoryUnit = "GiB";
        }
      } else {
        memoryValue = item.limitMemory;
        memoryUnit = item.limitMemoryUnit;
      }

      return `CPU : ${cpuValue} ${cpuUnit} | Memory : ${memoryValue} ${memoryUnit}`;
    },
    showLabel(item) {
      if (item) return `${item.name} (${this.infraSubtitleFormat(item)})`;
      return "";
    },
    onChange(idParams) {
      let selectedOption;
      if (this.multiple) {
        selectedOption = this.infraBaselineList.filter((i) =>
          idParams.includes(i.id),
        );
      } else {
        selectedOption = idParams
          ? this.infraBaselineList.find((i) => i.id == idParams)
          : null;
      }

      this.onChangeInfra(selectedOption);
    },
    setDefaultValue() {
      if (
        Array.isArray(this.defaultValue)
          ? this.defaultValue.length
          : this.defaultValue
      ) {
        if (["string", "number"].includes(typeof this.defaultValue)) {
          this.infraData = Number(this.defaultValue);
        } else if (
          Array.isArray(this.defaultValue) &&
          this.defaultValue.every((i) =>
            ["number", "string"].includes(typeof i),
          )
        ) {
          this.infraData = this.defaultValue.map((i) => Number(i));
        } else if (
          typeof this.defaultValue === "object" &&
          !Array.isArray(this.defaultValue) &&
          this.infraBaselineList.length
        ) {
          // this is a special case in vs code, web ssh, jupiter with infra input
          const selectedObj = this.infraBaselineList.find(
            (i) =>
              `${i.limitCpu}` === `${this.defaultValue.cpu}` &&
              `${i.limitMemory}` === `${this.defaultValue.memory}`,
          );

          if (selectedObj?.id) {
            this.infraData = selectedObj.id;
          }
        }
      }
    },
  },
};
</script>
