// @ts-check
import httpClient from "./httpClient";

/**
 * @typedef {"post"|"get"|"put"|"patch"|"delete"} Method
 */

/**
 * @typedef RequestParams
 * @type {object}
 * @property {"post"|"get"|"put"|"patch"|"delete"} method http 메소드
 * @property {string} uri 요청 uri
 * @property {object} [query] 쿼리 파라메터
 * @property {object} [data] post, put, patch 요청 시 body
 * @property {number} [timeout] 타임 아웃 정의
 * @property {import("axios").AxiosInstance} [instance = httpClient] 리퀘스트에 사용할 axios instance
 * @property {boolean} [originalResponse] true일 경우 원본 응답 반환
 */

/**
 * 요청에 대한 공통처리
 * @param {RequestParams} params
 */
export const request = async ({
  method,
  uri,
  query,
  data,
  timeout = 10000,
  instance,
  originalResponse,
}) => {
  const reqInstance = instance ?? httpClient;
  let newQueryString = "";

  if (!method) {
    throw new Error("not defined method: " + method);
  }
  if (!uri) {
    throw new Error("not defined uri: " + uri);
  }
  const newMethod = method.toLowerCase();

  if (query) {
    const newQuery = Object.entries(query)
      .filter(([k, v]) => v !== undefined && v !== null && k)
      .reduce((acc, [k, v]) => {
        return { ...acc, [k]: v };
      }, {});
    const searchParams = new URLSearchParams(newQuery);
    newQueryString = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
  }

  const newUri = uri + newQueryString;
  let res;

  if (newMethod === "get" || newMethod === "delete") {
    res = await reqInstance[newMethod](newUri, { timeout });
  } else {
    res = await reqInstance[newMethod](newUri, data, { timeout });
  }

  if (originalResponse) {
    return res;
  }

  if (!res || !res.data || res.data.success !== true) {
    throw new Error(res?.data?.message ?? res?.data?.error_message);
  }

  return res?.data?.result || res?.result;
};
