import httpClient from "@/service/httpClient";
import { request } from "../request";

/**
 * 그룹 목록 가져오기
 * @param {number} projectId 프로젝트 아이디.정의하지 않을 시 전체 그룹 목록
 */
export const getGroupList = ({ projectId } = {}) =>
  request({
    method: "get",
    uri: `/v1/admin/groups`,
    query: {
      projectId,
    },
  });

export default {
  getGroupList(projectId) {
    return new Promise((resolve, reject) => {
      let url = "/v1/admin/groups";
      if (projectId) {
        url += `?projectId=${projectId}`;
      }
      httpClient
        .get(url)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  getGroupInfo(id) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/admin/groups/${id}`)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  createGroup(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/v1/admin/groups", params)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  updateGroup(id, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/admin/groups/${id}`, params)
        .then((resp) => resolve(resp))
        .catch((e) => reject(e.response));
    });
  },
  deleteGroup(id) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/admin/groups/${id}`)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
};
