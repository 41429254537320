<template>
  <div class="p-2 grid grid-cols-12 gap-4 mt-2">
    <Indicator
      title="CPU Usage"
      :value="cpuMetrics.usageData"
      :usageUnit="cpuMetrics.usageUnit"
      :limit="cpuMetrics.limitData"
      :unit="cpuMetrics.limitUnit"
      :percent="cpuMetrics.percent"
      :showPercent="true"
      icon="cpu"
      extraClass="big-size"
    />
    <Indicator
      title="Memory Usage"
      :value="memoryMetrics.usageData"
      :usageUnit="memoryMetrics.usageUnit"
      :limit="memoryMetrics.limitData"
      :unit="memoryMetrics.limitUnit"
      :percent="memoryMetrics.percent"
      :showPercent="true"
      icon="memory"
      extraClass="big-size"
    />
    <Indicator
      title="Disk Usage"
      :value="diskMetrics.usageData"
      :usageUnit="diskMetrics.usageUnit"
      :limit="diskMetrics.limitData"
      :unit="diskMetrics.limitUnit"
      :percent="diskMetrics.percent"
      :showPercent="true"
      icon="disk"
      extraClass="big-size"
    />
  </div>
</template>

<script>
import Indicator from "@/components/newWorkspace/common/Indicator.vue";
import { UNIT_LABELS } from "@/service/constants";

export default {
  props: {
    workspaceId: { type: String },
    wsInfo: { type: Object },
    usageInfo: { type: Object },
  },
  components: { Indicator },
  data() {
    return {
      wp: null,
      UNIT_LABELS: UNIT_LABELS,
    };
  },
  computed: {
    memoryMetrics() {
      return this.$helper.viewMemoryData2(
        this.usageInfo?.memory,
        this.wsInfo?.limitMemory,
        true,
      );
    },
    diskMetrics() {
      return this.$helper.viewDiskData(
        this.usageInfo?.disk,
        this.wsInfo?.limitDisk,
        true,
      );
    },
    cpuMetrics() {
      return this.$helper.viewCpuData2(
        this.usageInfo?.cpu,
        this.wsInfo?.limitCpu,
        true,
      );
    },
  },
};
</script>
<style lang="css" scoped>
.big-size {
  @apply col-span-4;
}
</style>
