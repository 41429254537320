
<v-menu offset-y>
  <template v-slot:activator="{ on, attrs }">
    <FButton
      :size="size"
      fill
      color="white"
      v-bind="attrs"
      :class="textClass"
      v-on="on"
    >
      <v-icon>mdi-sort-variant</v-icon>
      {{ labelBtn }}
    </FButton>
  </template>
  <v-list>
    <v-list-item
      v-for="(item, index) in items"
      :key="index"
      :class="{ selected: item?.value === selected }"
    >
      <v-list-item-title @click="handleSelected(item?.value)">
        <v-icon v-if="item?.value === selected" size="medium">
          mdi-check
        </v-icon>
        {{ item?.label }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>
