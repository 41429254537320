import RdeContainerPage from "../pages/MyRde/MyRdePage.vue";

export default [
  {
    path: "my-rde",
    component: RdeContainerPage,
    meta: {
      title: "My RDE",
      isMenuItem: true,
      breadCrumb: [{ text: "My RDE" }],
    },
  },
];
