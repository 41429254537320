import httpClient from "@/service/httpClient";
import { request } from "../request";

/**
 * 프로젝트 멤버 혹은 그룹을 등록한다
 * @typedef {object} AddProjectMembersAndGroupsParams
 * @property {number} project_id - 프로젝트 아이디
 * @property {number} profile_id - 프로파일 아이디
 * @property {number} group_id - 멤버일 경우는 `0`
 * @property {number} member_id - 그룹일 경우는 `0`
 * @property {0|4|5|7} mode - 애플리케이션 권한. `0`외에는 개별 프로파일 선택시 애플리케이션 전체에 대한 권한. 0(개발 프로파일 선택시)|4(읽기)|5(읽기/실행)|7(전체)
 * @property {{id:number, mode:4|5|7}[]} resourceList - 다중 애플리케이션 정보. 단일 애플리케이션 요청 시 빈배열([])
 * @property {number} [resource_id] - 단일 애플리케이션 아이디. 다중 애플리케이션을 선택시에는 `0`
 * @property {"microservice"} [resource_type] - 차후 확장을 위한 필드. 현재는 고정값
 * @param {AddProjectMembersAndGroupsParams} param0
 * @returns {Promise<{result:number, code:number, http_status_code:number, success:boolean }>}
 */
export const addProjectMembersAndGroups = ({
  project_id,
  profile_id,
  group_id,
  member_id,
  mode,
  resourceList = [],
  resource_type = "microservice",
  resource_id = 0,
}) =>
  request({
    method: "post",
    uri: `/v1/project/${project_id}/auths`,
    data: {
      project_id,
      profile_id,
      group_id,
      member_id,
      mode,
      resourceList,
      resource_type,
      resource_id,
    },
  });

/**
 * 프로젝트 그룹, 멤버의 단일 권한을 수정한다
 * @param {Omit<AddProjectMembersAndGroupsParams, "resourceList"> & {auth_id:number}} param0
 * @returns
 */
export const editProjectMemberAndGroup = ({
  project_id,
  profile_id,
  auth_id,
  group_id,
  member_id,
  mode,
  resource_type = "microservice",
  resource_id = 0,
}) =>
  request({
    method: "put",
    uri: `/v1/project/${project_id}/auths/${auth_id}`,
    data: {
      project_id,
      profile_id,
      group_id,
      member_id,
      resource_type,
      mode,
      resource_id,
    },
  });

export default {
  createProjectAuth(projectId, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/project/${projectId}/auths`, params)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  getProjectAuthList(projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/auths`)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  getMemberProjectAuthList(memberId, projectId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/members/${memberId}/auths`)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  getProjectAuthInfo(projectId, authId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/auths/${authId}`)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  updateProjectAuth(projectId, authId, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/project/${projectId}/auths/${authId}`, params)
        .then((resp) => resolve(resp))
        .catch((e) => reject(e.response));
    });
  },
  deleteProjectAuth(projectId, authId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/project/${projectId}/auths/${authId}`)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
};
