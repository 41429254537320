<template>
  <label
    class="text-sm block relative"
    :class="{
      required: required,
      'direction-row': direction === 'row',
      'font-semibold': bold === true,
    }"
  >
    <div
      v-if="label"
      class="break-all text-gray-600"
      :class="{ 'label-row': direction === 'row' }"
      :style="{ width: direction === 'row' ? `${labelWidth}px` : '100%' }"
    >
      {{ label }}
    </div>
    <slot></slot>

    <!-- tooltip -->
    <DescriptionTooltip v-if="tooltip" :tooltipWidth="tooltipWidth">
      {{ tooltip }}
    </DescriptionTooltip>
  </label>
</template>

<script>
import DescriptionTooltip from "../../compositions/DescriptionTooltip/DescriptionTooltip.vue";

export default {
  props: {
    /** true일 경우 필수 표시 */
    required: {
      type: Boolean,
    },
    /** label text */
    label: {
      type: String,
      default: "",
    },
    /** label width */
    labelWidth: {
      type: Number,
      default: 96,
    },
    /** 레이블의 정렬방향 ('row' | 'col') */
    direction: {
      type: String,
      default: "col",
    },
    /** 항목에 대한 설명글 */
    tooltip: {
      type: String,
      default: undefined,
    },
    /** 툴팁 넓이 */
    tooltipWidth: {
      type: [String, Number],
      default: undefined,
    },
    /** true일 경우 bold */
    bold: {
      type: Boolean,
      default: true,
    },
  },
  components: { DescriptionTooltip },
};
</script>

<style lang="scss" scoped>
.required::after {
  content: " ";
  width: 4px;
  height: 4px;
  background-color: #f53123;
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: 0px;
}
.required.direction-row::after {
  top: 8px;
}
.direction-row {
  display: flex;
  gap: 8px;
  .label-row {
    margin-top: 8px;
  }
}
.tooltip {
  display: block;
  width: 400px;
  word-break: break-word;
  line-height: 1.5rem;
}
</style>
