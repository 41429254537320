export default [
  {
    path: "BackingServiceList",
    name: "BackingServiceList",
    component: () =>
      import(
        "../pages/main/modernframework/BackingService/BackingServiceList.vue"
      ),
  },
];
