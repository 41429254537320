
<v-form ref="form" v-model="valid">
  <div class="w-full flex flex-row container-search-dashboard">
    <label v-if="(searchFields?.length || 0) > 1" class="label mt-2 mr-2">
      Search by
    </label>
    <div class="inline-grid grid-cols-2 gap-2">
      <Select
        v-if="(searchFields?.length || 0) > 1"
        v-model="searchFieldSelected"
        label="Search by"
        :items="searchFields"
        :itemText="(a) => a.label || a"
        :itemValue="(a) => a.value"
        outlined
        dense
        class="bg-autocomplete"
        @change="(value) => onChangeSearchOption()"
      />
      <FInput
        v-model="inputSearch"
        :append-icon="'mdi-magnify'"
        outlined
        dense
        small-chips
        placeholder="Search"
        label="Search"
        size="lg"
        class="bg-autocomplete"
        @input="debounceInput"
      />
    </div>
    <div class="col-end-6 flex flex-row ml-auto gap-2">
      <template v-if="sortFields">
        <FMenu
          :items="sortFields"
          label="SORT"
          :onSelected="onSelectedSort"
        />
        <div class="search-indicator">
          <FButton size="lg" color="primary" @click="onChangeSortType()">
            <v-icon v-if="sortType">mdi-sort-ascending</v-icon>
            <v-icon v-else>mdi-sort-descending</v-icon>
          </FButton>
        </div>
      </template>
      <slot></slot>
    </div>
  </div>
</v-form>
