<template>
  <v-radio-group
    :value="value"
    :row="direction === 'row'"
    :col="direction === 'col'"
    class="compositions__radio-group"
    v-bind="$attrs"
    v-on="$listeners"
    @change="(v) => $emit('input', v)"
  >
    <v-radio
      v-for="(item, i) of items"
      :key="`radio-${item.value}-${i}`"
      :label="item.label"
      :value="item.value"
      v-bind="item"
    >
      <template #label>
        <slot name="label" :item="item" :index="i"></slot>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  props: {
    /** 값 */
    value: {
      type: [String, Number],
    },
    /** 아이템 배열 */
    items: {
      type: Array,
    },
    /** 정렬 방향("row" | "col") */
    direction: {
      type: String,
      default: "row",
    },
  },
};
</script>

<style lang="scss">
.compositions__radio-group {
  padding-top: 0px;
  margin-top: 0px;
  .v-input__slot {
    margin-bottom: 0px;
  }
  .v-radio--is-disabled i {
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>
