import httpClient from "../httpClient";
import { request } from "../request";

export default {
  getUserList(projectId) {
    let url = "/v1/admin/user";
    if (projectId) {
      url += `?projectId=${projectId}`;
    }
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  createUser(param) {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/v1/admin/user", param)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  updateUser(param) {
    return new Promise((resolve, reject) => {
      httpClient
        .put("/v1/admin/user", param)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getCurrentUser(userId) {
    return new Promise((resolve, reject) => {
      let url = "/v1/user";
      if (userId >= 0) {
        url += `/${userId}`;
      }

      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  /** 본인 비밀번호 변경 */
  changeUserPassword({ id, username, currentPassword, newPassword }) {
    return request({
      method: "put",
      uri: "/v1/user/changepassword",
      data: {
        id,
        username,
        current_password: currentPassword,
        new_password: newPassword,
      },
    });
  },
  /** 다른 사용자 비밀번호 변경 */
  editPassword({ id, username, newPassword, currentPassword }) {
    return request({
      method: "put",
      uri: `/v1/admin/user/${id}`,
      data: {
        id,
        username,
        current_password: currentPassword,
        new_password: newPassword,
      },
    });
  },
  getUserAuthList(userId, withName) {
    return new Promise((resolve, reject) => {
      let url = `/v1/user/${userId}/auths`;
      if (withName) {
        url += "?wn=true";
      }
      httpClient
        .get(url)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  getUserGroupList(userId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/user/${userId}/groups`)
        .then((resp) => resolve(resp.data.result))
        .catch((e) => reject(e.response));
    });
  },
  getUserByEmail(email) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/v1/user/search", { params: { email } })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getUserById(userid) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/v1/user/search", { params: { userid } })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};
