const showPublishingPage = process.env.VUE_APP_MODE !== "release";
const SHOW_INCOMPLETE = process.env.VUE_APP_MODE !== "release";

export default [
  {
    path: "CicdList",
    name: "CicdList",
    component: () => import("../pages/main/modernframework/Project/CicdList"),
    meta: {
      title: "CICD 상세",
      icon: "mdi-domino-mask",
      isMenuItem: showPublishingPage && SHOW_INCOMPLETE,
      breadCrumb: [{ text: "CI/CD 상세" }],
    },
  },
];
