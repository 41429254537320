<template>
  <div>
    <!-- Row 1: Label and dropdown box -->
    <div class="row my-0">
      <div class="col columns">
        <!-- Label on the left -->
        <span class="total-label">Total</span>
        <span class="total-value"> ₩10,000</span>
      </div>
      <div class="col columns text-right">
        <!-- Dropdown box on the right -->
        <DateRangePicker
          v-model="dateRange"
          opens="left"
          :genRanges="genRanges"
          :max-duration-start-end="0"
          viewFormat="DD/MM/YYYY"
          class="w-auto ml-auto"
          :timePicker="false"
        />
      </div>
    </div>
    <!-- Row 2: Two multi-select components -->
    <div v-if="isAdmin" class="row my-0">
      <div class="col py-0 px-0">
        <!-- Left multi-select component -->
        <v-autocomplete
          v-model="workspaceSelected"
          :items="workspaceItems"
          placeholder="Select left item"
          class="app-g-autocomplete-v2"
          multiple
          outlined
          dense
          chips
          clearable
          deletable-chips
          small-chips
        >
        </v-autocomplete>
      </div>
      <div class="col py-0 px-0">
        <!-- Right multi-select component -->
        <v-autocomplete
          v-model="userSelected"
          :items="userItems"
          placeholder="Select right item"
          class="app-g-autocomplete-v2"
          multiple
          outlined
          dense
          chips
          clearable
          deletable-chips
          small-chips
        />
      </div>
    </div>

    <!-- Row 3: Table -->
    <div class="row">
      <RdeCountTable />
    </div>

    <!-- Row 4: Table with pagination -->
    <div class="row">
      <RdeUsageTable />
      <!-- Pagination controls -->
      <div class="pagination-controls">
        <!-- Pagination buttons or controls go here -->
      </div>
    </div>

    <!-- Row 5: Table with search box and export button -->
    <div class="row">
      <!-- <div class="col py-0 px-0">
        <FInput
          v-model="inputSearch"
          :append-icon="'mdi-magnify'"
          outlined
          dense
          placeholder="Search"
          label="Search"
          size="lg"
          class="search pb-3"
          hide-details
          @input="debounceInput"
        />
      </div>
      <div class="col py-0 px-0 text-right">
        <FButton
          size="md"
          fill
          depressed
          color="white"
          class="text-transform-none buttons"
        >
          Export
        </FButton>
        <FButton
          size="md"
          fill
          depressed
          color="white"
          class="text-transform-none ml-3 buttons"
        >
          Price Policy
        </FButton>
      </div> -->
      <div class="col py-0 px-0 columns">
        <OverviewTable />
      </div>
    </div>
  </div>
</template>

<script>
import RdeCountTable from "./components/RdeCountTable.vue";
import RdeUsageTable from "./components/RdeUsageTable.vue";
import OverviewTable from "./components/OverviewTable.vue";
import {
  RANGES_DEFAULT_DATETIME_PICKER,
  DEFAULT_VALUE_RANGE_PICKER,
} from "@/pages/dashboard/constant";
import { genAgoTime } from "@/pages/dashboard/helper";
import DateRangePicker from "@/components/atoms/DateRangePicker/DateRangePicker.vue";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";
import * as _ from "lodash";

export default {
  name: "MeteringPricing",
  // Your component's JavaScript logic goes here
  components: { RdeCountTable, RdeUsageTable, OverviewTable, DateRangePicker },
  mixins: [ProfileAuthMixin],
  data() {
    return {
      workspaceSelected: [],
      userSelected: [],
      dropdownValue: null,
      searchQuery: "",
      userItems: [
        "user1",
        "user2",
        "user3",
        // Your items for the left select
      ],
      workspaceItems: [
        "workspace1",
        "workspace2",
        "workspace3",
        // Your items for the right select
      ],
      dropdownOptions: [
        // Your dropdown options
      ],
      genRanges: RANGES_DEFAULT_DATETIME_PICKER,
      dateRange: {
        startDate: genAgoTime(DEFAULT_VALUE_RANGE_PICKER * 24 * 60),
        endDate: new Date(),
      },
      inputSearch: "",
    };
  },
  methods: {
    exportTable() {
      // Your export logic here
    },
    debounceInput: _.debounce(function () {
      this.handleSearch();
    }, 800),
    handleSearch() {
      // Your search logic here
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  margin-bottom: 20px;
}
.col {
  flex: 1;
  padding: 10px;
}
.pagination-controls {
  margin-top: 10px;
}
.total-label {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.0015em;
  text-align: left;
}
.total-value {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0.0025em;
  text-align: left;
  color: #321fdb;
}
.search {
  max-width: 200px;
}

.buttons {
  /* color: rgba(19, 19, 22, 1); */
  color: var(--gray2);
  border: 1px solid rgba(19, 19, 22, 0.1) !important;
  &.v-btn__content {
    font-size: 14px;
    font-weight: normal !important;
    line-height: 16.41px;
    letter-spacing: 0.0089em;
    text-align: left;
  }
}
.columns {
  padding: 0 !important;
}
</style>
