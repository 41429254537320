
<v-data-table
  :headers="headers"
  :items="data"
  hide-default-footer
  dense
  disable-pagination
  class="table__wrapper_resource_rde no-border"
>
  <template v-slot:[`item.serviceType`]="{ item }">
    <label class="service-type">{{ item.serviceType }}</label>
  </template>
  <template v-slot:[`item.cpu`]="{ item }">
    <td>
      <div class="flex flex-col justify-center p-2">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="cpu" size="sm" color="#87888C" />
          <label class="label">CPU</label>
        </div>
        <div class="resource-box">
          <span
            class="usage-percentage"
            :style="$helper.getUsageStyle(item?.cpu?.usage, item?.cpu?.limit)"
          ></span>
          <label class="content text-start">
            {{ $helper.viewCpuData2(item?.cpu?.usage, item?.cpu?.limit) }}
          </label>
        </div>
      </div>
    </td>
  </template>
  <template v-slot:[`item.memory`]="{ item }">
    <td>
      <div class="flex flex-col justify-center p-2">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="memory" size="sm" color="#87888C" />
          <label class="label">Memory</label>
        </div>
        <div class="resource-box">
          <span
            class="usage-percentage"
            :style="
              $helper.getUsageStyle(item?.memory?.usage, item?.memory?.limit)
            "
          ></span>
          <label class="content text-start">
            {{
              $helper.viewMemoryData2(
                item?.memory?.usage,
                item?.memory?.limit,
              )
            }}
          </label>
        </div>
      </div>
    </td>
  </template>
</v-data-table>
