<template>
  <v-btn
    class="atoms__fbutton"
    :color="color"
    :x-small="size === 'xs'"
    :small="size === 'sm'"
    :large="size === 'lg'"
    :x-large="size === 'xl'"
    :elevation="elevation"
    :icon="icon"
    :block="block"
    :disabled="disabled || loading"
    :loading="loading"
    :outlined="fill === true ? false : outlined"
    :class="[block, $attrs.to && 'is-link']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>

    <Tooltip v-if="tooltip" :position="tooltipPosition" closest=".v-btn">
      <span>{{ tooltip }}</span>
    </Tooltip>
  </v-btn>
</template>

<script>
import Tooltip from "../FTooltip/FTooltip.vue";

export default {
  props: {
    /** 버튼의 색상 */
    color: {
      type: String,
      default: "accent",
    },
    /** 버튼의 크기 */
    size: {
      type: String, // "xs", "sm", "md", "lg", "xl"
      default: "sm",
    },
    /** 그림자의 크기 */
    shadow: {
      type: String, // "none", "xs", "sm", "md", "lg", "xl", "2xl"
      default: "none",
    },
    /** `true`일 경우 color 가 텍스트의 색상이되고 쉐도우가 없어진다 */
    text: {
      type: Boolean,
      default: true,
    },
    /** `true`일 경우 배경색이 채워진다 */
    fill: {
      type: Boolean,
    },
    /** `true`일 경우 display:block 속성으로 변환 */
    block: {
      type: Boolean,
      default: false,
    },
    /** `true`일 경우 원형으로 변경 */
    icon: {
      type: Boolean,
      default: false,
    },
    /** `true`일 경우 외곽선 */
    outlined: {
      type: Boolean,
      default: true,
    },
    /** `true`일 경우 전체를 비활성화 한다 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** `true`일 경우 laoding progress 표시 */
    loading: {
      type: Boolean,
    },
    /** 툴팁 */
    tooltip: {
      type: String,
    },
    /** 툴팁 위치 */
    tooltipPosition: {
      type: String,
      default: "bottom",
    },
  },
  components: { Tooltip },
  data: () => ({
    /** true일 경우 툴팁 활성화 */
    visibleTooltip: true,
  }),
  computed: {
    elevation() {
      return shadowR.router(this.shadow);
    },
  },
};

const shadowR = {
  ["none"]: () => 0,
  ["xs"]: () => 2,
  ["sm"]: () => 6,
  ["md"]: () => 8,
  ["lg"]: () => 12,
  ["xl"]: () => 16,
  ["2xl"]: () => 24,
  router(elevation) {
    if (!this[elevation]) {
      throw new Error("ERROR: shadow.router()  invalid `elevation`: ");
    }
    return this[elevation]();
  },
};
</script>

<style lang="scss" scoped>
.atoms__fbutton {
  &.is-link::before {
    opacity: 0;
  }
  font-size: 14px;
  text-transform: initial !important;
}
</style>
