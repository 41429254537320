// @ts-check
import Vue from "vue";
import CommonUIControl from "@/helper/CommonUIControl";
import { request } from "@/service/request";

/**
 * @typedef RequestURI
 * @type {string | Promise<any> | function}
 */

/**
 * @typedef RequestOptions
 * @property {import('../service/request').Method} [method]
 * @property {import('../service/request').RequestParams['data']} [data]
 * @property {import('../service/request').RequestParams['query']} [query]
 * @property {string} [key] 한번에 여러 리퀘스트를 요청 시 pendings, errors를 구분하기 위한 키
 * @property {string|function} [successText] 성공시 알림 텍스트
 * @property {string|boolean} [errorText]
 * @property {function} [onSuccess] 성공시 콜백
 * @property {function} [onError] 실패시 콜백
 * @property {function} [onAfter] 성공, 실패 후 콜백
 */

/**
 * @typedef QueryOptions
 * @type {Omit<RequestOptions, 'method'|'data'>}
 */

/**
 * @typedef MutationOptions
 * @type {Omit<RequestOptions, 'method'> & {method: Required<Exclude<import('../service/request').Method, 'get'>>}}
 */

/**
 * 리퀘스트
 */
export const RequestMixin = {
  data: () => ({
    /** pendingKey 정의 시 로딩 값을 보관 */
    pendings: {},
    /** error일 경우 값을 보관 */
    errors: {},
  }),
  methods: {
    /**
     * GET 요청
     * @param {RequestURI} uri
     * @param {QueryOptions} options
     */
    query(
      uri,
      { key, query, successText, errorText, onSuccess, onError, onAfter } = {},
    ) {
      const uriType = getType(uri);

      return this.commonRequest(
        () => queryCall.router(uriType, { uri, query }),
        {
          key,
          successText,
          errorText,
          onSuccess,
          onError,
          onAfter,
        },
      );
    },
    /**
     * 등록, 수정, 삭제 요청
     * @param {RequestURI} uri
     * @param {MutationOptions} options
     */
    mutation(uri, options) {
      const {
        key,
        method,
        data,
        query,
        successText,
        errorText,
        onSuccess,
        onError,
        onAfter,
      } = options ?? {};
      const uriType = getType(uri);

      return this.commonRequest(
        () => mutationCall.router(uriType, { uri, query, method, data }),
        {
          key,
          successText,
          errorText,
          onSuccess,
          onError,
          onAfter,
        },
      );
    },
    /**
     * 요청 공통처리
     * @param {function} requestFn 리퀘스트 비동기 함수
     * @param {Pick<RequestOptions, 'key'|'successText'|'errorText'|'onSuccess'|'onError'|'onAfter'>} RequestOptions 리퀘스트 옵션
     */
    async commonRequest(
      requestFn,
      { key, successText, errorText, onSuccess, onError, onAfter } = {},
    ) {
      if (key) {
        // # 리퀘스트 시 pending 시작
        this.pendings = {
          ...this.pendings,
          [key]: true,
        };

        // # 리퀘스트 시 에러 초기화
        if (this.errors[key]) {
          Vue.delete(this.errors, key);
        }
      }

      try {
        const successTextType = typeof successText;
        const res = await requestFn();

        // # 200인데 실패인 경우
        if (res?.success === false) {
          throw res;
        }

        // # 성공 텍스트
        if (successTextType === "string") {
          CommonUIControl.ShowSuccessToast(successText, 3000);
        } else if (successTextType === "function") {
          // @ts-ignore
          CommonUIControl.ShowSuccessToast(successText(res), 3000);
        }

        // # 성공 콜백
        if (typeof onSuccess === "function") {
          onSuccess(res);
        }

        return res;
      } catch (/** @type {any} */ error) {
        // # 에러 표기
        if (key) {
          this.errors = {
            ...this.errors,
            [key]: error,
          };
        }

        if (typeof onError === "function") {
          onError(error);
        }

        if (errorText !== false) {
          const errorMessage =
            errorText ??
            error?.response?.data?.message ??
            error?.response?.data?.error_message ??
            error?.response?.data?.msg ??
            error?.response?.data?.result?.errorMessage ??
            error?.message ??
            error?.data?.message ??
            error?.data?.error_message ??
            "요청에 실패하였습니다";

          CommonUIControl.ShowErrorToast(errorMessage, 6000);
        }

        throw error;
      } finally {
        if (typeof onAfter === "function") {
          onAfter();
        }
        if (key) {
          Vue.delete(this.pendings, key);
        }
      }
    },
  },
};

/** 타입을 반환 */
const getType = (data) => {
  const type = typeof data;
  return type === "object"
    ? Array.isArray(data) === true
      ? "array"
      : "object"
    : type;
};

/** 타입별 GET 요청 */
const queryCall = {
  ["array"]: async (uri) =>
    await request({
      method: "get",
      uri: uri[0],
      query: uri[1],
    }),
  ["string"]: async (uri, { query }) =>
    await request({
      method: "get",
      uri,
      query,
    }),
  ["function"]: async (uri) => await uri(),
  router(type, { uri, query }) {
    if (!this[type]) {
      throw new Error("queryCall.router() invalid type uri: " + uri);
    }
    return this[type](uri, { query });
  },
};

/** mutation 요청 */
const mutationCall = {
  ["string"]: async (uri, { query, method, data }) => {
    if (!method) {
      throw new Error("not defined method: " + method);
    }
    return await request({
      method,
      uri,
      query,
      data,
    });
  },
  ["function"]: async (uri) => await uri(),
  router(type, { uri, query, method, data }) {
    if (!this[type]) {
      throw new Error("mutationCall.router() invalid type uri: " + uri);
    }
    return this[type](uri, { query, method, data });
  },
};
