<template>
  <div
    class="rounded-lg overflow-hidden bg-white shadow-lg"
    :class="[usePadding ? 'p-8' : '']"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    /** true일 경우 기본 패딩값 적용 */
    usePadding: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
