
<div class="item-status-wrapper">
  <div
    class="flex flex-row px-2 rounded-lg"
    :style="{ 'background-color': calBgColor }"
  >
    <div
      class="status-text flex flex-col items-center justify-center"
      :style="{ color: btColor }"
    >
      {{ this.status }}
      <slot name="elapse-time"></slot>
    </div>

    <div class="flex flex-col justify-center ml-1">
      <v-progress-circular
        v-if="
          status == POD_STATUS.updating ||
          status == POD_STATUS.installing ||
          status == POD_STATUS.restarting ||
          status == POD_STATUS.restart ||
          status == POD_STATUS.resume ||
          status == POD_STATUS.loading
        "
        indeterminate
        :size="18"
        width="2"
        :color="btColor"
      ></v-progress-circular>

      <Tooltip
        v-else-if="
          [POD_STATUS.failed, POD_STATUS.error, POD_STATUS.pending].includes(
            status,
          ) && tooltip != ''
        "
        :text="tooltip"
        position="right"
        targetSelf
        :contentClass="
          status == POD_STATUS.failed ? 'p-2 text-sm inline-block' : ''
        "
      >
        <v-icon
          size="medium"
          :color="btColor"
          @click="
            () => {
              viewRDEState(1);
            }
          "
        >
          mdi-alert-circle
        </v-icon>
      </Tooltip>
    </div>
  </div>
</div>
