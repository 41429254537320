
<v-data-table
  key="id"
  :expanded.sync="expanded"
  :headers="tableHeaders"
  :items="items"
  item-key="id"
  hide-default-header
  class="ide-table"
>
  <template v-slot:item="{ item, isExpanded }">
    <tr class="row-ide-table">
      <td>
        <div
          class="ide-row-wrapper flex flex-row gap-0 w-full mb-2"
          :class="{ expanded: isExpanded }"
        >
          <div
            v-if="showFavoriteBtn || showSelectBtn"
            class="flex flex-col justify-center"
          >
            <!-- Favorite -->
            <IconButton
              v-if="showFavoriteBtn"
              outlined
              color="#F9B115"
              :iconName="item.isFavorite ? 'mdi-star' : 'mdi-star-outline'"
              class="favorite-btn"
              size="md"
              @click="onFavorite(item)"
            />
            <Checkbox
              v-if="showSelectBtn"
              :value="isSelected(item.id)"
              :true-value="item.id"
              class="check-box"
              @change="toggleSelection(item.id)"
            ></Checkbox>
          </div>
          <keep-alive>
            <WebIdeTableItem
              :key="`row-component-${item.id}`"
              :ref="`itemRef-${item.id}`"
              :item="item"
              :viewResourceRDE="viewResourceRDE"
              :viewStateRDE="viewStateRDE"
              :showSshInfo="showSshInfo"
              @getting-rde-status-error="onRdeStatusError"
              @click:remove="onDelete"
            />
          </keep-alive>
        </div>
      </td>
    </tr>
  </template>
</v-data-table>
