<template>
  <PageTemplate
    :useCard="false"
    :breadcrumbs="[
      {
        text: 'Billing',
      },
    ]"
  >
    <MeteringPricing />
  </PageTemplate>
</template>

<script>
import PageTemplate from "@/components/common/PageTemplate/PageTemplate.vue";
import MeteringPricing from "@/components/newWorkspace/components/MeteringPricing/MeteringPricing.vue";

export default {
  components: { PageTemplate, MeteringPricing },
  data: () => ({}),
  methods: {},
};
</script>
