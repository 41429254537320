import { mapState } from "vuex";
import _ from "lodash";

export const MetricMixin = {
  data() {
    return {
      usageInfo: {
        cpu: 0,
        memory: 0,
        disk: 0,
      },
    };
  },
  computed: {
    ...mapState({
      rdeMetrics: (state) => state.workspace.rdeMetrics,
    }),
  },
  watch: {
    rdeMetrics: {
      handler(rdeMetricsArr) {
        if (rdeMetricsArr && rdeMetricsArr.length > 0) {
          // Update usageInfo
          const usageInfo = rdeMetricsArr.reduce(
            (acc, curr) => {
              acc.cpu += curr.currentUsage.cpu;
              acc.memory += curr.currentUsage.memory;
              acc.disk += curr.currentUsage.disk;
              return acc;
            },
            { cpu: 0, memory: 0, disk: 0 },
          );
          this.usageInfo = usageInfo;
        }
      },
      deep: true,
    },
  },
  methods: {
    setMetric(id, currentUsage) {
      this.$store.dispatch("workspace/setRdeMetric", { id, currentUsage });
    },
    getAllMetrics() {
      return this.$store.state.workspace.rdeMetrics;
    },
    removeMetric(id) {
      this.removeRdeMetric(id);
      this.$store.dispatch("workspace/removeRdeMetric", id);
    },
    getCurrentUsage(metricData) {
      const cpuData = this.getLatestData(metricData?.cpu || {});
      const memoryData = this.getLatestData(metricData?.memory || {});
      const diskTotal = metricData?.volumeUsed;

      const currentUsage = {
        cpu: this.$helper.coreToMilicore(cpuData, 0) || 0,
        memory: this.$helper.byteToMebibyte(memoryData, 0) || 0,
        disk: diskTotal,
      };

      return currentUsage;
    },
    getLatestData(lines) {
      if (_.isNull(lines) || _.isEmpty(lines)) {
        return 0;
      }

      const maxDate = _.maxBy(Object.keys(lines), (v) => new Date(v).getTime());
      return parseFloat(lines[maxDate] || 0);
    },
    metricFlatten(rawData) {
      return rawData.map((metricData) => {
        const currentUsage = this.getCurrentUsage(metricData);
        return {
          id: metricData.id,
          currentUsage,
        };
      });
    },
  },
};
