
<div class="add-port-panel">
  <div
    v-for="(item, index) in portList"
    :key="item?.key || index"
    class="grid grid-cols-[1fr,1fr,80px] gap-x-2"
  >
    <Input
      v-model="item.name"
      placeholder="Enter your Port Name"
      :rules="[rules.required, rules.name, validateDuplicate('name')]"
      label="* Port Name"
      appendClass="input-text-field-v2 text-details"
    />
    <NumberInput
      v-model="item.port"
      format="none"
      placeholder="Enter your Port Number"
      :rules="[rules.required, validatePort, validateDuplicate('port')]"
      label="* Port"
      appendClass="input-text-field-v2 text-details"
      :max="65535"
      :min="0"
    />
    <div class="flex w-3">
      <v-icon
        v-show="portList.length > 1"
        class="h-6 mt-1"
        @click="handleRemoveClick(index)"
      >
        mdi-close
      </v-icon>
    </div>
  </div>
  <div v-if="!portList.length" class="text-xs text-error h-5">
    Require at least one port.
  </div>
  <Button class="add-button" size="md" @click="handleAddClick">
    <v-icon>mdi-plus</v-icon>
    ADD PORT
  </Button>
</div>
